$color_1: white;
$color_2: black;

$height: 160px;

.page-gallery {
  .bottom-shadow {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    z-index: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.5);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  // img.error {
  // 	// display: none;
  // 	visibility: hidden;
  // 	&::before {
  // 		content: "not available";
  // 		visibility: visible;
  // 		color: white;
  // 		white-space: pre;
  // 		display: flex;
  // 	}
  // }

  .img-being-uploaded {
    color: white;
  }

  &.non-editable {
    .gallery-item {
      width: 100%;
    }
  }

  .gallery-multitag-button {
    b {
      margin-left: 10px;
      background: rgba(255, 255, 255, 0.5);
      padding: 4px;
      border-radius: 4px;
    }
    &.ion-color-white b {
      background: rgba(0, 0, 0, 0.5);
      color: white;
    }
  }

  .no-pictures {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: calc(100% - 40px);
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    opacity: 0.8;
    & + * {
      // display: none;
      margin: 0 !important;
    }
  }

  .fixed-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    ion-button {
      animation: fadeInRight 0.3s both;
      // animation-delay: .2s;
      &[fill='outline'] {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
      }
    }
    &.upload-image-button {
      left: 10px;
      right: unset;
      gap: 10px;
      ion-button {
        animation: fadeInLeft 0.3s both;
        // animation-delay: .2s;
      }
      ion-icon {
        color: white;
      }
    }
  }

  .multi-tag-fab-button {
    animation: fadeInRight 0.3s both;
    margin-bottom: 50px;
    position: fixed;
  }

  ion-toolbar {
    --background: black;
    color: $color_1;
    ion-icon {
      color: white;
    }
  }

  .group-title {
    color: white;
    margin: 30px 0 15px 15px;
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .gallery-wrapper {
    margin-bottom: 120px;
  }

  .gallery-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-template-rows: repeat(100, 141px);
    grid-auto-rows: $height;
    // grid-auto-rows: 1fr;
    row-gap: 1px;
    column-gap: 1px;
    @media screen and (orientation: landscape) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    // @media (min-width: 374px) {
    // 	grid-auto-rows: 120px;
    // }
    // @media (min-width: 575px) {
    // 	grid-auto-rows: 180px;
    // }
  }

  .gallery-item {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    & > div {
      height: 100%;
      ion-img,
      img {
        height: 100%;
        object-fit: cover;
      }
    }

    img.render-picture {
      object-fit: cover;
      width: 100%;
      // height: $height;
    }

    .selected-icon {
      display: none;
    }
    .remove-icon {
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
      color: white;
      font-size: 28px;
      cursor: pointer;
      opacity: 0.6;
      background-color: rgba(0, 0, 0, 0.7);
      border-bottom-left-radius: 50%;
      padding: 2px;
      &:hover {
        opacity: 1;
      }
      // font-weight: bold;
      // .ionicon-stroke-width {
      // 	stroke-width: 4px;
      // }
    }
    .image-meta {
      position: absolute;
      top: 0;
      margin: 5px;
      opacity: 0.8;
      pointer-events: none;

      ion-badge {
        font-size: 12px;
        display: table;
        margin-bottom: 3px;
        white-space: normal;
        text-align: left;
        border-radius: 4px;
        padding: 4px;
      }
    }
  }
  .gallery-item.selected {
    border: 4px solid var(--ion-color-white);
    
    .selected-icon {
      display: block;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: white;
      font-size: 30px;
      display: none;
    }
  }

  .gallery-item.has-error {
    border: 4px solid var(--ion-color-danger2);  
  }
}
ion-content.page-gallery {
  --background: black;
}
ion-footer.page-gallery {
  --background: black;
  color: $color_1;
}
.multi-select-conditions-modal {
  ion-toolbar {
    --background: black;
    color: $color_1;
  }
  ion-item {
    --background: white;
    color: $color_2;
  }
  ion-list-header {
    --background: white;
    color: $color_2;
  }
}
