.show-modal {
  ion-input {
    background: transparent;
  }
}

.new-order-modal {
  @media screen and (min-width: 500px) {
    --width: 500px;
    --height: 600px;
  }
}

.page-new-dispatch-order {
  .form-container {
    height: 80%;
  }

  .hint-icon:hover {
    cursor: pointer;
  }

  .hint {
    margin-top: 6px;
    // max-width: 250px;
    top: 0;
    font-size: 14px;
    line-height: 16px;
    background-color: var(--ion-color-dark);
    color: var(--ion-color-light);
    padding: 6px 12px;
    border-radius: 3px;
    animation: fadeInUp 0.3s both;
    display: none;
    &:empty {
      display: none;
    }
    &.hint-active {
      display: inline-block;
    }
  }

  .buyer-label {
    display: flex;
    align-items: center;
  }

  .navigation-buttons {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    padding: 15px 0;
    width: 100%;
    bottom: 0;
    background-color: var(--ion-color-light);
  }

  .react-select__placeholder,
  .react-select__single-value {
    font-size: 14px;
    text-align: right;
  }
  .react-select__control {
    min-width: 250px;
    // width: 250px;
    margin: auto;
    z-index: 1000;
    // @media screen and (max-width: 500px) {
    //   min-width: 260px;
    //   width: 260px;
    // }
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
    z-index: 1000;
  }
  .react-select__option {
    color: var(--ion-color-dark);
    z-index: 1000;
  }
  .react-select__option--is-active {
    background-color: var(--ion-color-tertiary);
    color: white;
    z-index: 1000;
  }
  .react-select__option--is-selected {
    background-color: transparent !important;
    color: var(--ion-color-dark);
    font-weight: bold;
    z-index: 1000;
  }
  .react-select__option--is-focused {
    background-color: var(--ion-color-tertiary) !important;
    color: white;
    z-index: 1000;
  }

  ion-item-divider {
    text-transform: uppercase;
    font-weight: bold;
  }

  .select-item {
    overflow: visible;
    &.z1 {
      z-index: 101;
    }
    &.z2 {
      z-index: 102;
    }
    &.z3 {
      z-index: 103;
    }
    &.z4 {
      z-index: 104;
    }
    &.z5 {
      z-index: 105;
    }
  }

  color: var(--ion-color-dark);

  ion-input {
    --padding-start: 10px;
    text-align: right;
    max-width: 140px;
    margin-left: auto;
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid var(--ion-color-medium);
    height: 36px;
    font-size: 16px;
    &[disabled='true'] {
      background-color: transparent;
      border: none;
    }
  }

  ion-select {
    min-width: 200px;
    text-align: right;
  }
  ion-label {
    color: var(--ion-color-dark);
  }
  .select-all {
    position: absolute;
    right: 15px;
    margin-top: 10px;
    color: var(--ion-color-primary);
    text-decoration: underline;
  }
  .fruit-picker {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 5px;

    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;

    div {
      padding: 10px 15px;
      cursor: pointer;
      border: 2px solid transparent;
      &.selected {
        background-color: var(--ion-color-light);
        border: 2px solid var(--ion-color-primary);
        border-radius: 15px;
      }
      text-align: center;
      ion-icon {
        font-size: 50px;
      }
    }
  }

  .bottom-buttons {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    // bottom: 0;
    // width: 100%;
    // background-color: var(--ion-color-light);
  }

  ion-list {
    padding-bottom: 65px;
  }

  ion-list-header {
    margin-top: 10px;
    align-items: center;
    // gap: 15px;
    ion-badge {
      margin-left: 5px;
    }
  }

  .select-item {
    overflow: visible;
    &.z1 {
      z-index: 101;
    }
    &.z2 {
      z-index: 102;
    }
  }
}

.dark {
  .page-new-lot {
    .fruit-picker {
      ion-icon {
        color: #444;
        // background-color: #fff;
      }
    }
  }
}
