.todo-list {
  position: static;
  .bottom-buttons {
    position: sticky;
    top: 0px;
    z-index: 1;
    right: 0px;
    padding: 15px 0;
    background: rgba(255, 255, 255, 0.9);
    margin-top: -10px;
    animation: fadeInDown 0.3s both;
    display: flex;
    & > div {
      display: flex;
      gap: 5px;
    }
    .right {
      margin-left: auto;
    }
  }
}
