.new-report-modal {
  .modal-wrapper {
    width: 90% !important;
    height: 80vh !important;
    @media screen and (max-width: 846px) {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.page-new-report {
  .button-container {
    bottom: 0;
    margin: 15px;
    right: 0;
    > * {
      animation: fadeInRight 0.3s both;
    }

    // ion-button.translucent {
    //   opacity: .5!important;
    // }

    ion-button::part(native) .button-inner {
      justify-content: flex-start !important;
    }
  }

  ion-input {
    --padding-start: 10px;
    text-align: right;
  }

  .react-select__control {
    min-width: 250px;
    width: 250px;
    margin: auto;
    z-index: 1000;
    // @media screen and (max-width: 500px) {
    //   min-width: 260px;
    //   width: 260px;
    // }
  }

  textarea {
    width: 100%;
    margin-top: 5px;
    background-color: var(--ion-color-background);
    border-radius: 5px;
    padding: 5px;
    height: 100px;
    outline: none !important;
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
    z-index: 1000;
  }
  .react-select__option {
    color: var(--ion-color-dark);
    z-index: 1000;
  }
  .react-select__option--is-active {
    background-color: var(--ion-color-tertiary);
    color: white;
    z-index: 1000;
  }
  .react-select__option--is-selected {
    background-color: transparent !important;
    color: var(--ion-color-dark);
    font-weight: bold;
    z-index: 1000;
  }
  .react-select__option--is-focused {
    background-color: var(--ion-color-tertiary) !important;
    color: white;
    z-index: 1000;
  }

  .select-item {
    overflow: visible;
    &.z0 {
      z-index: 100;
    }
    &.z1 {
      z-index: 101;
    }
    &.z2 {
      z-index: 102;
    }
    &.z3 {
      z-index: 103;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    @media (max-width: 846px) {
      flex-direction: column-reverse;
      width: 100%;
    }
  }

  ion-list {
    overflow: auto;
  }
  .list-item {
    animation: fadeInDown 0.3s both;
    overflow: hidden;
    border-bottom: 1px solid var(--ion-color-light);
    padding-top: 5px;
    padding-bottom: 5px;
    // .view-article-description {
    //   display: flex;
    //   align-items: center;
    //   gap: 5px;
    // }
    .article-name {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .label-order-id {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    & + ion-button {
      margin-left: 10px;
    }
  }

  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding: 15px;
    ion-button {
      animation: fadeInUp 0.3s both;
      & + ion-button {
        animation-delay: 0.1s;
      }
    }
    &.fixed {
      position: fixed;
      bottom: 0px;
      right: 0px;
    }
  }

  .form-list {
    &.hidden {
      display: none;
    }
    width: 450px;

    @media (max-width: 846px) {
      width: 100%;
    }
    // ion-label {
    //   flex: 0.5;
    // }
    ion-select {
      // min-width: 200px;
      min-width: 290px;
      text-align: right;
    }
    .form-title {
      padding: 15px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }
    .input-order-id {
      input {
        text-align: center;
      }
    }
    ion-spinner {
      transform: scale(1.5);
      margin: auto;
      display: block;
      margin-top: 15px;
      animation: fadeIn 0.3s both;
      animation-delay: 0.5s;
    }
    .label-order-id {
      text-align: right;
    }
    .orders {
      ion-item {
        animation: fadeInUp 0.3s both;
        ion-label > div {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 2px 0;
        }
        @for $j from 0 to 33 {
          &:nth-child(#{$j}) {
            animation-delay: #{$j * 70+000}ms;
          }
        }
        b {
          font-weight: 900;
        }
        span {
          font-size: 14px;
        }
      }
    }
    &.step1 {
      z-index: 1;
      animation: fadeInLeft 0.3s both;
    }
    &.step2 {
      z-index: 1;
      animation: fadeInRight 0.3s both;
      // .buttons {
      //   justify-content: flex-start;
      // }
    }
  }

  .selected-lots {
    &.hide-defects {
      .defects {
        display: none;
      }
    }
    &.hide-scores {
      .view-assessment-score,
      .batch-summary-score {
        display: none;
      }
    }

    &.hide-inspector {
      .user {
        display: none;
      }
    }

    &.hide-quantity {
      .quantity {
        display: none;
      }
    }

    .user,
    .view-assessment-score,
    .batch-summary-score,
    .defects,
    .batch-summary,
    .quantity {
      animation: fadeInUp 0.3s both;
    }

    z-index: 2;
    padding: 10px 15px;
    padding-bottom: 75px;
    flex: 1;
    // white-space: nowrap;
    overflow: auto;
    background-color: var(--ion-color-step-50);

    .lots-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
      grid-template-rows: max-content;
      gap: 15px;
    }

    ion-list-header {
      border-bottom: 1px solid var(--ion-color-light-medium);
      animation: fadeInUp 0.3s both;
      h1 {
        margin-top: 10px;
        font-size: 22px;
        font-weight: bold;
      }
    }
    .pictures-hint {
      animation: fadeInUp 0.3s both;
      text-align: center;
      padding: 15px 5px 30px 5px;
      color: var(--ion-color-medium);
    }

    &.empty-lots {
      display: flex;
      justify-content: center;
      align-items: center;
      .no-lots-message {
        text-align: center;
        > div {
          margin-bottom: 15px;
          font-size: 18px;
          font-weight: bold;
          color: var(--ion-color-medium);
          text-transform: uppercase;
        }
      }
    }

    .card-wrapper {
      position: relative;

      .close-icon {
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: 1;
        background-color: var(--background);
        border-radius: 50%;
        display: none;
        cursor: pointer;
      }

      ion-card,
      .ion-card {
        // user-select: none;
        // pointer-events: none;
      }

      &:hover {
        .close-icon {
          display: block;
        }
      }
    }
  }
}
