.page-split-lot {
  color: var(--ion-color-dark);

  ion-input {
    --padding-start: 10px;
    text-align: right;
    // max-width: 100%;
    margin-left: 10px;
  }

  ion-label {
    min-width: 100px;
  }

  .transfer-grid {
    // align-items: center;
    // text-align: center;
  }

  .warning-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    div {
      margin-left: 10px;
    }
  }

  .amount-input {
    // margin-right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    input {
      max-width: 125px;
      background-color: var(--ion-color-light-medium);
      color: var(--ion-color-dark);
      border-radius: 4px;
      padding: 8px 10px;
    }
  }

  ion-select {
    min-width: 200px;
    text-align: right;
  }
  ion-label {
    color: var(--ion-color-dark);
    i {
      color: var(--ion-color-medium);
      font-style: normal;
      padding-left: 5px;
      // text-decoration: dashed;
    }
  }
  .select-all {
    position: absolute;
    right: 15px;
    margin-top: 10px;
    color: var(--ion-color-primary);
    text-decoration: underline;
  }
  .fruit-picker {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 5px;

    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;

    div {
      padding: 10px 15px;
      cursor: pointer;
      border: 2px solid transparent;
      &.selected {
        background-color: var(--ion-color-light);
        border: 2px solid var(--ion-color-primary);
        border-radius: 15px;
      }
      text-align: center;
      ion-icon {
        font-size: 50px;
      }
    }
  }
  .bottom-buttons {
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    // bottom: 0;
    // width: 100%;
    // background-color: var(--ion-color-light);
  }

  ion-list-header {
    margin-top: 10px;
    align-items: center;
    // gap: 15px;
    ion-badge {
      margin-left: 5px;
    }
  }
}
