.map-wrapper {
  width: 100%;
  height: 100%;
  position: 'relative';
}

.floating-search {
  width: 600px;
  position: absolute;
  top: -6px;
  right: 10px;
  z-index: 5;
}
