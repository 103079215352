.inspection-item {
  border-bottom: 1px solid var(--ion-color-light);
  transition: background-color 0.3s;

  &#question-ag_boxes_shipped_mismatch {
    .first-row .boolean:empty:after {
      content: 'no mismatch';
      white-space: nowrap;
    }
    &.score-2 .first-row .boolean:empty:after {
      content: 'mismatch';
      white-space: nowrap;
    }
  }

  .boolean {
    position: relative;
    width: auto !important;
    cursor: pointer !important;
    &:hover {
      background-color: var(--ion-color-light);
    }
    ion-checkbox {
      pointer-events: none;
    }
  }

  &.has-error {
    border-bottom: 1px solid var(--ion-color-danger2);
    color: var(--ion-color-danger2);
  }

  &.score-1 {
    background-color: var(--ion-color-danger2) !important;
    color: white;
    input,
    .unit {
      color: var(--ion-color-dark);
    }
    .pic-count {
      background-color: white !important;
      color: #222 !important;
    }
    ion-input {
      &:after,
      & + .unit:after {
        border-color: var(--ion-color-light) !important;
      }
    }
    ion-icon {
      color: white;
    }
    ion-checkbox {
      --ion-color-base: white !important;
      --ion-color-contrast: #222 !important;
      background: transparent;
    }

    select {
      color: white !important;
    }
  }
  &.score-2 {
    background-color: var(--ion-color-danger) !important;
  }
  &.score-3 {
    background-color: var(--ion-color-secondary) !important;
    color: #222;
  }

  .score-1 {
    .pic-count {
      background-color: white !important;
      color: #222 !important;
    }
    ion-input {
      &:after,
      & + .unit:after {
        border-color: var(--ion-color-dark) !important;
      }
    }
    ion-input,
    .unit {
      color: var(--ion-color-dark);
    }
  }

  .missing_mandatory {
    justify-content: flex-end;
    min-height: 36px;
    color: var(--ion-color-danger2);
    font-weight: bold;
    animation: fadeInUp 0.3s both;
  }

  > div {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    min-height: 52px;
    width: 100%;

    &:has(.text) {
      flex-direction: column;
      .inspection-label {
        padding: 10px 0;
      }
    }

    .inspection-label {
      // min-width: 150px;
      // padding-right: 10px;
      // white-space: nowrap;
      width: 100%;
      &.c-many {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        .computed {
          margin-left: 0;
        }
      }

      &.out-of-range span {
        background-color: var(--ion-color-danger2);
        color: var(--ion-color-white);
        border-radius: 4px;
        padding: 0 4px;
        font-weight: bold;
        margin-bottom: 4px;
        display: inline-block;
      }

      .label {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100%;
        padding-right: 10px;
      }

      &:empty {
        display: none;
      }
    }

    .inspection-control {
      margin-left: auto;
      opacity: 0.9;
      // max-width: 150px;

      .react-select__control {
        min-width: 200px;
      }

      ion-select {
        // min-width: 200px;
        &.score-GREEN {
          &::part(text) {
            font-weight: bold;
            background-color: var(--ion-color-primary);
            padding: 1px 5px;
            border-radius: 4px;
          }
        }
        &.score-AMBER {
          &::part(text) {
            font-weight: bold;
            background-color: var(--ion-color-secondary);
            padding: 1px 5px;
            border-radius: 4px;
          }
        }
        &.score-RED {
          &::part(text) {
            font-weight: bold;
            background-color: var(--ion-color-danger);
            padding: 1px 5px;
            border-radius: 4px;
          }
        }
        &.score-BLUE {
          &::part(text) {
            font-weight: bold;
            background-color: var(--ion-color-blue);
            padding: 1px 5px;
            border-radius: 4px;
            color: white;
          }
        }
        &.score-REJECT {
          &::part(text) {
            font-weight: bold;
            background-color: var(--ion-color-danger2);
            padding: 1px 5px;
            border-radius: 4px;
            color: white;
          }
        }
        &.score-GREY {
          &::part(text) {
            font-weight: bold;
            background-color: var(--ion-color-medium);
            padding: 1px 5px;
            border-radius: 4px;
            color: white;
          }
        }
      }
    }
  }

  .pic-indicator {
    position: relative;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto;
    // ion-icon {
    //   color: var(--ion-color-medium);
    // }
    .pic-count {
      padding: 2px 6px;
      border-radius: 5px;
      background-color: var(--ion-color-medium);
      color: var(--ion-color-light);
      font-size: 12px;
      font-weight: bold;
      &.many {
        background-color: var(--ion-color-dark);
        color: var(--ion-color-light);
      }
    }
  }

  .hint {
    font-size: 24px;
    width: 24px;
    min-width: 24px;
  }

  .react-tooltip {
    max-width: 300px;
    padding: 10px;
    z-index: 10;
    opacity: 1;
    color: var(--ion-color-light);
    background-color: var(--ion-color-dark);
    border-radius: 8px;
    white-space: pre-wrap;
  }

  .computed {
    font-weight: bold;
    &.out-of-range {
      color: var(--ion-color-danger2);
      display: flex;
      align-items: center;
      gap: 5px;
      // &:after {
      //   content: ' 💩💩';
      //   font-size: 18px;
      //   text-transform: uppercase;
      //   animation: fadeInUp .3s both;
      // }
    }
  }

  &.multi-row {
    padding-bottom: 5px;
    :first-child .inspection-label {
      // font-weight: bold;
      width: 100%;
    }
    .inspection-control {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 0px;
      width: 100%;
      > div {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        &:not(:last-child) {
          padding-bottom: 5px;
          margin-bottom: 5px;
          border-bottom: 1px solid var(--ion-color-light);
        }
      }

      .label {
        // margin-right: auto;
        text-align: right;
      }
    }
    .has-label .label {
      // display: none;
    }
  }

  &.single-row {
    .inspection-control > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      //   max-width: 150px;
    }
    .inspection-label {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      padding-right: 5px;
      .computed {
        margin-left: auto;
      }
    }
  }

  .measurable-edit-buttons {
    display: none;
  }
  .measurable-edit {
    &.is-primary {
      border-left: 3px solid var(--ion-color-primary);
      // border-radius: 8px;
    }
  }
  .measurable-edit:hover {
    border: 3px solid var(--ion-color-tertiary);
    // margin: 15px 0;
    // padding: 15px;
    border-radius: 8px;
    // background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
    position: relative;
    padding-bottom: 60px;
    // margin-bottom: 5px;
    padding-top: 5px;

    .measurable-edit-buttons {
      width: 100%;
      display: block;
      justify-content: flex-end;
      flex: 1;
      position: absolute;
      // margin: 15px;
      right: 0px;
      bottom: 5px;
      ion-button {
        animation: fadeInUp 0.3s ease;
      }
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
    }
  }

  .text {
    width: 100%;
  }
  ion-textarea {
    width: 100%;
    border: 2px solid var(--ion-color-light-medium);
    margin: 0;
    margin-bottom: 8px;
    margin-top: 8px;
    border-radius: 8px;
    font-family: monospace;
    font-size: 15px;
    min-height: 60px;

    textarea {
      padding: 10px !important;
    }
    &:hover,
    &:active,
    &:focus,
    &.focus {
      border-color: var(--ion-color-tertiary);
    }
  }
}

.dark {
  .inspection-item {
    &.has-error {
      border-bottom: 1px solid var(--ion-color-danger);
      color: var(--ion-color-danger);
    }
    .missing_mandatory {
      color: var(--ion-color-danger);
    }
  }
}
