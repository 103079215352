.component-users-admin {
  max-width: 1200px;
  position: relative;

  .email {
    // max-width: 150px;
  }

  .more {
    flex-direction: column;
    width: 100%;
    align-items: flex-start !important;

    .section {
      font-size: 1.5em;
      margin-bottom: 10px;
      margin-left: 15px;
      font-weight: bold;
    }

    .permission-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 5px;
      & > div {
        // margin-bottom: 10px;
        & > div {
          display: flex;
          // gap: 10px;
          align-items: center;
          // justify-content: center;
        }
      }
      .desc {
        padding-left: 15px;
        color: var(--ion-color-medium);
        animation: fadeInUp 0.3s both;
      }
    }
  }

  .pending-invites {
    margin: 15px;
    strong {
      display: block;
      border-bottom: 1px solid var(--ion-color-light);
      margin-bottom: 10px;
      padding-bottom: 5px;
      padding-left: 5px;
    }
    div {
      padding: 5px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      .action-button {
        visibility: hidden;
      }
      &:hover {
        .action-button {
          visibility: visible;
        }
      }
    }
    div:nth-child(even) {
      background-color: var(--ion-color-light);
    }
  }
}

// .checkbox-button {

// }

.user-admin-checkbox-container {
  display: flex;
  .info-button {
    margin-left: 5px;
    cursor: pointer;
  }
}
