.toast-fade-in-up {
  animation: fadeInUp 0.3s both;
}

.alert-qc {
  .accept-button-alert {
    color: #ffffff;
    background-color: #fa937a;
  }
}

.culolo {
  position: absolute;
  right: 60px;
  white-space: nowrap;
  width: 180px;
  text-align: right;
  font-size: 14px;
  animation: fadeIn 0.3s;
  animation-fill-mode: both;
  text-transform: uppercase;

  &.c1 {
    bottom: 11px;
  }

  &.c2 {
    bottom: 61px;
  }

  &.c3 {
    bottom: 111px;
  }

  &.c4 {
    bottom: 161px;
  }

  &.c5 {
    bottom: 212px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5 !important;
  }

  span {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-light);
    border-radius: 6px;
    display: inline-block;
    padding: 5px 8px;

    b {
      color: var(--ion-color-dark);
      background-color: var(--ion-color-light);
      // display: block;
      padding: 0 4px;
      border-radius: 4px;
      margin-left: 6px;
    }
  }
}

ion-icon.action-button-fab {
  font-size: 26px;
  font-weight: bold;
}

.fab-button {
  ion-icon {
    font-size: 22px;
  }
  &.disabled {
    opacity: 0.5 !important;
  }
}

.dark .page-quality-control {
  ion-content {
    // background-color: #222
    --background: #111;
  }
}

// .dark {
//   .products-select {
//     & > div {
//       border-color: hsl(0,0%,20%);
//       background-color: hsl(0,0%,20%);
//     }
//     > div > div {
//       background-color: hsl(0,0%,20%);
//       color: var(--ion-color-tertiary);
//       input {
//         color: white!important;
//       }
//     }
//   }
// }

.page-quality-control {
  ion-fab-button,
  .mulitple-select-button ion-button,
  .selectall-button ion-button {
    animation: fadeInRight 0.2s both;
  }

  .top-view-buttons {
    ion-button {
      &.selected::part(native) {
        border-radius: 5px;
        background-color: var(--ion-color-dark);
        color: var(--ion-color-light);
      }
    }
    @media only screen and (max-width: 602px) {
      display: none;
    }
  }

  .top-filter-button {
    @media only screen and (min-width: 992px) {
      display: none;
    }
  }

  @media only screen and (max-width: 320px) {
    ion-toolbar ion-segment + .buttons-last-slot {
      // display: none;
    }

    ion-title {
      font-size: 18px;
    }

    ion-header ion-toolbar {
      // --min-height: 42px;
    }
  }

  // &.no-animation {
  //   * {
  //     animation: none !important;
  //   }
  // }

  .mulitple-select-button {
    position: fixed;
    bottom: 23px;
    right: 75px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    &.ios {
      bottom: 26px;
      width: 42px;
      height: 42px;
    }
  }
  .selectall-button {
    position: fixed;
    bottom: 30px;
    right: 133px;
    // background: rgba(255, 255, 255, 0.9);
    &.ios {
      bottom: 31px;
    }
  }

  .notification-number {
    position: absolute;
    pointer-events: none;
    left: 30px;
    top: 2px;
    // opacity: .9;
  }

  .under-development {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    color: var(--ion-color-medium);
    margin-top: 30px;
    user-select: none;
    div {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;
    }
    ion-icon {
      font-size: 96px;
      color: var(--ion-color-medium);
    }
  }

  .searchbar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin: 0 7px;
    padding: 0 10px;
    position: sticky;
    top: 0;
    background-color: var(--background);
    z-index: 9;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
    transition: box-shadow 0.5s ease;
    /*     max-width: 800px; */
    /*     margin-left: auto;
    margin-right: auto; */

    @media screen and (min-width: 992px) {
      display: none;
    }

    .no-animation {
      animation: non;
    }

    &.animated {
      &.in {
        animation: fadeInDown;
        animation-duration: 0.3s;
        // animation-delay: .1s;
        animation-fill-mode: none;
        animation-timing-function: ease-in-out;
      }
      &.out {
        animation: fadeOutUp;
        animation-duration: 0.3s;
        // animation-delay: .1s;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
      }
    }

    &.shadow {
      box-shadow: 0px 0px 29px 0px rgb(0 0 0 / 41%);
    }

    ion-button {
      font-size: 0.6em;
    }

    .sorting-icon {
      // width: 80px;
      min-width: 24px;
      margin-left: -5px;
      cursor: pointer;
      height: 30px;
      // border: 1px solid;
      // border-radius: 3px;
      // @media screen and (max-width: 768px) {
      //   width: 60px;
      // }
    }
    .sorting-icon:hover {
      color: var(--ion-color-primary);
    }

    .datePicker {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      color: var(--ion-color-medium);

      @media (max-width: 520px) {
        max-width: 194px;
      }

      .datePicker-label {
        font-weight: bold;
      }
      .datePicker-selects {
        display: flex;
        .datePicker-start {
          margin-right: 10px;
        }
      }
    }

    .reorderSelect {
      min-width: 160px;
      .react-select__control {
        font-size: 14px;
      }
    }

    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      & > div:not(.products-select) {
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: flex-start;
        justify-content: center;
        gap: 10px;
        // margin-left: 10px;
        color: #333;
        // color: var(--ion-color-dark);
        ion-datetime {
          margin: 0;
          padding: 12px 0;
          // min-width: 100px;
        }
        ion-icon {
          color: var(--ion-color-medium);
        }
      }
    }

    .products-select {
      width: 100%;
      margin-bottom: 10px;

      &.desktop {
        margin-top: 10px;
      }
      &.mobile {
        display: none;
      }

      @media only screen and (max-width: 700px) {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
    }

    ion-searchbar {
      position: relative !important;
      // height: 48px !important;
      /*       max-width: 180px; */
      // margin: auto;
      // display: inline-block;
      padding: 5px -0;
      .searchbar-input-container {
        height: 38px;
      }
      .searchbar-input {
        // font-size: 13px;
        padding-inline-end: 10px;
        border-radius: 4px;
      }

      &.searchbar-has-value {
        width: 100%;
        max-width: unset;
        & + .products-select {
          display: none;
          & + .datePicker {
            width: 0;
            overflow: hidden;
            display: none;
            & + ion-icon {
              display: none;
            }
          }
        }
      }
    }
  }

  .loading-more-button {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-bottom: 15px;

    @media (max-width: 1197px) {
      display: none;
    }
  }

  .loading-more-spinner {
    text-align: center;
    transform: scale(2);
    // min-height: 100px;
    padding: 30px 0 90px;
    display: none;
  }

  .multiple-selection-message {
    text-align: center;
    padding-bottom: 10px;
    font-size: 12px;
    // margin-top: -5px;
    // background-color: var(--ion-color-light);
    animation: fadeInUp 0.3s;
    // position: absolute;
    width: 100%;
    z-index: 2;
    // opacity: .8;
    font-weight: bold;
    text-transform: uppercase;
  }

  .list-button {
    visibility: hidden;
  }

  ion-toolbar {
    // --min-height: 42px;
    ion-searchbar {
    }
  }

  // ion-searchbar {
  //   padding-bottom: 0;

  //   &.sc-ion-searchbar-ios-h {
  //     height: 44px;
  //     position: absolute;
  //     top: -1px;
  //   }
  // }

  ion-segment-button {
    font-size: 14px;

    span:nth-child(2) {
      display: none;
    }

    @media only screen and (max-width: 500px) {
      // font-size: 12px;
      span:nth-child(1) {
        display: none;
      }

      span:nth-child(2) {
        display: block;
      }
    }
  }

  .menu-button-hidden + ion-badge {
    display: none;
  }

  .filter-toolbar {
    &:empty {
      display: none !important;
    }
    // border-top: 1px solid var(--ion-color-light-medium);
    // border-bottom: 1px solid var(--ion-color-light-medium);
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px 0 10px 0;
    gap: 4px !important;
    // background-color: var(--ion-item-background);
    color: var(--ion-color-medium) !important;
    font-size: 14px;

    div {
      animation: fadeInLeft 0.3s both;
      overflow: hidden;
      border-radius: 15px;
      background-color: var(--ion-color-light);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      padding-left: 5px;
      display: flex;
      white-space: nowrap;
      border: 1px solid var(--ion-color-light-medium);
      align-items: center;
      cursor: pointer;

      span {
        padding-right: 2px;
        white-space: nowrap;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      ion-icon {
        font-size: 19px;
      }
    }
  }

  ion-list {
    --ion-background-color: var(--ion-color-background);

    ion-toolbar {
      --background: var(--ion-color-background);
    }
  }

  .cards-wrapper {
    display: flex;
    width: 100%;
    // gap: 5px;
    height: auto;
    // padding-right: 353px;
    > ion-list {
      padding-top: 10px;
      flex: 2;
      min-height: 110vh;
      border-right: 1px solid var(--ion-color-light-medium);
      > div {
        margin-right: 15px;
        margin-left: 15px;
      }
    }
    > .page-filters {
      width: 353px;
      transform: translate3d(0, 0, 0);
      // animation: fadeInRight .2s ease-out;
      // flex:1;
      position: sticky;
      top: 0px;
      right: 0;
      z-index: 5;
      height: 750px;
      overflow-y: auto;
      @media screen and (max-width: 991px) {
        display: none;
      }
      // ion-list {
      //   padding-top: 5px;
      //   border-top-left-radius: 10px;
      //   overflow: hidden;
      //   padding-bottom: 150px;
      //   margin-top: 5px;
      //   border-bottom-left-radius: 10px;
      // }
      ion-item {
        --padding-start: 10px;
      }
      // #filter-button, #clear-button {
      //   margin-left: 10px;
      // }
    }
  }

  .masonry-with-columns {
    padding-bottom: 100px;
    // padding-bottom: 0px;
    padding-top: 0;
    // padding-top: 50px;

    @media screen and (max-width: 500px) {
      padding-bottom: 50px;
    }

    .hidden {
      visibility: hidden;
      opacity: 0;
      transition: opacity 1s;
    }
    .visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 1s;
    }

    > div > ion-card,
    > div > div {
      // grid-row-end: span 15;
      // animation: .2s fadeIn both;

      // this is how to do it with siblings selectors
      // ---------------------------------------------
      // &.skeleton {
      //   $sel: '';
      //   @for $j from 1 to 33 {
      //     $sel: if($sel == '', '.skeleton', $sel + ' + .skeleton');
      //     &+#{$sel} {
      //       animation: .2s fadeInUp both;
      //       animation-delay: #{$j*70+000}ms;

      //       ion-skeleton-text {
      //         animation-delay: #{$j*200+000}ms;
      //       }

      //       ion-card-header {
      //         ion-skeleton-text {
      //           animation-delay: #{$j*70+80}ms;
      //         }
      //       }
      //     }
      //   }
      // }

      // &.skeleton {

      @for $j from 0 to 12 {
        &:nth-child(#{$j + 1}) {
          // display: none;
          // animation: .2s fadeInUp both;
          // animation-delay: #{$j*70+000}ms;

          ion-skeleton-text {
            animation-delay: #{$j * 200+000}ms;
          }

          ion-card-header {
            ion-skeleton-text {
              animation-delay: #{$j * 70+80}ms;
            }
          }
        }
        &.skeleton:nth-child(#{$j + 1}) {
          // display: none;
          animation: 0.2s fadeInUp both;
          animation-delay: #{$j * 70+00}ms;
        }
      }
      // }
    }

    // .no-animation > * {
    //   animation: none!important;
    // }

    .no-delay {
      ion-card {
        animation-delay: 0ms !important;
      }
    }
  }

  ion-card {
    .cover {
      ion-badge {
        pointer-events: none;

        ion-icon {
          display: none;
        }
      }
    }
  }
}

// .loading-finished .skeleton {
//   visibility: hidden;
// }

// @media only screen and (min-width: 630px) {
.page-quality-control {
  ion-card {
    margin: 0;
    width: 100%;
    // height: 100%;
  }

  .top-order-summary {
    grid-column: 1/-1;
    padding: 16px;
    margin-left: -16px;
    margin-top: -16px;
    margin-right: -16px;
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: var(--background);
    // border-radius: 8px;
    display: flex;
    display: none;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    > div {
      background-color: var(--ion-color-light);
      padding: 5px 10px;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 8px;
      display: flex;
      align-items: center;
      min-height: 36px;
      white-space: nowrap;
      cursor: pointer;
      &.selected {
        color: white;
        ion-spinner {
          color: white;
        }
        background-color: var(--ion-color-tertiary);
      }
      ion-spinner {
        width: 14px;
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 500px) {
      // margin-left: -30px;
      margin-top: -30px;
      // margin-right: -30px;
      // flex-wrap: nowrap;
      // align-items: flex-start;
      // justify-content: flex-start;
      // overflow-x: auto;
      font-size: 14px;
      > div {
        text-align: center;
        white-space: normal;
        // min-height: auto;
        // max-height: 30px;
      }
    }
  }

  .todo-bottom-buttons {
    position: sticky;
    bottom: 15px;
    z-index: 8;
    padding-right: 15px;
    // margin-right: 360px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    ion-button {
      animation: fadeInRight 0.3s both;
    }
    @media screen and (max-width: 530px) {
    }
  }

  .todo-list {
    margin-top: 0 !important;
    @media screen and (max-width: 530px) {
      margin: 0 !important;
      // --padding-start: 0;
    }

    .date-banner {
      // animation: fadeInUp .3s both;
      margin: auto;
      width: 90px;
      text-align: center;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: bold;
      background-color: var(--ion-color-dark);
      color: var(--ion-color-light);
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      position: sticky;
      top: 10px;
      z-index: 10;
      box-shadow: 0 0 7px 0px rgb(0 0 0 / 30%);
    }

    & > ion-list {
      margin: auto;
      max-width: 1000px;
      padding: 0;
      // ion-reorder-group ion-item:last-child {
      //   margin-bottom: 50px;
      // }
    }
    .break {
      flex-basis: 100%;
      height: 0;
    }
    ion-item-sliding {
    }
    ion-item {
      // animation: fadeIn .01s both;
      // --padding-top: 5px;
      // --padding-bottom: 5px;
      // --min-height: 36px;
      transition: height 1s;
      --inner-padding-end: 5px;
      --border-width: 0px 0 0px 0;
      --border-color: var(--ion-color-light-medium);
      overflow: visible;

      ion-card,
      .ion-card {
        // display: none;
        max-width: 300px;
        // width: 50%;
        margin: 8px 0px 24px 0;
        // animation: fadeInRight .3s both;
        @media screen and (max-width: 500px) {
          max-width: 284px;
        }
        .view-article-description {
          margin-bottom: 5px;
        }
      }

      .link {
        margin-bottom: 15px;
        margin-top: 15px;
        b {
          min-width: 140px;
          max-width: 200px;
          display: flex;
          flex-direction: column;
          gap: 2px;
          align-items: flex-start;
        }
        .description {
          display: flex;
          flex-direction: column;
          gap: 2px;
          .pallets {
            font-size: 13px;
            font-weight: bold;
            @media screen and (max-width: 500px) {
              order: -1;
            }
          }
          .article-name {
            display: block !important;
          }
          i {
            font-style: normal;
            display: block;
            font-size: 14px;
          }
        }
      }

      // .buttons-wrapper ion-buttons {
      //   display: none;
      // }

      @media screen and (max-width: 600px) {
        --border-color: var(--ion-color-light-medium);
        .item-wrapper {
          align-items: center;
        }

        .link {
          margin-bottom: 10px;
          margin-top: 10px;
          flex-direction: column;
          align-items: flex-start !important;
          width: 100%;
          row-gap: 0 !important;
          .pepe {
            width: 100%;
          }
          b {
            min-width: auto;
          }
        }

        .buttons-wrapper {
          margin-bottom: 15px;
          margin-top: 15px;
          // gap: 0!important;
          // flex-direction: column-reverse;
          // ion-buttons {
          //   margin-right: -10px;
          // }
        }
        .buttons-wrapper ion-buttons {
          display: flex;
        }
        .btn-more-task {
          display: none;
        }
      }

      .link {
        color: var(--ion-color-dark);
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 4px;
        row-gap: 4px;
        cursor: pointer;
        /*         max-width: 200px; */
        .article-name {
          margin: 0;
          display: none;
        }
        .view-article-description {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          row-gap: 5px;
          column-gap: 10px;
        }
        .pepe {
          display: flex;
          align-items: center;
          @media screen and (max-width: 500px) {
            width: 100%;
          }
        }
      }

      .top-wrapper {
        width: 100%;
        & > div:nth-child(2) {
          display: flex;
          justify-content: flex-end;
        }
      }

      .item-wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        .buttons-wrapper {
          margin-left: auto;
          display: flex;
          column-gap: 5px;
          flex-wrap: nowrap;
          justify-content: flex-end;

          .btn-start-task {
            // display: none;
          }
        }
        .view-assessment-score {
          flex-wrap: nowrap;
        }
      }

      &:hover {
        .buttons-wrapper ion-buttons {
          display: flex;
        }
      }

      &::part(native) {
        // height: 42px;
        // align-items: flex-start;
        @media screen and (max-width: 530px) {
          --padding-start: 10px;
        }
        // slot {
        //   color: black;
        // }
      }

      ion-checkbox {
        margin: 0px 16px 0px 0;
      }
      ion-reorder {
        margin: 10px 0px 10px 10px;
        @media screen and (max-width: 530px) {
          // display: none;
          // position: absolute;
          // margin: 0;
          // top: 0px;
          // left: 10px;
        }
      }

      &.view {
        ion-card,
        .ion-card {
          display: block;
        }
      }

      &.crossed {
        // font-style: italic;
        filter: grayscale(100%);
        b {
          text-decoration: line-through;
        }
        // .buttons-wrapper .btn-start-task {
        //   display: none;
        // }
      }
      &.deleted {
        height: 0;
        overflow: hidden;
      }
    }
  }

  .cdate-picker-wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: fixed;
    z-index: 30;
    left: 0;
    right: 0;
    animation: fadeIn 0.5s both;

    &.out {
      animation: fadeOut 0.5s both;
      .cdate-picker {
        animation: fadeOutUp 0.5s both;
      }
    }
  }

  // https://w3bits.com/css-grid-masonry/
  .masonry-with-columns > div {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
    // grid-auto-rows: 0;
    min-height: 80vh;
    margin-top: 5px;
    padding-bottom: 0;
    align-items: flex-start;
    grid-auto-rows: min-content;
  }
  ion-infinite-scroll {
    position: absolute;
    bottom: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

// }

.list-button {
  ion-icon {
    color: var(--ion-color-medium);
  }

  // padding-right: 6px;
  // font-size: 14px;
}

.list-button {
  ion-icon.dark {
    color: var(--ion-color-dark);
  }
}

// @media only screen and (man-width: 768px) {
.masonry-with-columns.list-view {
  > div {
    grid-template-columns: auto;
    // display: flex!important;
    // flex-direction: column;
    // @media only screen and (max-width: 500px) {
    //   margin-left: 0!important;
    //   margin-right: 0!important;
    // }
  }

  // .crossed {
  //   display: none;
  // }

  ion-card,
  .ion-card,
  .multiple-selection.enabled {
    box-shadow: none;
    border-radius: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
    // border-bottom: 1px solid var(--ion-color-light-medium);
    &:not(.completed),
    &.card-inspection {
      border-bottom: 1px solid var(--ion-color-light-medium);
    }

    &.card-order {
      border-right: 0;
      border-top: 0;
      padding-top: 0;
      margin-top: 0;
      border-left: 0;
      ion-card-content {
        max-height: inherit;
      }
      .share-button,
      .create-report-button,
      .redo-button {
        // display: block!important;
      }
    }

    .card-images {
      // flex-wrap: wrap;
      display: flex;
      height: auto;
      grid-auto-flow: unset;
      flex-wrap: wrap;
      overflow: hidden;
    }

    ion-card-content,
    ion-card-header {
      // padding-left: 0;
      // padding-right: 0;
    }
  }

  .multiple-selection.enabled ion-card {
    border-right: 0;
    border-left: 0;
    border-top: 0;
    background: linear-gradient(0deg, var(--ion-color-light-medium) 0%, #fff 100%);
  }
}

.page-report-dashboard {
  .masonry-with-columns {
    .card-order {
      // box-shadow: none;
      // border-right: 0;
      // border-top: 0;
      // padding-top: 0;
      // margin-top: 0;
      // border-left: 0;
      // border-radius: 0;

      ion-card-content {
        max-height: inherit;
      }
    }
  }
}

.dark {
  .masonry-with-columns.list-view {
    .multiple-selection.enabled ion-card {
      background: linear-gradient(
        0deg,
        var(--ion-color-light-medium) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    ion-card {
      --ion-background-color: linear-gradient(
        0deg,
        var(--ion-color-light-medium) 0%,
        var(--ion-background-color) 100%
      );
    }
  }
}

// }

.modal-filter {
  // .modal-wrapper {
  //   transform: none;
  //   animation: fadeInRight .3s ease-in;
  // }
  @media (min-width: 576px) {
    justify-content: flex-end;
    --height: 100%;
    --width: 400px;
    .ion-page,
    .modal-wrapper {
      border-radius: 0 !important;
    }
  }
}

.md {
  .page-quality-control {
    ion-toolbar {
      // --min-height: 48px;
    }
  }
}
