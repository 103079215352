.commercial-dashboard {
  .loading-more-spinner {
    text-align: center;
    transform: scale(2);
    // min-height: 100px;
    margin: 30px 0 90px;
  }

  .item-datetime {
    ion-label {
      margin-right: 0;
    }
  }

  .paging {
    display: flex;
    gap: 5px;
    padding: 10px 0;
    justify-content: center;
    div {
      padding: 5px 10px;
      border: 1px solid var(--ion-color-light-medium);
      border-radius: 3px;
      font-weight: bold;
      &:hover {
        background-color: var(--ion-color-light-medium);
        cursor: pointer;
      }
      &.current {
        background-color: var(--ion-color-primary);
        border: 1px solid var(--ion-color-primary);
      }
    }
  }

  ion-datetime {
    padding: 0;
  }

  .top-filter {
    display: flex;
    padding: 5px 0;
    width: 100%;
    background: var(--ion-item-background);
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    ion-item,
    .ion-item {
      overflow: visible;
      --padding-start: 10px;
      color: var(--ion-color-medium);
      &.item-selectable {
        ion-label {
          min-width: 90px;
        }
        z-index: 03;
        &.z-08 {
          z-index: 08;
        }
        &.z-09 {
          z-index: 09;
        }
        &.z-10 {
          z-index: 10;
        }
        &.z-11 {
          z-index: 11;
        }
        &.z-12 {
          z-index: 12;
        }
        &.z-13 {
          z-index: 13;
        }
        .react-select__control {
          min-width: 200px;
        }
        > div {
          color: #333;
          input {
            // width: auto!important;
            // min-width: 150px;
          }
        }
      }

      .filter-date {
        font-size: 14px;
        .label-date {
          font-weight: bold;
          text-transform: uppercase;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            color: var(--ion-color-primary);
          }
        }
        .inputs {
          display: flex;
          align-items: center;
          height: 20px;
        }
      }

      @media (max-width: 768px) {
        --padding-start: 5px;
        --padding-end: 0px;
        &.item-datetime {
          font-size: 15px;
        }
        ion-searchbar {
          padding-left: 5px;
          padding-right: 0px;
        }
      }
    }

    .search-bar {
      @media (min-width: 768px) {
        ion-searchbar {
          // height: 48px !important;
          width: 190px;
          height: 36px;
        }
      }
      @media (max-width: 768px) {
        height: 42px;
        ion-searchbar {
          // height: 48px !important;
          height: 36px;
        }
        width: 100%;
        max-width: 100%;
      }
      .searchbar-input {
        border-radius: 4px;
      }
    }

    .date-select {
      // min-width: 300px;
      background: transparent;
      --background: transparent;
      @media (max-width: 500px) {
        max-width: 100%;
        width: 100%;
        & > div {
          width: 100%;
        }
      }
    }
    .status-select {
      max-width: 320px;
    }
  }

  ion-list {
    padding-top: 0;
    .top-resume {
      // @media (min-width: 768px) {
      position: sticky;
      top: 0px;
      z-index: 5;
      // }
      background-color: var(--ion-color-background);
      display: flex;
      align-items: stretch;
      justify-content: space-around;
      align-content: center;
      padding: 5px;
      gap: 5px;
      // box-shadow: 0px 19px 19px 0px rgb(0 0 0 / 31%);
      margin-bottom: 5px;
      // border-bottom: 1px solid var(--ion-color-light-medium);
      & > div {
        font-weight: bold;
        background-color: var(--ion-color-light);
        border-radius: 5px;
        padding: 10px;
        flex: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          // background-color: lighten(#bf79e9, 20);
          background-color: var(--ion-color-light-medium);
        }

        @media only screen and (max-width: 600px) {
          font-size: 13px;
          white-space: normal;
        }

        &.selected {
          background-color: var(--ion-color-tertiary);
          color: white;
        }
      }
    }

    ion-item {
      border-bottom: 1px solid var(--ion-color-light);
      overflow: visible;
      ion-label:nth-child(2) {
        text-align: right;
      }
      .lotId {
        padding-left: 10px;
      }
      ion-grid {
        padding-top: 8px;
      }
    }
    .order-div {
      border-bottom: 1px solid var(--ion-color-light-medium);
      // padding-bottom: 10px;
      margin-bottom: 10px;
      animation: fadeIn 0.3s ease;

      .date-badge {
        display: flex;
        align-items: center;
        padding: 0;
        overflow: hidden;

        .date-div {
          padding: 1px 3px;
          background-color: var(--ion-color-light-medium);
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        .date {
          display: flex;
          padding-top: 1px 3px;
          color: var(--ion-color-dark);
          padding: 3px;
        }

        .status {
          text-transform: uppercase;
          padding: 1px 5px 1px 4px;
        }
      }

      &.hide {
        display: none;
      }

      &.score-3 {
        border-left: 5px solid var(--ion-color-warning);
        // border-bottom: 5px solid var(--ion-color-warning);
        // border-right: 5px solid var(--ion-color-warning);
        .order-item {
          // background: var(--ion-color-warning);
          // --background: rgba(255, 204, 104, 0.1);
        }
      }
      &.score-2 {
        border-left: 5px solid var(--ion-color-danger);
        // border-right: 5px solid var(--ion-color-danger);
        // border-bottom: 5px solid var(--ion-color-danger);
        // .order-item {
        //   background: var(--ion-color-danger);
        //   --background: var(--ion-color-danger);
        // }
      }
      &.score-1 {
        border-left: 5px solid var(--ion-color-danger2);
      }
    }

    .lot-info {
      font-weight: bold;
    }

    .col-scoring {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap-reverse;
      gap: 10px;
      align-items: center;
      overflow: visible;
      max-width: 300px;
      &:empty {
        display: none;
      }
      @media only screen and (max-width: 500px) {
        max-width: 100px;
      }
    }

    .order-item {
      display: flex;
      justify-content: flex-start;
      overflow: visible;
      padding: 5px 0;
      --padding-top: 5px;
      --padding-bottom: 5px;
      background: var(--ion-color-light-medium);
      --background: var(--ion-color-light);

      @media screen and (min-width: 820px) {
        position: sticky;
        top: 51px;
        z-index: 4;
      }

      ion-router-link {
        color: var(--ion-color-dark);
        text-decoration: underline;
        &:not([router-link]) {
          text-decoration: none;
          color: var(--ion-color-medium);
        }
      }
      .contact-name {
        font-size: 17px;
        font-weight: bold;
      }

      .shareCountBadge {
        cursor: pointer;
      }
      b {
        font-size: 18px;
        font-weight: 900;
      }
      div.actions {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        &.hide {
          display: none;
        }

        @media only screen and (max-width: 600px) {
          ion-button {
            height: 30px;
            min-width: 80px;
            max-width: 130px;
          }
        }
      }

      .order-header {
        // min-width: 215px;
        & > div {
          margin-top: 5px;
          display: flex;
          gap: 5px;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
    h2 {
      font-size: 17px;
      font-weight: bold;
    }
    h4 {
      font-weight: bold;
      margin: 10px 0;
      font-size: 18px;
    }
    .assesment-info,
    .assesment-badges {
      display: flex;
      gap: 5px;
      padding-bottom: 8px;
    }
    .assesment-info {
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
    .assesment-badges {
      // display:none;
      flex-wrap: wrap;
    }
  }

  .assesment-defects {
    max-width: 800px;
    .all-defects {
      line-height: 0;
      display: flex;
      flex-flow: wrap;
      gap: 5px;
      margin-bottom: 6px;
    }

    ion-badge {
      max-width: 350px;
      overflow: hidden;
      // text-overflow: ellipsis;
    }

    span {
      // color: rgba(0,0,0,.7);
      display: inline-block;
      margin-left: 2px;
    }
    span:empty {
      display: none;
    }
    .score-4 {
      background-color: var(--ion-color-primary);
    }
    .score-3 {
      background-color: var(--ion-color-warning);
    }
    .score-2 {
      background-color: var(--ion-color-danger);
    }
    .score-1 {
      background-color: var(--ion-color-danger2);
      color: white;
      // span {
      // color: rgba(255,255,255,.9);
      // }
    }
    .score-null {
      display: none;
    }
    .actions {
      border-top: 1px solid var(--ion-color-light);
      padding-top: 5px;
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 5px;
      // justify-content: center;
      b {
        font-size: 13px;
      }
      ion-badge {
        margin: 0;
      }

      &:empty {
        display: none;
      }
    }
    .action-comment {
      // border-top: 1px solid var(--ion-color-light);
      margin-top: 5px;
      padding-top: 5px;
      font-size: 15px;
      white-space: pre-wrap;
      @media (max-width: 575px) {
        font-size: 14px;
      }
    }
    ion-badge {
      font-weight: bold;
      padding: 5px 7px;
      text-transform: uppercase;
      color: black;
      // @media (max-width: 575px) {
      //   font-size: 10px;
      // }
    }
  }

  .filter-toolbar {
    // border-top: 1px solid var(--ion-color-light-medium);
    border-bottom: 1px solid var(--ion-color-light-medium);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 5px;
    gap: 4px;
    background-color: var(--ion-item-background);
    color: var(--ion-color-medium);
    font-size: 14px;

    &:empty {
      display: none;
    }

    div {
      border-radius: 15px;
      background-color: var(--ion-color-light);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      padding-left: 5px;
      display: flex;
      white-space: nowrap;
      border: 1px solid var(--ion-color-light-medium);
      align-items: center;
      cursor: pointer;

      span {
        padding-right: 2px;
        white-space: nowrap;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      ion-icon {
        font-size: 19px;
      }
    }
  }
}
