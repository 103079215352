#main-menu {
  // --background: white !important;
  @media only screen and (min-width: 992px) {
    max-width: 250px;
    min-width: 250px;
    transition: width 0.3s, max-width 0.3s, min-width 0.3s;

    &.menu-collapsed {
      min-width: 60px;
      width: 60px;
      max-width: 60px;
      ion-icon {
        margin-right: 3px;
      }
      .logo {
        display: block;
        border-radius: 6px;
      }
      ion-title {
        display: none;
      }
      ion-toggle {
        display: none;
      }
    }
  }

  ion-icon {
    transition: margin-right 0.3s;
  }

  .update-menu-item {
    animation: flash 1s both;
    cursor: pointer;
    // animation-iteration-count: 2;
  }

  .logo {
    display: none;
    margin-left: 10px;
    width: 38px;
    // width: 38.4px;
    animation: fadeInUp 0.3s both;
    cursor: pointer;
  }

  ion-title {
    cursor: pointer;
    user-select: none;
    animation: fadeInUp 0.3s both;
    padding-left: 20px;
    padding-right: 30px;
    font-size: 20px;
    overflow: visible;
    text-align: left;
    .toolbar-title {
      overflow: visible !important;
    }
  }

  .enviroment {
    font-weight: 900;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 16px;
    color: var(--ion-color-dark);
    &.production {
      color: var(--ion-color-danger2);
    }
  }
  ion-list {
    ion-label {
      padding: 4px 0;
    }
    ion-icon {
      margin-right: 15px;
    }
    .selected {
      --background: var(--ion-color-light-medium);
    }
  }
  .button-container {
    // padding: 15px;
    width: 100%;
    // max-width: 269px;
    bottom: 0;
    position: absolute;
    padding-bottom: 15px;
    @media screen and (max-width: 844px) {
      position: relative;
    }
  }
}

// react-tooltip
.place-right.type-custom {
  opacity: 1 !important;
  font-weight: bold;
  text-transform: uppercase;
}

.ios {
  #main-menu {
    @media only screen and (min-width: 992px) {
      &.menu-collapsed {
        min-width: 65px;
        max-width: 65px;
        width: 65px;
        ::part(detail-icon) {
          display: none;
        }
        .logo {
          margin-left: 12px;
          width: 32px;
        }
      }
    }
  }
}
