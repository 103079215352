.modal-share-report {
  .bottom-button {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 5;
  }

  .share-link {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid var(--ion-color-light-medium);
    a {
      color: var(--ion-color-tertiary);
    }
  }

  .loading {
    text-align: center;
    ion-spinner {
      margin: 30px 0 90px;
      // animation: fadeInDown .3s both;
      transform: scale(2);
      // min-height: 100px;
    }
  }

  ion-item {
    --min-height: 30px;
    --padding-top: 0px;
    --padding-bottom: 0px;

    &.group-item {
      --background: var(--ion-color-light);
      border-bottom: 2px solid var(--ion-color-light-medium);
      .group-label {
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
        font-weight: bold;
        text-overflow: ellipsis;
        ion-icon {
          min-width: 24px;
        }
      }
    }

    ion-label {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    ion-checkbox {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  ion-item-divider {
    padding-right: 10px;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid var(--ion-color-light-medium);
  }

  ion-title {
    align-items: center;
    display: flex;
    ion-badge {
      margin-top: 4px;
      position: absolute;
      margin-left: 10px;
    }
  }

  ion-list-header {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    background-color: var(--ion-color-light);
    // border-bottom: 1px solid var(--ion-color-light-medium);
  }

  .contact {
    padding: 10px;
    background-color: var(--ion-color-light);
    border-bottom: 3px solid var(--ion-color-light-medium);
    text-transform: capitalize;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    // white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 0;
    ion-badge {
      flex: 0 0;
      flex-basis: auto;
    }
  }

  .search-bar {
    display: flex;
  }

  .bulk-list {
    &:empty {
      display: none;
    }
  }

  .grid {
    display: flex;
    @media (max-width: 490px) {
      flex-direction: column;
      position: relative;
    }
    > * {
      flex: 1;
    }

    .first-col {
      overflow: auto;
      max-height: 100%;
      border-right: 1px solid var(--ion-color-light-medium);
      // height: 100%;
      @media (max-width: 490px) {
        max-height: 280px;
        border-right: 0;
        border-bottom: 1px solid var(--ion-color-light);
      }

      ion-checkbox {
        margin-right: 20px;
      }
      ion-list {
        padding-top: 0;
      }
      // min-width: 300px;
    }

    .second-col {
      display: flex;
      flex-direction: column;
      padding-bottom: 40vh;

      ion-list-header {
        border-bottom: 1px solid var(--ion-color-light-medium);
      }

      @media (min-width: 768px) {
        padding-bottom: initial;
      }

      ion-list {
        flex: 0;
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          @media (max-width: 490px) {
            border-bottom: 1px solid var(--ion-color-light);
          }
        }
        &:nth-child(2) {
          margin-bottom: 60px;
        }
      }
    }
  }
}

.bulkEmailValidator {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  gap: 5px;
  padding: 10px 0;
  &:empty {
    display: none;
  }
  .bulkEmailValidatorItem {
    display: block;
    margin-top: 1px;
    cursor: pointer;
    .bulkEmailValidatorContainer {
      display: flex;
      align-items: center;
      border-radius: 15px !important;
      background-color: var(--ion-color-primary);
      color: black;
      padding-right: 1px;
      padding-left: 4px;
      font-size: 14px;
      color: white;
      .bulkEmailLabel {
        padding: 1px 1px 1px 4px;
      }
    }
    .invalidEmailContainer {
      color: white;
      background-color: var(--ion-color-danger);
    }
  }
}

.contact-group-modal {
  --height: 400px;
  --width: 400px;

  color: black;
  --backdrop-opacity: 50%;

  .new-group-input-group {
  }
  .hidden {
    display: none;
  }
}
