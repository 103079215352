.card-inspection:hover {
  .bottom-buttons {
    display: flex;
    animation: fadeIn 0.3s both;
  }
}

.card-sub-title {
  display: flex;
  align-items: center;
}

.supplier {
  display: flex;
  font-weight: bold;
  align-items: center;
  color: var(--ion-color-dark);
  // line-height: 14px;
}

.second-card {
  // order: 1;
  position: absolute;
  top: 40px;
  width: 100%;
  opacity: 1;
  // z-index: 10;
  animation: fadeInUp 0.3s both;
  animation-delay: 0.05s;
  ion-card,
  .ion-card {
    box-shadow: 0px 0px 20px rgba(35, 35, 39, 0.2);
  }
}

.linked-lot {
  display: flex;
  align-items: center;
}

.linked-id {
  ion-icon {
    font-size: 20px;
  }
}

.background-card {
  opacity: 0;
  width: 100%;
  display: block;
}

.first-card {
  width: 100%;
  opacity: 0.6;
  position: absolute;
  max-height: 50px;
  overflow: hidden;
  ion-card,
  .ion-card {
    pointer-events: none;
    box-shadow: none;
    border: 3px solid var(--ion-color-tertiary);
    .card-images {
      display: none;
    }
    ion-card-header {
      padding-top: 11px;
    }
    ion-card-title {
      font-size: 18px;
      color: var(--ion-color-tertiary);
      ion-icon {
        font-size: 20px;
      }
    }
  }
}

.double-card {
  position: relative;
  display: flex;
  margin-bottom: 70px;

  &.swap {
    .first-card {
      max-height: auto;
      overflow: visible;
      z-index: 10;
      top: 40px;
      opacity: 1;
      ion-card,
      .ion-card {
        pointer-events: all;
        box-shadow: 0px 0px 20px rgba(35, 35, 39, 0.2);
        .card-images {
          display: flex;
        }
      }
    }
    .second-card {
      top: 0;
      max-height: 200px;
      overflow: hidden;
      opacity: 0.6 !important;
      ion-card,
      .ion-card {
        pointer-events: none;
      }
    }
  }
}

.popup-icon {
  margin-right: 15px !important;
}

.card-inspection {
  .location {
    width: 100%;
    padding: 5px 15px;
    color: var(--ion-color-dark) !important;
    background: var(--ion-color-light);
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.5;
    font-size: 14px;
  }

  // .view-article-description {
  //   margin-bottom: 10px;
  // }

  .assessment-date {
    font-size: 12px;
    font-weight: bold;
  }

  .lot-article-description {
    // padding-bottom: 0;
  }

  .batch-summary {
    display: flex;
    gap: 5px;
    flex-direction: column;
    border-top: 1px solid var(--ion-color-light-medium);
    margin-top: 10px;
    padding-top: 10px;
    /*     &:first-child {
      margin-top: 0;
    } */
    .batch-summary-score {
      margin-left: auto;
      min-width: 80px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .batch-summary-title {
      display: flex;
      align-items: center;
      ion-badge {
        padding: 5px 6px;
        font-size: 13px;
      }
      & > div:first-child {
        align-items: center;
        display: flex;
      }
    }
    .assessment-date {
      color: var(--ion-color-light);
    }
  }

  ion-badge {
    // max-width: 188px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &.go-to-top {
    order: -1;
  }
  .cover {
    background: var(--ion-color-light-medium);
    position: relative;
    ion-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      box-shadow: 0px 0px 10px rgba(35, 35, 39, 0.3);
      cursor: pointer;
      color: rgba(35, 35, 39, 0.9);
      display: flex;
      align-items: center;
      gap: 5px;
      max-width: 200px;
      &.danger2 {
        color: rgba(255, 255, 255, 0.9);
      }
      ion-icon {
        font-size: 18px;
      }
      &.primary {
        // color: var(--ion-color-primary);
      }
    }
  }
  ion-item {
    --padding-start: 0;
  }
  ion-button {
    margin-top: 0px;
  }

  ion-content:empty,
  .defects:empty {
    display: none;
  }

  ion-card-title,
  ion-card-title a {
    display: flex;
    gap: 3px;
    font-weight: 900;
    color: var(--ion-color-dark);
    text-decoration: none;
  }

  .grower-name {
    font-weight: bold;
    // color: var(--ion-color-medium);
    text-transform: uppercase;
    font-size: 14px;
    // margin-bottom: 5px;
    &:empty {
      display: none;
    }
  }

  .report-icon {
    ion-icon {
      font-size: 20px;
    }
  }

  .highlight {
    background-color: var(--ion-color-secondary);
    color: black;
    padding: 0 3px;
    border-radius: 3px;
  }

  .bottom-buttons {
    display: none;
    // display: flex;
    // animation: fadeOut .3s both;
    padding: 0 13px 13px 13px;
    ion-button {
      // font-size: 13px;
      --padding-end: 5px;
      --padding-start: 5px;
      margin-top: 0;
    }
    * {
      flex: auto;
    }
  }

  .has-transfers {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 5px;
    // background-color: rgba(255,255,255,.2);
    // color: #222;
    border-radius: 15px;
    font-size: 20px;
  }

  ion-card-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    overflow-wrap: anywhere;
    padding-bottom: 13px;
    > div:nth-child(2) {
      margin-left: auto;
      min-width: 91px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &:empty {
        display: none;
      }
    }
    .view-assessment-score {
      margin-bottom: 5px;
    }

    .score-type-section {
      display: flex;
      gap: 2px;
      align-items: center;
    }

    .user {
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    .date-report-type {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 3px;
      margin-bottom: 10px;
      &:empty {
        display: none;
      }
      margin-top: 5px;
    }

    .arrival-date {
      display: inline-flex;
      text-transform: uppercase;
    }

    .report-type-badge {
      // color: #000000;
      padding-left: 7px;
      padding-right: 7px;
      text-transform: uppercase;
    }

    .inspection-print-link {
      display: inline-block;
      margin-left: 10px;
      text-decoration: underline;
      cursor: pointer;
    }

    .waste {
      padding-top: 4px;
      :first-child {
        font-size: 12px;
      }
    }

    .quantity {
      border: 2px solid var(--ion-color-light-medium);
      display: flex;
      flex-direction: column;
      line-height: 16px;
      // background: var(--ion-color-light-medium);
      border-radius: 5px;
      padding: 4px 6px;
      text-align: center;
      font-weight: bold;
      min-width: 55px;
      opacity: 0.8;
      color: var(--ion-color-dark);
      :first-child {
        font-size: 16px;
        font-weight: 900;
      }
      :nth-child(2) {
        text-transform: uppercase;
        font-size: 12px;
      }
      small {
        font-size: 12px !important;
        display: block;
        font-weight: bold !important;
      }
    }
  }

  .card-inspection-wrapper {
    // display: flex;
    // & > * {
    //   flex: 1 1;
    // }
  }

  // .view-assessment-score + .quantity {
  //   position: absolute;
  //   right: -1px;
  //   margin-top: 10px;
  // }

  .defects {
    padding-top: 0px;
    .all-defects {
      line-height: 0;
      display: flex;
      flex-flow: wrap;
      gap: 5px;

      .expandable {
        cursor: pointer;
      }

      .group_action {
        order: 100;
      }
      .score-4 {
        order: 1;
      }
      .score-3 {
        order: 2;
      }
      .score-2 {
        order: 3;
      }
      .score-1 {
        order: 4;
      }
      .boxes-count {
        order: 0;
      }
    }

    span:nth-child(1) {
      // color: rgba(0,0,0,.7);
      // text-transform: uppercase;
      // max-width: 180px;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    span:nth-child(2) {
      // color: rgba(0,0,0,.7);
      margin-left: 4px;
      margin-left: 4px;
      background: rgba(0, 0, 0, 0.1);
      padding: 4px 6px;
      margin-top: -4px;
      margin-bottom: -4px;
      // max-width: 140px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      margin-right: -6px;
      // min-width: 30px;
    }
    span:empty {
      display: none;
    }
    .score-4 {
      background-color: var(--ion-color-primary);
    }
    .score-3 {
      background-color: var(--ion-color-warning);
    }
    .score-2 {
      background-color: var(--ion-color-danger);
    }
    .score-1 {
      background-color: var(--ion-color-danger2);
      color: white;
      // span {
      // color: rgba(255,255,255,.9);
      // }
    }
    .score-undefined {
      color: white;
    }
    .score-null {
      display: none;
    }
    .actions {
      border-top: 1px solid var(--ion-color-light);
      padding-top: 5px;
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 5px;
      // justify-content: center;
      b {
        font-size: 13px;
      }
      ion-badge {
        margin: 0;
      }

      &:empty {
        display: none;
      }
    }
    .action-comment {
      // border-top: 1px solid var(--ion-color-light);
      margin-top: 5px;
      padding-top: 5px;
      font-size: 15px;
      white-space: pre-wrap;
      @media (max-width: 575px) {
        font-size: 14px;
      }
    }
    ion-badge {
      font-weight: bold;
      padding: 5px 7px;
      color: black;
      // max-width: 240px;
      display: inline-flex;
      // @media (max-width: 575px) {
      //   font-size: 10px;
      // }
    }
  }
}
