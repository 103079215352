.view-assessment-status {
  box-shadow: 0px 0px 10px rgba(35, 35, 39, 0.3);
  cursor: pointer;
  color: rgba(35, 35, 39, 0.9);
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: none;
  &.danger2 {
    color: rgba(255, 255, 255, 0.9);
  }
  ion-icon {
    font-size: 18px;
  }
  &.primary {
    // color: var(--ion-color-primary);
  }
}
