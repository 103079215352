// --------------------------------
// ocr-view
// --------------------------------
.ocr-view {
  ion-list {
    max-width: 500px;
    // margin-left: auto!important;
    // margin-right: auto!important;
    box-shadow: 0px 0px 20px rgba(35, 35, 39, 0.2);
  }
  ion-select {
    padding-right: 10px;
    color: var(--ion-color-dark);
    // --placeholder-color: var(--ion-color-dark)!important;
    --placeholder-opacity: 1;
  }
  ion-item {
    --inner-border-width: 0;
    b {
      color: #222;
      display: flex;
      gap: 10px;
      ion-badge {
        background-color: var(--ion-color-danger2);
        text-transform: uppercase;
        font-weight: bold;
        padding-left: 6px;
        padding-right: 6px;
      }
    }
    .ocr-code {
      font-family: monospace;
      font-size: 16px;
      animation: fadeInUp 0.3s both;
    }
  }
}
