.page-scoring {
  .card-wrapper {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  h2 {
    font-weight: bold;
    color: var(--ion-color-dark);
    padding: 10px;
    font-size: 18px;
  }

  ion-select {
    min-width: 300px;
    text-align: right;
  }

  small {
    font-size: 16px;
  }
  .thanks {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .score-big {
    font-size: 2em;
    color: #000000;
  }

  .center-vertically {
    display: flex;
    align-items: center;
  }

  .big-text {
    font-size: 2em;
  }

  .medium-text {
    font-size: 1.5em;
  }

  .alert-wrapper {
    max-width: unset !important;
    width: 80% !important;
  }

  .alert-confirm {
    color: var(--ion-color-danger) !important;
  }

  .alert-cancel {
    color: var(--ion-color-success) !important;
  }

  ion-range.slider-with-pin::part(pin) {
    transform: translate3d(0px, -16px, 0px) scale(0.75);
  }

  .slider-with-pin {
    //--bar-background:#ccc;
    //--bar-background-active:blue;
    //--bar-border-radius:50;
    //--bar-height:10px;
    --height: 20px;
    //--knob-background:red;
    //--knob-border-radius:30;
    //--knob-size:20px;
    &.score-4 {
      --bar-background-active: var(--ion-color-success);
    }
    &.score-3 {
      --bar-background-active: var(--ion-color-warning);
    }
    &.score-2 {
      --bar-background-active: var(--ion-color-danger);
    }
    &.score-1 {
      --bar-background-active: var(--ion-color-danger2);
    }
  }

  .alert-reset-scores {
    .accept-button-alert {
      color: #ffffff;
      background-color: var(--ion-color-danger);
    }
  }

  .range-4 {
    border-radius: 4px;
    --background: var(--ion-color-primary) !important;
  }

  .range-3 {
    border-radius: 4px;
    --background: var(--ion-color-warning) !important;
  }

  .range-2 {
    border-radius: 4px;
    --background: var(--ion-color-danger) !important;
  }

  .range-1 {
    border-radius: 4px;
    --background: var(--ion-color-danger2) !important;
  }

  .buttons-row {
    justify-content: flex-end;
  }

  .progress-bar {
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #ddd;
    border-radius: 4px;
    overflow: hidden;
    margin: 10px 0;
    span {
      color: black;
      font-weight: bold;
      margin-top: 4px;
      display: inline-block;
    }
  }

  .col-header {
    font-weight: bold;
    font-size: 16px;
    color: var(--ion-color-dark);
  }

  .slider-no-pin {
    --height: 20px;
  }

  .information-field {
    background-color: #dddddd;
    font-size: 1.2em;
    white-space: pre-wrap;
  }

  .green-item {
    --background: var(--ion-color-primary) !important;
  }

  // .desktop {
  //   .modal-wrapper {
  //     height: 80%;
  //     // width: 90%;
  //     //display: block;
  //     max-width: 1000px!important;
  //   }
  // }

  .label-defect {
    white-space: break-spaces;
    min-width: 150px;
  }

  .spec-builder {
    ion-input {
      --background: #ffffff;
      border-radius: 5px;
      border: 1px solid;
      border-color: var(--ion-color-light-medium);
      input {
        padding-left: 10px !important;
        padding-right: 10px;
      }
    }

    ion-item {
      overflow: visible;
      --highlight-background: transparent;
      &.item-selectable {
        z-index: 10;
        &.z-11 {
          z-index: 11;
        }
        &.z-12 {
          z-index: 12;
        }
        > div {
          min-width: 200px;
          input {
            width: auto !important;
          }
        }
      }
    }
  }

  .layout-card {
    padding-bottom: 0px;
  }
}
