.dark {
  .page-filters {
    ion-item {
      overflow: visible;
      &.item-selectable {
        > div > div {
          background-color: hsl(0, 0%, 6%);
          color: var(--ion-color-tertiary);
          input {
            color: white !important;
          }
        }
      }
    }
  }
}

.page-filters {
  @media (max-width: 320px) {
    * {
      font-size: 15px;
    }
  }
  .react-select__control {
    margin-top: 1px;
    min-width: 209px;
    .react-select__single-value {
      font-size: 14px;
    }
    .react-select__multi-value__label {
      font-size: 13px;
    }
  }
  .gets-blurry {
    // transition: opacity 0.3s ease;
  }

  .refresh-stock-button {
    margin-right: 15px;
    animation: fadeInUp 0.3s both;
    &.refreshing ion-icon {
      animation: rotation 1s infinite linear;
    }
  }

  .search-by-text-enabled {
    opacity: 0.5;
    pointer-events: none;
    filter: blur(2px);
  }

  ion-buttons ion-button.selected::part(native) {
    background-color: var(--ion-color-tertiary);
    color: white;
    border-radius: 5px;
  }

  .view-mode {
    ion-label {
      margin-bottom: 5px;
    }
  }

  .datePicker {
    display: flex;
    align-items: center;
    background-color: var(--ion-item-background);
    padding: 5px 10px 0px 10px;

    .label-dates {
      text-align: right;
      min-width: 220px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: visible;
      margin-left: auto;
    }

    .label {
      display: flex;
      align-items: center;
    }

    ion-label {
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .datePicker-label {
      margin-right: 8px;
      font-weight: bold;
      font-size: 14px;
      color: var(--ion-color-medium);
    }

    .sorting-text {
      font-size: 12px;
      font-weight: bold;
      width: 68px;
      text-transform: uppercase;
      color: var(--ion-color-medium);
    }

    ion-datetime {
      padding: 4px 8px;
      border-radius: 8px;
      font-size: 15px;
      &:hover,
      &:focus {
        background-color: var(--ion-color-light-medium);
      }
    }
    .datePicker-end,
    .datePicker-start {
      &:hover,
      &:focus {
        cursor: pointer;
        background-color: var(--ion-color-light-medium);
        border-radius: 8px;
      }
    }

    ion-buttons {
      // margin-left: 5px;
    }

    .datePicker-selects {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 2px;
    }
  }

  .md .sort-control {
    margin-right: 6px;
  }
  .sort-control {
    margin-bottom: 3px;
  }

  .bottom-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .search-by-id {
    border-bottom: 1px solid var(--ion-color-light-medium);
  }

  ion-searchbar {
    --padding-start: 10px;
    max-width: 209px;
    padding: 5px 0;
    height: 48px;
    margin-left: auto;
    border-radius: 8px;
    // text-align: right;
    background-color: transparent;
    input + ion-icon {
      display: none;
    }
    input {
      padding-left: 15px !important;
      // padding-right: 15px;
    }
    ion-icon {
      color: var(--ion-color-dark);
    }
  }
  .filter-button,
  .clear-button {
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }

  ion-select {
    max-width: 222px;
    font-size: 15px;
  }
  .saved-filters {
    background-color: var(--ion-item-background);
    padding: 5px 0px 5px 0px;
    margin-bottom: 5px;
    overflow-x: hidden;
    border-bottom: 1px solid var(--ion-color-light-medium);
    ion-select {
      max-width: 100%;
    }
    .ion-label {
      position: relative;
      margin-bottom: 5px;
      padding-bottom: 10px;
      padding-top: 10px;
      font-size: 15px;
      padding-left: 9px;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid var(--ion-color-light-medium);
    }
    .saved-filters-back-button {
      position: absolute;
      right: 5px;
      top: 0;
    }
    .saved-filter-list {
      // max-height: 110px;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
    }
    .saved-filter-item {
      display: flex;
      padding: 5px 10px;
      position: relative;
      align-items: center;

      &.selected {
        font-weight: bold;
        background: var(--ion-color-tertiary) !important;
        color: var(--ion-color-white) !important;
        // order: -1;
        .delete-link {
          color: var(--ion-color-white) !important;
        }
      }

      &.no-results {
        font-style: italic;
        background: none !important;
        cursor: auto !important;
      }

      &:hover {
        background-color: var(--ion-color-light-medium);
        color: var(--ion-color-dark);
        // border-radius: 5px;
        cursor: pointer;
        .delete-link {
          display: block;
          background: transparent;
        }
      }
    }
    .delete-link {
      z-index: 9;
      display: none;
      // position: absolute;
      background-color: var(--ion-color-light-medium);
      padding: 0 10px;
      // right: 10px;
      margin-left: auto;
      font-weight: bold;
      font-size: 14px;
      animation: fadeInRight 0.3s both;
      /*       text-decoration: underline; */
      color: var(--ion-color-dark);
    }
  }
  ion-list {
    overflow: visible;
    // padding-bottom: 150px;
    padding-top: 5px;
    background: var(--ion-item-background);
  }
  ion-item {
    overflow: visible;
    ion-label {
      font-size: 15px !important;
    }
  }
}

.culo .select-interface-option {
  // font-family: monospace;
  // >:before {
  //     content: attr(class)
  // }
  // ::first-word {
  //     font-family: monospace;
  // }
}
