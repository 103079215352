/* Document Level Styles */

/*
  The imports below are needed to include our light dom css for global styles such as fonts and colors.
  You can comment out any of these imports if you do not need that css. For example, if you have your own
  global font family css then you can comment out the typography.css import.
*/

/** Core CSS required for ionic components to work property */
@import '~@ionic/core/css/core';

/** Basic CSS for apps built with Ionic */
@import '~@ionic/core/css/normalize';
@import '~@ionic/core/css/structure';
@import '~@ionic/core/css/typography';

/** Optional CSS utils that can be commented out */
@import '~@ionic/core/css/padding';
@import '~@ionic/core/css/float-elements';
@import '~@ionic/core/css/text-alignment';
@import '~@ionic/core/css/text-transformation';
@import '~@ionic/core/css/flex-utils';

//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,100&display=swap");

/*
  The CSS Variables below can be used to theme your app.
  For more info on CSS variables check out:
  https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_variables

  More info about color theming using Ionic:
  https://ionicframework.com/docs/theming/color-generator
*/

@import 'print.scss';
@import 'intercom.scss';
@import 'animations.scss';

// popover temporary fix for chrome
.popover-content {
  min-width: 300px !important;
  min-height: 350px !important;

  .popover-viewport {
    display: block;
    width: 100%;
    min-height: 350px;
    padding: 0;
    background-color: #fff;
  }
}
// ----------------------------------------------------------------
// https://github.com/ionic-team/ionic-framework/issues/23001
// https://bugs.webkit.org/show_bug.cgi?id=222654
// Fix for bug: ios, scroll latching incorrect on pwas
// ----------------------------------------------------------------
html,
body {
  position: fixed !important;
}

// ----------------------------------------------------------------
// add more responsive interactions on mobile
// ----------------------------------------------------------------
ion-button,
ion-back-button,
ion-select,
html,
body {
  touch-action: manipulation;
}

.ion-page-hidden {
  content-visibility: hidden;
}

.no-ripple {
  --ripple-color: transparent;
  --background-activated: transparent;
}

body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;

  ion-split-pane {
    animation: fadeIn 1.3s both;
  }

  // #root ion-app {
  // 	max-width: 1000px;
  // }
}

.error-toast::part(message) {
  word-break: break-word;
}

.error-boundary-message {
  // background: url('assets/digital_painting_flying_avocados_in_fire_in_the_sky.jpeg');
  // background-size: cover;
  // background-position: center center;

  // background: url('assets/fruits-pattern.avif') repeat;
  // background-size: 400px;

  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  height: 100vh;
  flex-direction: column;
  padding: 15px;
  text-align: center;
  gap: 15px;

  // &:before {
  // 	content: '';
  // 	width: 100%;
  // 	height: 100%;
  // 	background-color: rgba(0,0,0,.1);
  // 	position: absolute;
  // 	pointer-events: none;
  // }
  > div:first-child {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    font-size: 32px;
  }

  .error-id {
    font-size: 16px;
    text-transform: none;
    font-weight: normal;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 10px 15px;
    border-radius: 8px;
    letter-spacing: 0;

    a {
      color: white;
      text-decoration: none;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .code {
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 14px;
    width: 800px;
    max-height: 300px;
    overflow: scroll;
    text-transform: none;
    font-weight: normal;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.ion-text-wrap {
  overflow-wrap: anywhere;
}

@media only screen and (max-width: 600px) {
  .hide-only-mobile {
    display: none !important;
  }

  .show-only-mobile {
    display: inherit;
  }
}

@media only screen and (min-width: 601px) {
  .show-only-mobile {
    display: none;
  }

  .hide-only-mobile {
    display: inherit;
  }
}

// hack to prevent default webkit autofill background color
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px var(--ion-color-primary) inset;
  // color: #222;
}

// remove arrows from input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sc-ion-buttons-ios-s ion-button {
  font-size: 14px;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  .sc-ion-modal-md-h {
    --width: 750px;
    --height: 750px;

    &.map-picker-modal {
      --width: 90%;
      --height: 90%;
    }
  }
}

// ion-modal {
// 	animation: fadeIn .3s both;
// }

// popover fix for IOS, more padding, smaller letters
ion-popover .sc-ion-popover-ios {
  ion-list-header {
    font-size: 18px;
  }

  ion-item {
    // padding: 2px 0;
    --padding-top: 2px;
    --padding-bottom: 2px;
    font-size: 14px;
    // @media (max-width: 490px) {
    // }
  }

  ion-item-divider {
    display: none;
  }
}

.dark {
  .react-select__control {
    border-color: hsl(0, 0%, 20%);
    background-color: hsl(0, 0%, 20%);
  }

  .react-select__menu {
    background-color: hsl(0, 0%, 20%);
    color: var(--ion-color-tertiary);

    input {
      color: white !important;
    }
  }

  .react-select__single-value {
    color: white;
  }

  .react-select__input {
    color: white;
  }
}

// -------------------------------------
// react-select component global styles
// -------------------------------------
.react-select__control {
  border-color: var(--ion-color-light-medium);
  border-width: 2px;

  &:hover {
    border-color: var(--ion-color-tertiary);
  }
}

.react-select__control--is-focused {
  box-shadow: 0 0 0 2px var(--ion-color-tertiary) !important;
  border-color: var(--ion-color-tertiary) !important;
  // color: white!important;
}

.react-select__placeholder {
  font-size: 14px;
}

.react-select__option {
  color: var(--ion-color-dark) !important;

  .option {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    span {
      text-overflow: ellipsis;
      overflow: hidden;

      &:nth-child(1) {
        font-weight: bold;
        text-transform: capitalize;
      }

      &:nth-child(2) {
        font-size: 13px;
      }
    }
  }
}

.react-select__option--is-active {
  background-color: var(--ion-color-tertiary) !important;
  color: white !important;

  ion-badge {
    background-color: white !important;
    color: #222;
  }
}

.react-select__option--is-selected {
  background-color: transparent !important;
  color: var(--ion-color-dark) !important;
  font-weight: bold;
}

.react-select__option--is-focused {
  background-color: var(--ion-color-tertiary) !important;
  color: white !important;

  ion-badge {
    background-color: white !important;
    color: #222;
  }
}

.react-select__multi-value__remove {
  color: #333 !important;
}

.react-select__menu-portal {
  > div {
    color: #333;

    input {
      // width: auto!important;
      min-width: 150px;
    }
  }
}

ion-app {
  // transition when the offline banner is shown on top
  transition: margin-top 0.3s;

  .tutorial-slides {
    z-index: 200;

    ion-slides {
      animation-delay: 0.7s;
      animation: fadeInUp 0.3s both;
      height: 100%;
    }
  }

  &.offline {
    margin-top: 10px;
    overflow: visible !important;

    &.ios {
      #app-offline {
        top: -10px !important;
      }
    }
  }

  .dots-spinner {
    margin: auto;
    transform: scale(2);
    display: block;
    margin-top: 30px;
  }

  #app-offline {
    background-color: black;
    color: white;
    text-align: center;
    font-size: 10px;
    pointer-events: none;
    z-index: 999;
    text-transform: uppercase;
    // opacity: .9;
    animation: fadeInDown 0.3s;
    position: fixed;
    top: -10px;
    width: 100%;

    span {
      margin-left: 20px;
    }

    ion-icon {
      font-size: 12px;
      position: absolute;
    }

    @media (min-width: 768px) {
      text-align: left;
      padding-left: 10px;
    }
  }

  ion-toolbar {
    // --background: var(--ion-color-light)!important;
  }

  ion-button[disabled='true'],
  .button-disabled {
    opacity: 0.6 !important;
  }

  .main-reload-button {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    animation: fadeIn 1s both;
    animation-delay: 8s;
    z-index: 999999999;
    padding: 15px;
    text-transform: uppercase;

    ion-button {
      display: block;
    }
  }

  ion-page.ion-page-hidden {
    display: none;
  }
}

.darkmode-moon {
  display: block;
}

.darkmode-sunny {
  display: none;
}

.dark {
  .darkmode-moon {
    display: none;
  }

  .darkmode-sunny {
    display: block;
  }
}

.clearfix {
  clear: both;
}

.alert-button {
  font-weight: bold;

  &.danger-button {
    color: var(--ion-color-danger2);
  }
  &.success-button {
    color: var(--ion-color-success);
  }
  &.neutral-button {
    color: var(--ion-color-medium);
  }
}

ion-header {
  ion-toolbar {
    ion-text.subTitle {
      padding: 0px 16px 6px 16px;
    }
  }
}

ion-app.offline {
  .sync-fab {
    display: none !important;
  }
}

.loading-wrapper {
  // background: var(--ion-background-color);
  // top: 5px;

  ion-icon {
    font-size: 36px !important;
    background-color: var(--ion-background-color);
    border-radius: 50%;
  }
}

// ion-card::part(native) {
// 	border-radius: 15px;
// }

ion-action-sheet.select-action-sheet {
  --button-color: var(--ion-color-dark);
}

.sw-update {
  .image-container {
    height: 200px;
    overflow-y: hidden;
    position: relative;

    img {
      width: 100%;
    }

    .brand-logo {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 200px;
      margin-left: -100px;
      margin-top: -30px;
    }
  }

  ion-card-content {
    text-align: center;
  }
}

ion-range.ion-range-quality {
  --bar-background: red;
  --bar-background-active: green;
  padding-left: 0;
  padding-right: 0;
}

ion-list-header {
  ion-button {
    height: 28px;
  }
}

.h1-not-found {
  grid-column: 1/-1;
  animation: fadeInUp 0.3s both;
  animation-delay: 0.1s;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--ion-color-medium);
  margin-top: 60px;
  letter-spacing: 2px;
}

ion-toolbar {
  .b-title {
    font-size: 18px;
    padding-left: 10px;

    @media (max-width: 500px) {
      font-size: 16px;
    }
  }

  ion-title:not([size='large']) {
    small {
      display: block;
      clear: both;
    }

    @media (max-width: 500px) {
      // white-space: break-spaces;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;

      // padding: 0 5px;
      div {
        font-weight: bold;
        // white-space: break-spaces;
        font-size: 16px;
        line-height: 20px;
        // padding: 0 5px;
      }
    }
  }
}

ion-item {
  // background: white;
  // --border-color: #ccd8dd;
  // --min-height: 50px;
  ion-button[expand='full'] {
    width: 100%;
  }

  ion-button.full-width {
    width: 100%;

    ion-icon {
      margin-right: 10px;
    }

    .button-inner {
      --padding-top: 10px;
      --padding-bottom: 10px;
    }
  }

  ion-button.icon-button {
    width: auto;

    ion-icon {
      margin-right: 0;
    }
  }
}

ion-fab.big {
  ion-fab-button {
    width: 80px;
    height: 80px;
    right: 90px;
    position: fixed;
    bottom: 20px;
  }
}

// ion-fab.fab-horizontal-start {
// 	left: 20px;
// }
// ion-fab.fab-horizontal-end {
// 	right: 20px;
// }

ion-fab.fab-vertical-bottom {
  bottom: 20px;
}

ion-fab.align-to-big {
  bottom: 35px;
  position: fixed;
}

ion-fab-button.gallery-fab {
  --border-radius: 10px;

  ion-icon {
    color: white;
  }
}

ion-picker {
  touch-action: none;
}

ion-header.brand-header {
  ion-toolbar {
    --background: var(--ion-color-primary);
    --color: white;

    ion-back-button {
      --color: white;
    }
  }
}

.rotate {
  animation: rotation 2s infinite linear;
}

ion-searchbar {
  ion-icon {
    --icon-color: var(--ion-color-primary);
    color: var(--ion-color-primary);
  }
}

.pwa-install-popup-ios-content {
  color: var(--ion-color-dark);
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

ion-skeleton-text {
  // margin-top: 0px;
  border-radius: 10px;
  min-height: 15px;

  &.no-radius {
    border-radius: 0px;
    margin-top: 0;
  }
}

.main-loading-indicator {
  animation: fadeIn 0.5s both;
  animation-delay: 0.3s;

  .loading-wrapper {
    border: 0;
    background: none;
    box-shadow: none;

    .loading-spinner {
      transform: scale(2);
    }
  }

  ion-backdrop {
    background-color: #fff !important;
  }
}

.md {
  .refresher-pulling-icon,
  .refresher-refreshing-icon {
    background: var(--ion-color-dark) !important;
    border: 0 !important;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
// .scrollbar {
//   overflow: overlay;
// }

// /* total width */
// .scrollbar::-webkit-scrollbar {
//   background-color: rgba(0, 0, 0, 0);
//   width: 16px;
//   height: 16px;
//   z-index: 999999;
// }

// /* background of the scrollbar except button or resizer */
// .scrollbar::-webkit-scrollbar-track {
//   background-color: rgba(0, 0, 0, 0);
// }

// /* scrollbar itself */
// .scrollbar::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0);
//   border-radius: 16px;
//   border: 0px solid #fff;
// }

// /* set button(top and bottom of the scrollbar) */
// .scrollbar::-webkit-scrollbar-button {
//   display: none;
// }

// /* scrollbar when element is hovered */
// .scrollbar:hover::-webkit-scrollbar-thumb {
//   background-color: #a0a0a5;
//   border: 4px solid #fff;
// }

// /* scrollbar when scrollbar is hovered */
// .scrollbar::-webkit-scrollbar-thumb:hover {
//   background-color: #a0a0a5;
//   border: 4px solid #f4f4f4;
// }

::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  background: transparent;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #666;
  border-radius: 5px;
}
