.page-connect {
  // .card-product {
  //   width: 100%;
  //   height: 100px;
  //   position : relative;

  //   ion-card-subtitle {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     text-align: center;
  //     width: 100px;
  //     margin-left: -50px;
  //     color: white;
  //   }

  //   img {
  //     height: 100px;
  //     width: 100%;
  //     object-fit: cover;
  //   }
  // }

  ion-item {
    border-bottom: 1px solid var(--ion-color-light);
    --border-color: var(--ion-color-light);
    div {
      display: flex;
      align-items: center;
      gap: 5px;
      &:not(:first-child) {
        padding-left: 39px;
      }
      ion-badge {
        display: flex;
        gap: 3px;
        min-width: 40px;
        ion-icon {
          font-size: 16px;
        }
      }
    }
    &.item-pending-invite {
      display: flex;
      gap: 5px;
      div {
        display: block;
      }
      ion-button {
        margin-left: 10px;
      }
    }
  }

  .out {
    animation: fadeOut 1s both;
    // --min-height: 0;
    overflow: visible;
    // * {
    //   transition: min-height 1s ease;
    // }
  }

  .card-partner {
    margin-bottom: 0px;
    margin-right: 0px;
    &.ios {
      width: calc(50% - 24px);
    }
    &.md {
      width: calc(50% - 15px);
    }
    display: inline-block;

    ion-card-content {
      position: relative;

      h2 {
        min-height: 60px;
        margin-right: 50px;
      }

      .partner-logo {
        height: 60px;
        object-fit: contain;
        object-position: center;
      }
      .partner-score {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 50px;

        .number {
          position: absolute;
          top: 15px;
          left: 20px;
          font-weight: bold;
          color: black;
        }
      }
    }
  }
}
