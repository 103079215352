.cdate-picker {
  padding: 15px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  max-width: 850px;
  margin: auto;
  // background-color: var(--background);
  background-color: white;
  animation: fadeInDown 0.3s both;
  // border-bottom: 1px solid var(--ion-color-light-medium);
  box-shadow: 0px 0px 20px rgba(35, 35, 39, 0.2);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  .rdrDateRangePickerWrapper {
    justify-content: center;
    width: 100%;
  }

  .date-picker-ok-button {
    justify-content: flex-end;
    display: flex;
  }

  .rdrStaticRangeLabel,
  .rdrInputRange span {
    color: #333;
  }

  // dash below today date
  .rdrDayToday .rdrDayNumber span:after {
    background: var(--ion-color-tertiary);
  }

  .rdrCalendarWrapper {
    font-size: 14px;
  }
  .rdrMonth {
    width: 22em;
  }

  .rdrInputRanges {
    input {
      background-color: white;
    }
  }
  .rdrDefinedRangesWrapper {
    width: 160px;
  }

  // .rdrStaticRanges, .rdrDefinedRangesWrapper, .rdrStaticRange, .rdrCalendarWrapper {
  //   background-color: var(--background);
  //   color: var(--ion-color-dark);
  //   border-color: var(--ion-color-light-medium);
  // }

  .rdrStaticRangeLabel {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media screen and (max-width: 500px) {
    padding: 5px;

    .rdrDateRangePickerWrapper {
      flex-direction: column;
    }
    .rdrDefinedRangesWrapper {
      width: 100%;
      border: 0;
    }
    .rdrWeekDay {
      font-size: 12px;
      text-align: center;
    }
    .rdrDayNumber {
      font-size: 14px;
    }
    .rdrMonth {
      padding: 0;
      padding-bottom: 15px;
      padding-right: 5px;
      width: 100%;
      & + .rdrMonth {
        padding-left: 5px;
        padding-right: 0;
      }
    }
    .rdrInputRanges {
      display: none;
    }
    .rdrStaticRanges {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      width: 100%;
      gap: 5px;
      padding-bottom: 10px;
      padding-top: 10px;
      button {
        border: 0;
        display: flex;
        align-items: center;
        span {
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 8px;
          text-align: center;
          min-width: 78px;
          text-transform: uppercase;
          border-radius: 5px;
          background-color: var(--ion-color-light);
        }
        &.rdrStaticRangeSelected {
          span {
            color: var(--ion-color-light);
            background-color: var(--ion-color-dark);
            font-weight: 900;
          }
        }
      }
    }
  }
}
