.view-question-tab {
  overflow: scroll;
  ion-list {
    gap: 10px;
  }

  .top-button {
    position: sticky;
    top: 0px;
    z-index: 5;
    padding: 15px 0;
    background-color: var(--background);
    margin-top: -15px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: right;
    border-bottom: 1px solid var(--ion-color-light-medium);
  }

  .question-item {
    background-color: var(--ion-item-background);
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 8px;
    align-items: flex-start;
    &:hover {
      cursor: pointer;
      background-color: var(--ion-color-tertiary);
      color: white;
      ion-button {
        background-color: var(--ion-color-white);
        border-radius: 4px;
      }
      span {
        color: white;
      }
    }
    ion-button {
      margin: 15px 10px;
      margin-left: auto;
    }
    span {
      font-size: 14px;
      color: var(--ion-color-medium);
    }
  }
}

.small-modal {
  .react-select {
    width: 100%;
    margin-bottom: 10px;
  }
}
