.component-products-admin {
  max-width: 1200px;
  position: relative;
  .btn-invite {
    bottom: 15px;
    right: 15px;
    position: fixed;
    z-index: 5;
  }

  .top {
    width: 100%;
    background-color: var(--ion-color-light);
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 5px 10px;
    & > div {
      flex: 1;
    }
    strong {
      margin-left: auto;
      padding-right: 15px;
    }
  }
  .item-selectable {
    .item-selectable_variety {
      display: flex;
      column-gap: 5px;
      align-items: center;
    }
    .desc {
      padding-left: 20px;
      //padding-top: 5px;
      margin-bottom: 10px;
      color: var(--ion-color-medium);
    }
  }
}

.warningMessageNewProduct {
  color: var(--ion-color-danger2);
  font-size: 0.8rem;
}
