.multiple-selection.enabled {
  // the outline is the old way but doesnt work well in safari
  // with border-radius

  // outline: 3px solid var(--ion-color-tertiary)!important;
  &:after {
    content: '';
    position: absolute;
    top: -4px; // border: 3px + offset: 1px
    right: -4px; // border: 3px + offset: 1px
    bottom: -4px; // border: 3px + offset: 1px
    left: -4px; // border: 3px + offset: 1px
    border: 3px solid var(--ion-color-tertiary);
    border-radius: 14px; // border—radius: 6px + offset: 1px
    animation: fadeIn 0.3s both;
  }

  ion-card,
  .ion-card {
    border: 0 !important;
  }
}
.multiple-selection {
  pointer-events: none;
  position: relative;
}
.multiple-selection.disabled {
  ion-card,
  .ion-card {
    opacity: 50%;
  }
}

.card-lot {
  &.skeleton {
    .view-assessment-score {
      filter: grayscale(100%);
      opacity: 0.5;
      .score .numerical {
        color: transparent !important;
      }
    }
  }
}

.non-clickable {
  pointer-events: none;
}

ion-card.card-lot {
  // --ion-background-color: red;
  .lot-insight {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    // color: black;
    border-radius: 0;
  }

  .emptyPicture {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    &:before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .cover.avocado .emptyPicture,
  .cover.avocados .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/avocados.jpg');
  }
  .cover.lime .emptyPicture,
  .cover.limes .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/limes.jpg');
  }
  .cover.fig .emptyPicture,
  .cover.figs .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/figs.jpg');
  }
  .cover.carambola .emptyPicture,
  .cover.carambolas .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/carambola.jpg');
  }
  .cover.sugar-snap .emptyPicture,
  .cover.sugar-snaps .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/sugarsnaps.jpg');
  }
  .cover.corn .emptyPicture,
  .cover.corns .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/corn.jpg');
  }
  .cover.papaya .emptyPicture,
  .cover.papayas .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/papaya.jpg');
  }
  .cover.pomegranate .emptyPicture,
  .cover.pomegranates .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/pomegranate.jpg');
  }
  .cover.bean .emptyPicture,
  .cover.beans .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/beans.jpg');
  }
  .cover.spearmint .emptyPicture,
  .cover.spearmints .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/spearmint.jpg');
  }
  .cover.rosemary .emptyPicture,
  .cover.rosemarys .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/rosemary.jpg');
  }
  .cover.mango .emptyPicture,
  .cover.mangos .emptyPicture,
  .cover.mangoes .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/mangoes.jpg');
  }

  .cover.red-currants .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/red-currants.jpg');
  }
  .cover.green-asparagus .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/green-asparagus.jpg');
  }
  .cover.snowpeas .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/snowpeas.jpg');
  }
  .cover.raspberries .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/raspberries.jpg');
  }
  .cover.blueberries .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/blueberries.jpg');
  }
  .cover.strawberries .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/strawberries.jpg');
  }
  .cover.blackberries .emptyPicture {
    background-image: url('./assets/img/fruit-backgrounds/blackberries.jpg');
  }

  ion-icon {
    float: left;
    margin-right: 10px;
    margin-top: 3px;
    font-size: 40px;
    margin-bottom: 25px;
  }

  .cover {
    height: 100px;
    // background: lightgray;
    position: relative;
    display: flex;
    align-items: stretch;

    .cover-pictures {
      height: 100px;
    }

    img {
      // height: 100%;
      // width: 100%;
      object-fit: cover;
      margin-right: 3px;
      flex: 1;
      // max-width: unset;
      // width: unset;
      width: 33%;

      &:last-child {
        margin-right: 0;
      }
    }

    .cover-icon {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 60px;
      height: 60px;
      font-size: 40px;
      padding: 10px;
      display: inline-block;
      border-radius: 100px;
      // background: #FFFFFF;
      box-shadow: 0px 2.26804px 2.26804px rgba(0, 0, 0, 0.13);
    }
  }

  ion-item {
    --padding-start: 0;
  }

  ion-button {
    margin-top: 20px;
  }
}

.dark ion-card.card-lot {
  ion-icon {
    color: rgba(100, 100, 100, 0.5);
  }
}
