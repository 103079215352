.c-inspection-header {
  .ion-item {
    // padding: 5px;
    padding-top: 10px;
    background-color: var(--ion-item-background);
  }
  .ion-label {
    max-width: 150px;
    margin-left: 16px;
    font-weight: bold;
    font-size: 14px;
  }
  ion-select {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    &[disabled='true'] {
      opacity: 0.8;
    }
  }
  ion-item-divider {
    z-index: 0;
  }
  .name-wrapper {
    // padding-left: 9px;
  }
}

.alert-button.sc-ion-alert-ios {
  color: var(--ion-color-danger);
  font-weight: bold;
}
.alert-button.sc-ion-alert-ios:last-child {
  color: var(--ion-color-dark);
}
