.wizard-navigation-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 15px 0;
  width: 100%;
  bottom: 0;
  // gap: 3px;
  background-color: var(--ion-color-light);
  .navigation-button {
    display: block;
    float: right;
  }
  ion-icon.issue-icon {
    // font-size: 1.3em;
    // position: absolute;
    // margin-left: -16px;
    // margin-top: -3px;
  }
  ion-button::part(native) {
    --border-width: 2px;
    --border-style: solid;
    --border-color: transparent;
  }
}
