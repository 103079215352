.page-management {
  .main-container {
    display: flex;
    height: 100%;
    > ion-list {
      // width: 220px;
      position: sticky;
      top: 0;
      z-index: 2;
      height: 344px;
      .selected {
        --background: var(--ion-color-light-medium);
      }
      border-right: 1px solid var(--ion-color-light-medium);
    }
    > div {
      flex: 1;
      padding-top: 8px;
      // padding-left: 15px;
    }
  }

  // --------------------------------------------------
  // more and view-more
  // --------------------------------------------------
  .more {
    display: none !important;
    &.visible {
      display: flex !important;
    }
    user-select: none;

    .info-button {
      display: inline-block;
    }

    .checkbox-container {
      display: inline-block;
    }
  }

  .grid-list {
    display: none !important;
    &.visible {
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }
    }
    user-select: none;

    .info-button {
      display: inline-block;
    }

    .checkbox-container {
      display: inline-block;
    }
  }

  .view-more {
    padding-top: 0 !important;
    display: inline-block !important;
    cursor: pointer;
    color: var(--ion-color-tertiary);
    font-weight: bold;
    // text-decoration: underline;
    &:after {
      content: ' ▸';
    }
    &.less:after {
      content: ' ▾';
    }
  }
  // --------------------------------------------------
  // common with pages
  // --------------------------------------------------
  .paging {
    display: flex;
    gap: 5px;
    padding: 10px 0;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 95%;
    margin: auto;
    div {
      padding: 5px 10px;
      border: 1px solid var(--ion-color-light-medium);
      border-radius: 3px;
      font-weight: bold;
      &:hover {
        background-color: var(--ion-color-light-medium);
        cursor: pointer;
      }
      &.current {
        background-color: var(--ion-color-primary);
        border: 1px solid var(--ion-color-primary);
      }
    }
  }

  .top-toolbar {
    width: 100%;
    background-color: var(--ion-color-light);
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 5px 10px;
    & > div {
      flex: 1;
    }
    strong {
      margin-left: auto;
      padding-right: 15px;
    }
  }

  .action-button {
    cursor: pointer;
    // border: 1px solid var(--ion-color-light-medium);
    border-radius: 4px;
    padding: 5px;
    display: flex;
    &:hover {
      // border: 1px solid var(--ion-color-tertiary);
      background-color: var(--ion-color-tertiary);
      ion-icon {
        color: white;
      }
    }
    &.delete:hover {
      background-color: var(--ion-color-danger2);
    }
    ion-icon {
      pointer-events: none;
      color: var(--ion-color-medium);
    }
  }

  .main-button {
    bottom: 15px;
    right: 15px;
    position: fixed;
    z-index: 5;
  }

  .add-packaging-button {
    margin-left: 10px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .item-selectable {
    min-width: 120px;
    // max-width: 160px;
  }

  #item-selectable-packaging {
    display: inline-block;
  }
  #remove-invite-icon {
    display: inline-block;
    margin-left: 5px;
  }

  .no-results {
    text-align: center;
    padding: 15px;
    color: var(--ion-color-medium);
    text-transform: uppercase;
  }

  .table-list {
    padding: 15px;
    // min-width: 1100px;
    // display: inline-block;
    overflow-x: scroll;
    &.align-top {
      & > div,
      & > div > div {
        align-items: flex-start !important;
      }
    }
    .list-header {
      font-weight: bold;
      border-bottom: 1px solid var(--ion-color-light);
      margin-bottom: 10px;
      padding-bottom: 5px;
      // position: sticky;
    }

    & > div {
      &:nth-child(even) {
        background-color: var(--ion-color-light);
      }
    }

    &:not(.double-row) > div,
    &.double-row > div > div {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 5px;
      padding: 10px;

      .name {
        div + div {
          font-size: 14px;
          color: var(--ion-color-medium);
        }

        .partners-input {
          margin-top: 0;
        }
        .ag-id {
          font-size: 12px;
          text-transform: uppercase;
          padding: 4px;
          margin-top: 5px;
          border-radius: 4px;
          background-color: var(--ion-color-dark);
          color: var(--ion-color-light);
          display: inline-block;
          font-weight: bold;
        }
        .id-value {
          padding: 10px;
          margin-top: 0px;
          border-radius: 4px;
          background-color: #cccccc;
          border: 1px solid #b0b0b0;
          padding: 7.5px;
        }
        .gps-coords {
          cursor: pointer;
          padding: 10px;
          margin-top: 0px;
          border-radius: 4px;
          background-color: #ffffff;
          border: 1px solid #b0b0b0;
          padding: 7.5px;
        }

        ion-input, ion-datetime {          
          background-color: white;
          border: 1px solid #b0b0b0;
          border-radius: 4px;
          input {
            padding: 7.5px;
          }
        }
        ion-input {
          margin-top: 10px;
        }
      }
      .selectables {
        b {
          margin-bottom: 10px;
          margin-left: 2px;
          display: block;
        }
        & > div {
          margin-bottom: 10px;
        }
      }
      .actions {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        max-width: 80px;
        gap: 10px;
      }
      .admin-checkbox {
        text-align: center;
        vertical-align: middle;
        max-width: 80px;
        gap: 10px;
      }
      > div {
        // max-width: 200px;
        flex: 1;
      }
    }

    &.double-row .second-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
}

// modal
// common with pages
.add-modal {
  .main-btn {
    padding: 15px 20px 15px 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    ion-button {
      width: 100%;
    }
  }

  .gps-coords {
    cursor: pointer;
    padding: 10px;
    margin-top: 0px;
    border-radius: 4px;
    color: var(--ion-color-medium);
    background-color: #ffffff;
    border: 1px solid #b0b0b0;
    padding: 7.5px;
    width: 250px;
  }

  .react-select__control {
    max-width: 250px;
    margin-left: auto;
  }

  ion-list {
    padding-bottom: 400px;
    ion-label {
      width: 120px;
      white-space: wrap;
    }
    ion-input, ion-datetime {
      // border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid var(--ion-color-medium);
      max-width: 250px;
      text-align: right;
      margin-left: auto;
    }
    ion-datetime {
      padding-right: 16px;
    }
    
    .select-input {
      display: flex;
      overflow: visible;
      ion-label {
        width: 300px;
        max-width: 300px;
      }
      z-index: 3;
      & > div {
        flex: 1;
      }
    }
  }
}

// --------------------------------------------------
// alert-invite-partner modal
// --------------------------------------------------
.alert-invite-partner {
  .alert-wrapper {
    max-width: 500px !important;
  }
}

// --------------------------------------------------
// dark mode
// --------------------------------------------------
.dark {
  .page-management {
    .item-selectable {
      > div > div {
        background-color: hsl(0, 0%, 6%);
        color: var(--ion-color-tertiary);
        input {
          color: white !important;
        }
      }
    }
  }
}
