.location-inspections {
  .field-inspections-list .field-inspection-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .field-inspections-list .ion-card {
    z-index: 10;
    margin: 10px 10px 30px 10px;
  }

  ion-list {
    margin-bottom: 100px;
    overflow: visible;

    & > div {
      padding-bottom: 20px;
    }

    @media (min-width: 575px) {
      margin-top: 20px;
      border-left: 3px solid var(--ion-color-tertiary);
      margin-left: 30px;
      padding-bottom: 0;
      padding-top: 0;

      &:after {
        content: ' ';
        width: 10px;
        height: 10px;
        display: block;
        background-color: var(--ion-color-tertiary);
      }
    }

    ion-item-divider {
      font-weight: bold;
      text-transform: uppercase;
      position: sticky;
      top: 86px;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: var(--ion-color-light);
      b {
        display: inline-block;
        margin-left: 10px;
      }
      @media (min-width: 575px) {
        padding-left: 0;
        border: 0;

        span {
          background-color: var(--ion-color-tertiary);
          color: white;
          padding: 5px;
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;
        }
      }
      span {
        margin-right: 10px;
      }
    }
    ion-item.item {
      ion-label {
        white-space: normal;
      }
    }
    .order-container {
      display: flex;
      overflow: auto;
      padding-bottom: 13px;
      z-index: 10;
      align-items: flex-start;
      > ion-card {
        z-index: 10;
      }
    }
  }
}
