.card-images {
  height: 200px;
  // background: lightgray;
  position: relative;

  display: grid;
  gap: 1px;
  align-items: stretch;
  grid-auto-flow: column dense;
  grid-auto-rows: auto;
  grid-template-rows: 99px 99px;
  justify-content: start;

  &.one-row {
    display: flex;
    height: 100px;
  }

  gap: 1px;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  // background-color: black;
  &:hover {
    cursor: pointer;
  }
  &.emptyPicture {
    &:hover {
      cursor: inherit;
    }
  }

  &.emptyPicture {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    user-select: none;
    pointer-events: none;

    &:after {
      content: 'no pictures';
      padding: 10px 15px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .cover-pictures {
    height: 100px;
  }

  > * {
    &.loaded {
      // animation: fadeIn .3s both;
      // @for $j from 2 to 5 {
      //   &:nth-child(#{$j}) { animation-delay: #{($j - 1) * 100}ms; }
      // }
    }
    object-fit: cover;
    flex: 1;
    width: 100px;
    height: 100px;
    min-width: 100px;
    flex: none;

    &.selected {
      border: 4px solid var(--ion-color-tertiary);
    }
  }
}
