.c-granularity {
  display: flex;
  ion-label {
    width: 60px;
    display: flex;
    justify-content: center;
    // flex-direction: column;
    gap: 2px;
    span + span {
      font-weight: bold;
      // font-family: monospace;
    }
  }
  ion-button {
    position: relative;
    cursor: pointer;
    ion-icon {
      color: var(--ion-color-dark) !important;
    }
    &:active,
    &:focus,
    &:hover {
      &:after {
        content: '';
        position: absolute;
        top: -2px; // border: 3px + offset: 1px
        right: -2px; // border: 3px + offset: 1px
        bottom: -2px; // border: 3px + offset: 1px
        left: -2px; // border: 3px + offset: 1px
        border: 2px solid var(--ion-color-tertiary);
        border-radius: 12px; // border—radius: 6px + offset: 1px
      }
    }
  }
}
