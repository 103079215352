.congratulations-panel {
  &--wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px 20px;
    height: 100%;
    text-align: center;
    width: 100%;

    @media (min-width: 991px) {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
      max-width: 600px;
      margin: auto;
    }
  }

  &--header {
    padding-top: 20px;
    width: 100%;

    h1 {
      font-size: 40px;
    }

    img {
      width: 100px;
    }
  }

  &--content {
    padding: 10px 20px;
    width: 100%;
  }

  &--footer {
    padding: 20px;
    width: 100%;
  }

  &--button {
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 991px) {
      width: 300px;
    }
  }
}
