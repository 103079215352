.view-article-description {
  .article-name {
    font-size: 16px;
    font-weight: bold;
    color: var(--ion-color-dark);
    margin-bottom: 5px;
    display: flex;
    gap: 4px;
  }
  .article-badges {
    display: flex;
    gap: 4px;
    line-height: 0;
    flex-wrap: wrap;
    ion-badge {
      max-width: 150px;
      // min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
