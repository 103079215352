.page-order {
  .main-wrapper {
    max-width: 1010px;
    // position: sticky;
  }

  .report-feedback {
    white-space: break-spaces;
    border: 1px solid var(--ion-color-light-medium);
    border-radius: 4px;
    padding: 10px;
    margin: 10px;

    div {
      text-align: right;
      font-size: 14px;
      color: var(--ion-color-medium);
    }
  }

  ion-content ion-list {
    // padding-bottom: 100px;
  }

  .non-clickable {
    pointer-events: none;
  }

  .clickable {
    pointer-events: all !important;
  }

  .container-attachments {
    ion-card {
      padding-top: 10px;
      padding-bottom: 10px;

      ion-item {
        padding-inline-end: 0;
        --background: transparent;

        ion-icon {
          margin-right: 15px;
          min-width: 24px;
        }
      }
    }
  }

  .lock-icon {
    margin-right: 10px;
    // margin-left: 10px;
  }

  .checkbox {
    // margin-left: 15px;
    // margin-right: 5px;
    animation: fadeInLeft 0.3s ease;
  }

  // .report-button {
  //   border: 1px solid var(--ion-color-light-medium)!important;
  //   border-radius: 10px;
  //   margin: 10px;
  //   &::part(native) {
  //     padding-top: 10px; padding-bottom: 10px;
  //   }
  //   @media screen and (max-width: 600px) {
  //     margin: 0 15px 5px;
  //     border: 2px solid var(--ion-color-light-medium)!important;
  //   }
  // }

  .loading {
    display: flex;
    justify-content: center;
    animation: fadeInUp 0.3s both;
    animation-delay: 0.5s;
    margin-top: 35px;

    ion-spinner {
      transform: scale(2);
    }
  }

  .shared-count {
    color: var(--ion-color-dark);
    text-align: center;
    margin-bottom: 10px;
    animation: fadeInDown 0.3s both;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    span {
      background: var(--ion-color-light);
      padding: 2px 6px;
      border-radius: 4px;
    }
  }

  .lot-list > div {
    display: flex;
    flex-direction: column;

    ion-list-header {
      order: -2;
    }

    .go-to-top {
      order: -1;
      border-left: 6px solid var(--ion-color-danger);

      &::part(native) {
        padding-left: 10px;
      }
    }

    ion-item.disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    ion-item.target {
      --background: var(--ion-color-light-medium);
    }
  }

  .copy-assessments {
    .lot-list > div .go-to-top {
      border-left: 0;
    }

    .lot-list > div ion-item::part(native) {
      padding-left: 10px;
    }
  }

  .general-info-wrapper {
    margin-bottom: 10px;
    max-width: 700px;

    ion-item {
      --min-height: 35px !important;

      ion-label:first-child {
        font-weight: bold;
      }
    }

    &.no-events {
      pointer-events: none;
      cursor: text !important;
    }

    .order-id-div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
  }

  .report-list {
    max-height: 460px;
    max-width: 350px;

    @media (max-width: 575px) {
      max-width: inherit;
    }

    overflow: auto;

    ion-list-header {
      position: sticky;
      top: 0;
      z-index: 9;
      display: flex;
      align-items: center;
    }

    ion-item {
      // border-bottom: 1px solid var(--ion-color-light-medium);
      --padding-bottom: 10px !important;
      --padding-top: 10px !important;
      margin-bottom: 10px;
      margin-right: 10px;
      /*       border-left: 4px solid var(--ion-color-light-medium); */

      ion-icon {
        margin-right: 20px;
      }

      &:last-child {
        border-bottom: none;
      }

      .id {
        font-weight: bold;
      }

      .when,
      .message {
        font-size: 13px;
      }

      .when {
        ion-badge {
          font-size: 13px;
        }

        display: flex;
        gap: 5px;
      }

      .shared {
        font-weight: bold;
      }

      .by,
      .shared-to,
      .shared {
        font-size: 13px;
      }

      .message {
        border-left: 3px solid var(--ion-color-light-medium);
        max-width: 400px;
        // border-radius: 4px;
        // font-style: italic;
        padding: 3px 0 3px 5px;
        margin-top: 10px;
        white-space: break-spaces;

        &:empty {
          display: none;
        }
      }
    }
  }

  .order-summary {
    padding-bottom: 30px;

    > ion-item {
      overflow: auto;
      background: transparent;
      --background: transparent;
    }
  }

  .copy-assessment-banner {
    background-color: var(--ion-color-tertiary);
    animation: fadeInDown 0.3s both;
    color: white;
    padding: 15px 15px 15px 15px;
    position: sticky;
    top: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 24px;

    &:not(.first) {
      @media screen and (max-width: 500px) {
        display: block;
      }
    }

    .buttons {
      margin-left: auto;
      display: flex;
      gap: 15px;
      opacity: 0.9;

      @media screen and (max-width: 500px) {
        justify-content: flex-end;
      }
    }
  }

  &.upfront-page {
    ion-content {
      border: 5px solid var(--ion-color-tertiary);

      > ion-list {
        // box-shadow: inset 0px 0px 0 5px var(--ion-color-tertiary);
      }

      .report-general-overview {
        // padding: 0 5px;
      }
    }

    .shared-order-banner {
      background-color: var(--ion-color-tertiary);
      color: white;
      text-align: center;
      padding: 10px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      // text-transform: uppercase; // Emi: changed this because sharon didn't like it
      font-weight: bold;
      border-bottom-left-radius: 10px;
      opacity: 0.9;

      @media screen and (max-width: 768px) {
        position: sticky;
        border-radius: 0px;
        padding: 0 0 5px 0;
        opacity: 1;
        top: -1px;
      }
    }

    ion-card {
      .cover {
        // disable badges with approval workflow
        // on upfront/shared reports
        ion-badge {
          pointer-events: none;

          ion-icon {
            display: none;
          }
        }
      }
    }
  }

  // ion-title.ios {
  //   font-size: 16px;
  //   padding-left: 60px;
  //   padding-right: 60px;
  // }

  .upfront.insight {
    border-radius: 0;
    margin-top: 0px;
    padding: 0;
    width: 100%;
  }

  .organization-name {
    text-transform: capitalize;
  }

  ion-datetime {
    padding-right: 0;
  }

  .list-wrapper {
    // animation: fadeIn .1s both;

    padding-bottom: 75px;

    .container-reports .card-inspection {
      animation: fadeInUp 0.3s both;

      @for $j from 0 to 24 {
        &:nth-child(#{$j}) {
          animation-delay: #{$j * 70+000}ms;
        }
      }
    }
  }

  ion-label:nth-child(2) {
    // min-width: 200px;
    flex: 2;
  }

  .external-id {
    &.animated {
      animation: fadeInDown 0.3s;
    }

    text-align: right;
    display: flex;
    align-items: center;
    padding-inline-end: 0 !important;

    ion-label,
    ion-badge {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    ion-label:nth-child(2) {
      justify-content: flex-end;
      gap: 15px;

      // min-width: 230px;
      div {
        // text-decoration: underline;
        // border-bottom: 1px solid var(--ion-color-tertiary);
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: bold;
        color: var(--ion-color-tertiary);
        cursor: pointer;
      }
    }

    ion-icon {
      font-size: 18px;
    }
  }

  .button-container {
    bottom: 0;
    position: fixed;
    margin: 15px;
    right: 0;
    z-index: 2;

    > * {
      animation: fadeInRight 0.3s both;
    }

    // ion-button.translucent {
    //   opacity: .5!important;
    // }

    ion-button::part(native) .button-inner {
      justify-content: flex-start !important;
    }
  }

  .text-wrap {
    white-space: normal;
  }

  .lot-options-button {
    --width: 20px;
    --height: 20px;
    // --border-radius: 50%;
    --vertical-align: middle;
    --padding-start: 8px;
    --padding-end: 8px;
    margin-left: -10px !important;
    margin-right: 5px !important;
  }

  // .lot-options-button.no-lock {
  //   margin-left: 0px!important;
  // }

  // ion-item.display-lot-options
  // // ,ion-item.transport-item
  // {
  //   --padding-start: 0px;
  // }

  .align-right {
    text-align: right;
  }

  .upfront.insight {
    --min-height: 40px;

    ion-icon {
      font-size: 18px;
      margin-right: 5px;
    }

    ion-label {
      color: black;
    }
  }

  .link-to-order {
    --min-height: 20px;
    display: flex;
    justify-content: flex-end;
  }

  #agrinormInputId + ion-badge {
    position: absolute;
    right: 10px;
    // pointer-events: none;
  }

  #agrinormInputId:focus + ion-badge {
    display: none;
  }

  .hidden {
    display: none;
  }

  .lot-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 0;

    .first-row {
      display: flex;
      align-items: center;

      .lot-info-score {
        display: flex;
        // flex-direction: column;
        align-items: center;
        column-gap: 5px;

        .lot-info {
          font-weight: bold;
        }
      }

      .col-scoring {
        margin-left: auto;

        ion-badge,
        .numerical {
          font-size: 14px;
        }
      }
    }
  }

  .separator {
    background-color: var(--ion-color-light);

    &.child-batches {
      margin-bottom: 0;

      ion-icon {
        margin-right: 10px;
      }

      h2 {
        font-weight: bold;
      }
    }

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 22px;
    }
  }

  ion-badge {
    font-size: 13px;

    // @media (max-width: 575px) {
    //   font-size: 10px;
    // }
    &.score-4 {
      background-color: var(--ion-color-primary);
      color: #000;
    }

    &.score-2 {
      background-color: var(--ion-color-danger2);
    }

    &.score-undefined {
      background-color: var(--ion-color-medium);
    }
  }

  .lot-list {
    margin-top: 10px;

    ion-item {
      border-bottom: 1px solid var(--ion-color-light);
      padding-right: 0;
      // --padding-start: 0;
      overflow: visible;

      ion-grid {
        padding: 10px 0 10px 0;

        // &.open {
        //   .col-scoring {
        //     // display: none;
        //   }
        // }
      }
    }

    .assesment-badges {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      ion-badge {
        display: flex;
        gap: 2px;
        align-items: center;
        height: 20px;

        ion-icon {
          font-size: 16px;
        }
      }
    }

    .lot-info {
      white-space: nowrap;
    }

    .linked-lot {
      // display: flex;
      // align-items: center;
      vertical-align: auto;
      // gap: 4px;
      font-size: 0.85em;
      line-height: 15px;
      margin: 5px 0;

      ion-icon {
        font-size: 18px;
        margin-right: 5px;
      }

      ion-badge {
        margin: 0;
      }
    }
  }

  .wrapper-list {
    display: flex;

    // gap: 10px;
    .quantity {
      // padding: 5px 5px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      line-height: 16px;
      border: 2px solid var(--ion-color-medium);
      background: none;
      color: var(--ion-color-medium);
    }
  }

  .assesment-info {
    display: flex;
    align-items: center;
    align-content: center;
    // margin-bottom: 5px;
    gap: 4px;

    .lot-info {
      margin-right: 5px;
    }

    ion-badge {
      margin-top: 0;
      display: flex;
      gap: 2px;
      justify-content: center;
      align-items: center;

      ion-icon {
        font-size: 12px;
      }
    }
  }

  ion-list-header {
    h1 {
      margin-top: 10px;
      font-size: 22px;
      font-weight: bold;
    }

    user-select: none;
    background-color: var(--ion-color-light);
    border-bottom: 1px solid var(--ion-color-light-medium);
    // border-top: 1px solid var(--ion-color-light-medium);
    margin-bottom: 10px;
  }

  ion-input {
    text-align: right;
  }

  .container-reports {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    align-items: flex-start;
    gap: 15px;
    padding: 5px 15px;
    padding-bottom: 30px;

    ion-card {
      margin: 0;
    }
  }

  .report-general-overview {
    display: flex;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    // cursor: pointer;
    overflow: auto;
    // user-select: none;
    background: var(--ion-item-background);

    .general {
      min-width: 400px;
      max-width: 700px;
      flex: 5;

      @media (max-width: 575px) {
        min-width: 100%;
      }

      ion-item {
        @media (max-width: 575px) {
          // min-width: 60px;
          --min-height: 35px;
        }

        ion-label:first-child {
          min-width: 160px;
          white-space: normal;
          text-align: left;
        }
      }
    }

    .report-list,
    .defects {
      flex: 3;

      &:empty {
        display: none;
      }
    }

    ion-item {
      padding: 0;

      // height: 40px;
      ion-label {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      &::part(native) {
        white-space: nowrap;
      }
    }

    .defects,
    .remarks,
    .others {
      // margin-left: 10px;
      // user-select: none;
      flex: 4;

      // max-width: 300px;
      // ion-list-header {
      //   padding-left: 0;
      // }
      div {
        margin-bottom: 5px;
        display: inline-block;
        margin-right: 5px;
        border: 1px solid var(--ion-color-light-medium);
        padding: 4px 6px;
        border-radius: 3px;
        font-size: 14px;
        white-space: nowrap;
        color: var(--ion-color-dark);
        cursor: pointer;

        &.selected-filter {
          background: var(--ion-color-dark);
          border-color: var(--ion-color-dark);
          color: var(--ion-color-light);
          font-weight: bold;
        }
      }
    }

    @media (max-width: 575px) {
      display: block;

      .defects,
      .remarks,
      .others {
        display: none;
      }

      .general {
        max-width: 100%;
        display: block;

        ion-label {
          font-size: 16px;
        }
      }
    }
  }
}

.page-report {
  .main-wrapper {
    max-width: none;
  }

  &.hide-defects {
    .defects {
      display: none;
    }
  }

  &.hide-scores {
    .view-assessment-score,
    .batch-summary-score {
      display: none;
    }
  }

  &.hide-inspector {
    .ion-card .user {
      display: none;
    }
  }

  &.hide-quantity {
    .ion-card .quantity {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    .main-wrapper {
      display: flex;
      height: 100%;
      padding-bottom: 0;

      .report-general-overview {
        flex-direction: column;
        min-width: 400px;
        max-width: 570px;
        overflow: auto;
        height: 100%;

        // padding-bottom: 100px;
        > * {
          flex: 0 0 !important;
          overflow: revert;
          max-width: inherit;
          width: 100%;
        }

        .defects-wrapper {
          padding: 0 15px;
          border: 0;
          display: flex;
          flex-wrap: wrap;
        }

        // ion-list-header {
        //   position: sticky;
        //   top: 0;
        //   z-index: 5;
        // }
      }

      .quality-reports {
        overflow: auto;
        width: 100%;
        // ion-list-header {
        //   position: sticky;
        //   top: 0;
        //   z-index: 5;
        // }
      }
    }
  }
}

.alert-delete {
  .accept-button-alert {
    color: #ffffff;
    background-color: #fa937a;
  }
}

.modal-share {
  // .modal-wrapper {
  //   transform: none;
  //   animation: fadeInRight .3s ease-in;
  // }
  @media (min-width: 576px) {
    justify-content: flex-start;
    --height: 100%;
    --width: 630px;

    .ion-page,
    .modal-wrapper {
      border-radius: 0 !important;
    }
  }
}
