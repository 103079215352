@mixin tablet-and-up {
  @media screen and (min-width: 769px) {
    @content;
  }
}

@mixin mobile-and-up {
  @media screen and (min-width: 601px) {
    @content;
  }
}

@mixin tablet-and-down {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: 600px) {
    @content;
  }
}

.page-timeline {
  ul,
  li {
    list-style: none;
    padding: 0;
  }

  .wrapper {
    display: flex;
    width: 100%;
    padding: 0 0 0 16px;
    .sessions {
      flex: 1;
    }
    .page-filters {
      min-width: 375px;
      height: 90vh;
      // animation: fadeInRight .3s ease-in;
      top: 0;
      position: sticky;
      display: block;
      @media screen and (max-width: 1090px) {
        display: none;
      }
      ion-content > ion-list {
        padding-top: 0;
      }
    }
  }

  .card-images {
    gap: 5px;
    flex-wrap: wrap;
    height: 133px;
    img {
      border-radius: 10px;
    }
  }

  .sessions {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 15px;
    border-right: 1px solid var(--ion-color-light-medium);
    position: relative;
    @media screen and (max-width: 1090px) {
      border-right: none;
    }
  }

  li {
    padding-bottom: 1.5rem;
    border-left: 2px solid var(--ion-color-light-medium);
    position: relative;
    padding-left: 20px;
    margin-left: 10px;

    &:last-child {
      border: 2px solid transparent;
      padding-bottom: 0;
    }

    .view-assessment-score {
      min-width: 60px;
      margin-left: auto;
    }

    .icon {
      animation: fadeInLeft 0.3s ease-in-out;
      width: 16px;
      height: 16px;
      background: var(--ion-color-background) !important;
      border: 1px solid var(--ion-color-medium) !important;
      box-shadow: 2px 2px 0px var(--ion-color-light-medium);
      border-radius: 50%;
      position: absolute;
      left: -9px;
      top: 0px;

      &.success {
        background: var(--ion-color-primary);
        border: 1px solid var(--ion-color-primary);
      }

      &.danger {
        background: var(--ion-color-danger);
        border: 1px solid var(--ion-color-danger);
      }

      &.danger2 {
        background: var(--ion-color-danger2);
        border: 1px solid var(--ion-color-danger2);
      }

      &.warning {
        background: var(--ion-color-secondary);
        border: 1px solid var(--ion-color-secondary);
      }

      &.tertiary {
        background: var(--ion-color-tertiary);
        border: 1px solid var(--ion-color-tertiary);
      }
    }
  }

  .time {
    color: var(--ion-color-dark);
    font-weight: 500;
    display: flex;
    gap: 5px;

    @include mobile-and-up {
      font-size: 0.9rem;
    }

    @include mobile-only {
      margin-bottom: 0.3rem;
      font-size: 0.85rem;
    }
  }

  p {
    color: var(--ion-color-medium);
    font-family: sans-serif;
    line-height: 1.5;
    margin-top: 0.4rem;
    display: flex;
    gap: 10px;
    align-items: center;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 6px;
    }

    b {
      border-bottom: 1px solid var(--ion-color-medium);
      cursor: pointer;
    }

    @include mobile-only {
      font-size: 0.9rem;
    }
  }
}
