.change-password-form {
  &--wrapper {
    width: 100%;
  }

  &--content {
    margin-bottom: 20px;
  }

  &--input-container {
    --background: transparent;
    border-bottom: 1px solid var(--ion-color-light-medium);
  }

  &--input {
    background-color: transparent;
  }

  &--button {
    width: 100%;
    text-transform: uppercase;

    @media (min-width: 991px) {
      max-width: 300px;
    }
  }

  &--error {
    p {
      color: #cc0000;
    }
  }
}
