.c-float-list {
  .react-select {
    min-width: 240px;
    .react-select__control {
      border-radius: 8px;
      background-color: var(--ion-color-light);
      border: 0;
      padding: 5px 0;
    }
  }
  .input {
    background: var(--ion-color-light);
    border-radius: 8px;
    border: 0;
    text-align: right;
    padding: 10px 10px 10px 5px;
    position: relative;
    outline: none;
    min-width: 250px;
  }
}

.dark {
  .react-select__input input {
    color: var(--ion-color-dark) !important;
  }
}
