.page-new-conversation {
  // ion-list {
  //   --ion-background-color: white;
  //   background: white;
  // }

  .input-label {
    margin-bottom: 10px;
  }

  ion-label span {
    font-size: 14px;
    color: var(--ion-color-medium);
    text-transform: lowercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ion-avatar {
    width: 48px;
    height: 48px;
  }

  ion-list-header {
    text-transform: capitalize;
  }

  ion-item {
    --border-color: var(--ion-color-light);
    &:last-child {
      --border-color: transparent;
    }
  }

  .contact-divider {
    // text-transform: uppercase;
    font-weight: bold;
    text-overflow: ellipsis;
    font-size: 18px;
    padding: 5px 15px 5px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    position: relative;
  }

  .render-user {
    box-shadow: none;
    background-color: var(--ion-color-light);
    // padding: 4px;
    .initials {
      margin: 4px;
    }
  }
}
