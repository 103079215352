.render-picture {
  width: 100%;
}

.img-being-uploaded {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  // border-right: 1px solid var(--ion-color-light);
  // background-color: var(--ion-color-light-medium);
  text-transform: uppercase;
  font-weight: bold;
  color: var(--ion-color-medium);
  height: 100%;
  // animation: fadeInUp .3s both;
  ion-icon {
    font-size: 32px;
  }
  > div {
    font-size: 12px;
    text-align: center;
  }
}

.dirty {
  position: relative;
  // border: 3px solid var(--ion-color-tertiary);
  &:after {
    position: absolute;
    top: 0;
    right: 4px;
    content: '•';
    color: white;
    font-size: 30px;
    line-height: 15px;
    animation: fadeIn 1000ms infinite;
  }
}

.uploading {
  // border: 4px solid red;
}
