// .page-insight-more {
//   --ion-background-color: #E5E5E5;
//   ion-toolbar {
//     --background: #E5E5E5;
//   }
// }

.page-lot,
.page-order {
  .order-id {
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    gap: 3px;
    font-weight: 900;

    &.linked-id {
      font-size: 16px;
      margin-top: -2px;
    }

    .highlight {
      background-color: var(--ion-color-secondary);
      color: black;
      padding: 0 3px;
      border-radius: 3px;
    }

    .report-icon {
      font-size: 20px;
    }

    .order-icon {
      font-size: 20px;
    }
  }

  .metadata {
    border-bottom: 1px solid var(--ion-color-light-medium);
    color: var(--ion-color-dark);
    width: 100%;
    animation: fadeInDown 0.3s both;
    ion-input {
      text-align: right;
    }

    ion-item {
      overflow: visible;
      &.item-selectable {
        z-index: 08;
        &.z-09 {
          z-index: 09;
        }
        &.z-10 {
          z-index: 10;
        }
        &.z-11 {
          z-index: 11;
        }
        &.z-12 {
          z-index: 12;
        }
        > div {
          min-width: 200px;
          input {
            width: auto !important;
          }
        }
      }
    }

    .metadata-buttons {
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
  }
}
.page-lot {
  .loading {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    ion-spinner {
      transform: scale(2);
    }
  }

  // ion-header {
  //   background-color: var(--ion-color-light);
  // }

  // ion-toolbar {
  //   --background: var(--ion-color-light);
  // }

  ion-card,
  .ion-card {
    min-width: 280px;
    @media only screen and (min-width: 500px) {
      max-width: 360px;
    }
    .cover ion-badge {
      pointer-events: none;
      ion-icon {
        display: none;
      }
    }
  }
  .article-description {
    padding: 15px;
    width: 100%;
    background-color: var(--ion-color-light);
    color: var(--ion-color-dark);
    display: flex;
    align-items: center;
    // animation: fadeIn .5s both;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.41);

    .quantity {
      margin-left: auto;
      border: 2px solid var(--ion-color-light-medium);
      border-radius: 5px;
      padding: 6px;
      text-align: center;
      font-weight: bold;
      line-height: 20px;
      // background-color: rgba(0,0,0,.1);
      :first-child {
        font-size: 20px;
        font-weight: 900;
      }
      :nth-child(2) {
        text-transform: uppercase;
        font-size: 14px;
      }
    }
    .article-name {
      font-size: 20px;
    }
  }
  .button-container {
    bottom: 0;
    // width: 100%;
    right: 0;
    // animation: fadeInRight .3s both;
    // animation-delay: .5s;
    // background: rgba(0, 0, 0, .2);
    ion-button {
      margin: 15px;
    }
  }

  .page-lot-history {
    // .upfront-information-badge {
    // display:none;
    // }
    // animation: fadeIn .5s both;
    // animation-delay: .5s;
    .page-lot-history-list ion-card,
    .page-lot-history-list .ion-card {
      // animation: fadeInLeft .3s both;
      // animation-delay: .3s;
      z-index: 10;
      margin: 10px 10px 30px 10px;
    }
    // .page-lot-history-list ion-item-divider {
    // animation: fadeIn .3s both;
    // animation-delay: .3s;
    // }
    .card-inspection .view-article-description {
      display: none;
    }
    .icon-link {
      margin-top: 60px;
      color: var(--ion-color-medium);
    }
    ion-list {
      // animation: fadeIn .5s both;
      margin-bottom: 100px;
      overflow: visible;

      & > div {
        padding-bottom: 20px;
      }

      @media (min-width: 575px) {
        margin-top: 20px;
        border-left: 3px solid var(--ion-color-tertiary);
        margin-left: 30px;
        padding-bottom: 0;
        padding-top: 0;
        // margin-bottom: 0;

        &:after {
          content: ' ';
          width: 10px;
          height: 10px;
          display: block;
          background-color: var(--ion-color-tertiary);
          // border-radius: 50%;
          // position: absolute;
          // bottom:0;
          // left: 0;
          // margin-left: -5px;
        }

        // &:before {
        //   content: ' ';
        //   width: 10px;
        //   height: 10px;
        //   display: block;
        //   background-color: var(--ion-color-tertiary);
        //   clip-path: polygon(0 0, 100% 100%, 0 100%);
        //   margin-bottom: -1px;
        //   // margin-left: -1px;
        //   // margin-top: -1px;
        //   // border-radius: 50%;
        //   // position: absolute;
        //   // bottom:0;
        //   // left: 0;
        //   // margin-left: -5px;
        // }
      }
      // ion-item-divider {
      //   position: sticky;
      // }
      ion-item-divider {
        font-weight: bold;
        text-transform: uppercase;
        position: sticky;
        top: 86px;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: var(--ion-color-light);
        b {
          display: inline-block;
          margin-left: 10px;
        }
        @media (min-width: 575px) {
          padding-left: 0;
          border: 0;

          span {
            background-color: var(--ion-color-tertiary);
            color: white;
            padding: 5px;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
          }
        }
        span {
          margin-right: 10px;
        }
      }
      ion-item.item {
        ion-label {
          white-space: normal;
        }
      }
      .order-container {
        display: flex;
        overflow: auto;
        padding-bottom: 13px;
        z-index: 10;
        align-items: flex-start;
        > ion-card {
          z-index: 10;
        }
      }
    }
  }
}
