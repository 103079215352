.view-allocation-mismatch-warning {
  .view-allocation-mismatch-warning_grid {
    //width: 100%;
    .divider {
      border-top: 1px solid var(--ion-color-light-medium);
    }
    .view-allocation-mismatch-warning_grid-item {
      //width: 100%;
      .view-allocation-mismatch-warning_grid-item-row {
        width: 100%;
        .header {
          font-weight: bold;
        }
        .title {
          // font-weight: bold;
        }
        .expected {
          color: var(--ion-color-success);
        }
        .found {
          color: var(--ion-color-danger2);
        }
      }
    }
  }
}
