.small-modal .centered {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  .form {
    margin-top: 30px;
    ion-textarea {
      width: 400px;
      display: block;
      textarea {
        padding: 8px;
        text-align: left;
        border: 1px solid var(--ion-color-light-medium);
        border-radius: 4px;
      }
    }
    ion-button {
      margin-top: 15px;
    }
  }
}

.page-contact-profiling {
  ion-content {
  }
  ion-item {
    overflow: visible;
    z-index: 10;
    &.z-11 {
      z-index: 11;
    }
    &.z-12 {
      z-index: 12;
    }
    &.z-13 {
      z-index: 13;
    }
    > div {
      min-width: 250px;
      input {
        width: auto !important;
      }
    }
  }

  .loading-spinner {
    transform: scale(2);
    display: flex;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    animation: fadeInUp 0.3s ease-in-out;
  }

  .error-message {
    text-align: center;
    margin: 30px;
  }

  .no-enough-data {
    width: 200px;
    text-align: center;
    margin: auto;
    padding: 15px;
    background-color: var(--ion-color-light);
    border-radius: 8px;
  }

  .logo {
    display: flex;
    color: var(--ion-color-dark);
    text-decoration: none;
    animation: fadeInUp 0.3s both;
    img {
      width: 32px;
      margin-left: 12px;
      border-radius: 4px;
    }
    .agrinorm {
      font-weight: bold;
      padding-left: 12px;
    }
  }

  .customer-title {
    text-align: center;
    padding: 10px;
    font-size: 22px;
    font-weight: 900;
    background-color: var(--ion-item-background);
    color: var(--ion-color-medium);
    border-bottom: 1px solid var(--ion-color-light-medium);
    letter-spacing: 1px;
    &:empty {
      display: none;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    > div {
      flex: 1;
      &.left {
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        background-color: var(--ion-item-background);
        width: 100%;
        ion-label {
          margin-right: 10px;
          text-align: right;
          font-weight: bold;
        }
        ion-item {
          --padding-start: 0;
        }
        .react-select__control {
          width: 300px;
        }
      }
      &.right {
        min-width: 300px;
      }
    }
    // border-bottom: 1px solid var(--ion-color-light-medium);
    // margin-bottom: 15px;
    // padding-bottom: 15px;
  }

  .propertiesBreakdown {
    display: flex;
    justify-content: center;
    & > div {
      width: 350px;
    }
    // grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
  }
  .propertiesOther {
    border-bottom: 1px solid var(--ion-color-light-medium);
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .summary {
    display: flex;
    justify-content: center;
    // max-height: 100px;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 360px));
    > div {
      display: flex;
      // width: 200px;
      // text-align: center;
      align-items: center;
      span {
        font-size: 13px;
      }
    }
  }

  .summary-claims-sales-wrapper {
    // max-width: 800px;
    border-bottom: 1px solid var(--ion-color-light-medium);
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  .summary-claims-sales {
    display: flex;
    justify-content: center;
    gap: 20px;
    // max-height: 100px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 400px));
    h2 {
      display: none;
    }
    > div {
      display: flex;
      flex-direction: column;
      // width: 200px;
      // text-align: center;
      align-items: center;
      span {
        font-size: 13px;
      }
    }
  }
  .title {
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: bold;
  }
  .highcharts-credits {
    display: none;
  }
  .defects-incident-breakdown-grid {
    padding-bottom: 60px;
    ion-col {
      padding-top: 0;
    }
    .top-row {
      position: sticky;
      top: 0;
      background-color: var(--ion-color-light);
      z-index: 3;
      border-radius: 4px;
    }
    .top-row-2 {
      z-index: 3;
      position: sticky;
      top: 47px;
      padding: 5px 0;
      margin-bottom: 10px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: var(--ion-color-light);
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      padding: 10px 0;
      border-bottom: 1px solid var(--ion-color-light-medium);
    }
    > ion-row > ion-col {
      max-height: 400px;
      overflow: auto;
    }
  }
}
