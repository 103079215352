.page-reset {
  .loading-container {
    width: 100vw;
    height: 100vh;
    background-color: red;
    display: flex;
    justify-content: center;
    margin: 50px;
    animation: fadeInUp 0.5s both;
    ion-spinner {
      transform: scale(2);
    }
  }
  .cover-area-wrapper {
    background-color: lighten(#abdd7e, 15%);
  }
  .cover-area {
    height: calc(100vh - 440px);
    max-height: 600px;
    position: relative;
    overflow: visible;
    background-size: cover;
    animation: fadeIn 2s both;
    animation-delay: 0.5s;
    animation-timing-function: cubic-bezier();
  }
  .logo-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    margin-top: -60px;
    .brand-logo {
      width: 120px;
      height: 120px;
      z-index: 100;
      animation: fadeInDown 1s both;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
  .main-area {
    margin-top: 70px;
    height: 360px;
    width: 100%;
    animation: fadeIn 1s both;

    > h1 {
      font-size: 30px;
      animation: fadeIn 0.5s both;
      animation-delay: 0.6s;
      margin: 0;
    }
  }
  .password-reset-form {
    width: 400px;
    margin-top: 10px;
    .hidden {
      display: none;
    }
    .password-reset-input-group {
      --background: transparent;
      border-bottom: 1px solid var(--ion-color-light-medium);
      .password-reset-input {
        background-color: transparent;
      }
    }
    .password-reset-btn {
      width: 100px;
      float: right;
    }
    .back-to-login-btn {
      width: 100px;
      float: left;
    }
    .password-reset-input-group:focus-within {
      border-bottom: 0 solid var(--ion-color-light-medium);
    }
  }
}
