.component-partners-admin {
  max-width: 1200px;
  position: relative;

  .item-selectable {
    z-index: 100;
  }

  .actions {
    max-width: 50px;
  }

  .users {
    max-width: 80px;
  }

  input,
  .id-value {
    color: black !important;
  }
}
