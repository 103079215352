$score5: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTA0cHgiIGhlaWdodD0iMTEycHgiIHZpZXdCb3g9IjAgMCAxMDQgMTEyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPmRvd25sb2FkICgxKTwvdGl0bGU+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iZG93bmxvYWQtKDEpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjIzNTk1NSwgLTAuMDAyODEwKSIgZmlsbD0iIzM0QTg1NCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIuNzg3NjEzNDQsMTMuNTg0ODA4MSBMNTIuNDg0OTkzNCwwLjA2MzE0MDEwNTggQzUyLjgwMzE2MjIsLTAuMDIzNDI3Mjk0MiA1My4xMzg5OTY5LC0wLjAyMDkzNTI5NDIgNTMuNDU1ODQ2LDAuMDcwMzQ0MTE1OCBMMTAwLjI2NjQwNCwxMy41NTU3NTQ4IEMxMDEuODg1MTk2LDE0LjAyMjEwNDEgMTAzLDE1LjUwMzQwNSAxMDMsMTcuMTg4MDMyMSBMMTAzLDg5LjI0MzQxMDIgQzEwMyw5MC44MzMzMTA2IDEwMi4wMDUxMjgsOTIuMjUzMjY4MiAxMDAuNTEwNzk5LDkyLjc5NjE4OTIgTDUwLjY4MzUxOCwxMTAuODk5NDgxIEM1MC4yNzU2OTk1LDExMS4wNDc2NSA0OS44MjgwODQzLDExMS4wNDM1MTkgNDkuNDIzMDY5NiwxMTAuODg3ODUgTDIuNDIzODU3MjQsOTIuODIzNDMzOCBDMC45NjM2MzI2Niw5Mi4yNjIxODgxIDAsOTAuODU5NDUwMiAwLDg5LjI5NTA4MDUgTDAsMTcuMjMyMjEzOSBDMCwxNS41MjY3NzI5IDEuMTQxOTk1NjYsMTQuMDMyNTQ4IDIuNzg3NjEzNDQsMTMuNTg0ODA4MSBaIiBpZD0icGF0aC0xIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=';
$score4: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTIwcHgiIGhlaWdodD0iMTIwcHgiIHZpZXdCb3g9IjAgMCAxMjAgMTIwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1My4yICg3MjY0MykgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+Z3J1ZW48L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBkPSJNMTEuMDIzNTY4NSwxNy41ODE5OTgyIEw2MC43MjA5NDg1LDQuMDYwMzMwMjMgQzYxLjAzOTExNzMsMy45NzM3NjI4MyA2MS4zNzQ5NTIsMy45NzYyNTQ4MyA2MS42OTE4MDExLDQuMDY3NTM0MjQgTDEwOC41MDIzNTksMTcuNTUyOTQ0OSBDMTEwLjEyMTE1MSwxOC4wMTkyOTQyIDExMS4yMzU5NTUsMTkuNTAwNTk1MSAxMTEuMjM1OTU1LDIxLjE4NTIyMjIgTDExMS4yMzU5NTUsOTMuMjQwNjAwMyBDMTExLjIzNTk1NSw5NC44MzA1MDA3IDExMC4yNDEwODMsOTYuMjUwNDU4MyAxMDguNzQ2NzU0LDk2Ljc5MzM3OTMgTDU4LjkxOTQ3MzEsMTE0Ljg5NjY3MSBDNTguNTExNjU0NiwxMTUuMDQ0ODQgNTguMDY0MDM5NCwxMTUuMDQwNzA5IDU3LjY1OTAyNDcsMTE0Ljg4NTA0IEwxMC42NTk4MTIzLDk2LjgyMDYyMzkgQzkuMTk5NTg3NzIsOTYuMjU5Mzc4MiA4LjIzNTk1NTA2LDk0Ljg1NjY0MDMgOC4yMzU5NTUwNiw5My4yOTIyNzA2IEw4LjIzNTk1NTA2LDIxLjIyOTQwNCBDOC4yMzU5NTUwNiwxOS41MjM5NjMgOS4zNzc5NTA3MiwxOC4wMjk3MzgxIDExLjAyMzU2ODUsMTcuNTgxOTk4MiBaIiBpZD0icGF0aC0xIj48L3BhdGg+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0iZ3J1ZW4iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4KICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICA8L21hc2s+CiAgICAgICAgPHVzZSBpZD0iTWFzayIgZmlsbD0iI0FCREQ3RSIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICA8L2c+Cjwvc3ZnPg==';
$score3: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTIwcHgiIGhlaWdodD0iMTIwcHgiIHZpZXdCb3g9IjAgMCAxMjAgMTIwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1My4yICg3MjY0MykgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+b3JhbmdlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+CiAgICAgICAgPHBhdGggZD0iTTExLjAyMzU2ODUsMTcuNTgxOTk4MiBMNjAuNzIwOTQ4NSw0LjA2MDMzMDIzIEM2MS4wMzkxMTczLDMuOTczNzYyODMgNjEuMzc0OTUyLDMuOTc2MjU0ODMgNjEuNjkxODAxMSw0LjA2NzUzNDI0IEwxMDguNTAyMzU5LDE3LjU1Mjk0NDkgQzExMC4xMjExNTEsMTguMDE5Mjk0MiAxMTEuMjM1OTU1LDE5LjUwMDU5NTEgMTExLjIzNTk1NSwyMS4xODUyMjIyIEwxMTEuMjM1OTU1LDkzLjI0MDYwMDMgQzExMS4yMzU5NTUsOTQuODMwNTAwNyAxMTAuMjQxMDgzLDk2LjI1MDQ1ODMgMTA4Ljc0Njc1NCw5Ni43OTMzNzkzIEw1OC45MTk0NzMxLDExNC44OTY2NzEgQzU4LjUxMTY1NDYsMTE1LjA0NDg0IDU4LjA2NDAzOTQsMTE1LjA0MDcwOSA1Ny42NTkwMjQ3LDExNC44ODUwNCBMMTAuNjU5ODEyMyw5Ni44MjA2MjM5IEM5LjE5OTU4NzcyLDk2LjI1OTM3ODIgOC4yMzU5NTUwNiw5NC44NTY2NDAzIDguMjM1OTU1MDYsOTMuMjkyMjcwNiBMOC4yMzU5NTUwNiwyMS4yMjk0MDQgQzguMjM1OTU1MDYsMTkuNTIzOTYzIDkuMzc3OTUwNzIsMTguMDI5NzM4MSAxMS4wMjM1Njg1LDE3LjU4MTk5ODIgWiIgaWQ9InBhdGgtMSI+PC9wYXRoPgogICAgPC9kZWZzPgogICAgPGcgaWQ9Im9yYW5nZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPG1hc2sgaWQ9Im1hc2stMiIgZmlsbD0id2hpdGUiPgogICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPgogICAgICAgIDwvbWFzaz4KICAgICAgICA8dXNlIGlkPSJNYXNrIiBmaWxsPSIjRkZDNTgzIiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgIDwvZz4KPC9zdmc+';
$score2: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTIwcHgiIGhlaWdodD0iMTIwcHgiIHZpZXdCb3g9IjAgMCAxMjAgMTIwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1My4yICg3MjY0MykgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+cm90PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+CiAgICAgICAgPHBhdGggZD0iTTExLjAyMzU2ODUsMTcuNTgxOTk4MiBMNjAuNzIwOTQ4NSw0LjA2MDMzMDIzIEM2MS4wMzkxMTczLDMuOTczNzYyODMgNjEuMzc0OTUyLDMuOTc2MjU0ODMgNjEuNjkxODAxMSw0LjA2NzUzNDI0IEwxMDguNTAyMzU5LDE3LjU1Mjk0NDkgQzExMC4xMjExNTEsMTguMDE5Mjk0MiAxMTEuMjM1OTU1LDE5LjUwMDU5NTEgMTExLjIzNTk1NSwyMS4xODUyMjIyIEwxMTEuMjM1OTU1LDkzLjI0MDYwMDMgQzExMS4yMzU5NTUsOTQuODMwNTAwNyAxMTAuMjQxMDgzLDk2LjI1MDQ1ODMgMTA4Ljc0Njc1NCw5Ni43OTMzNzkzIEw1OC45MTk0NzMxLDExNC44OTY2NzEgQzU4LjUxMTY1NDYsMTE1LjA0NDg0IDU4LjA2NDAzOTQsMTE1LjA0MDcwOSA1Ny42NTkwMjQ3LDExNC44ODUwNCBMMTAuNjU5ODEyMyw5Ni44MjA2MjM5IEM5LjE5OTU4NzcyLDk2LjI1OTM3ODIgOC4yMzU5NTUwNiw5NC44NTY2NDAzIDguMjM1OTU1MDYsOTMuMjkyMjcwNiBMOC4yMzU5NTUwNiwyMS4yMjk0MDQgQzguMjM1OTU1MDYsMTkuNTIzOTYzIDkuMzc3OTUwNzIsMTguMDI5NzM4MSAxMS4wMjM1Njg1LDE3LjU4MTk5ODIgWiIgaWQ9InBhdGgtMSI+PC9wYXRoPgogICAgPC9kZWZzPgogICAgPGcgaWQ9InJvdCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPG1hc2sgaWQ9Im1hc2stMiIgZmlsbD0id2hpdGUiPgogICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPgogICAgICAgIDwvbWFzaz4KICAgICAgICA8dXNlIGlkPSJNYXNrIiBmaWxsPSIjRkE5MzdBIiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgIDwvZz4KPC9zdmc+';
$score1: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTIwcHgiIGhlaWdodD0iMTIwcHgiIHZpZXdCb3g9IjAgMCAxMjAgMTIwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPkFydGJvYXJkPC90aXRsZT4KICAgIDxkZWZzPgogICAgICAgIDxmaWx0ZXIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJhdXRvIiBpZD0iZmlsdGVyLTEiPgogICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlR3JhcGhpYyIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMC45MTc2NDcgMCAwIDAgMCAwLjAxMTc2NSAwIDAgMCAwIDAuMDI3NDUxIDAgMCAwIDEuMDAwMDAwIDAiPjwvZmVDb2xvck1hdHJpeD4KICAgICAgICA8L2ZpbHRlcj4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlci0xKSIgaWQ9ImRvd25sb2FkIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOC4yMzU5NTUsIDMuOTk3MTkwKSI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMi43ODc2MTM0NCwxMy41ODQ4MDgxIEw1Mi40ODQ5OTM0LDAuMDYzMTQwMTA1OCBDNTIuODAzMTYyMiwtMC4wMjM0MjcyOTQyIDUzLjEzODk5NjksLTAuMDIwOTM1Mjk0MiA1My40NTU4NDYsMC4wNzAzNDQxMTU4IEwxMDAuMjY2NDA0LDEzLjU1NTc1NDggQzEwMS44ODUxOTYsMTQuMDIyMTA0MSAxMDMsMTUuNTAzNDA1IDEwMywxNy4xODgwMzIxIEwxMDMsODkuMjQzNDEwMiBDMTAzLDkwLjgzMzMxMDYgMTAyLjAwNTEyOCw5Mi4yNTMyNjgyIDEwMC41MTA3OTksOTIuNzk2MTg5MiBMNTAuNjgzNTE4LDExMC44OTk0ODEgQzUwLjI3NTY5OTUsMTExLjA0NzY1IDQ5LjgyODA4NDMsMTExLjA0MzUxOSA0OS40MjMwNjk2LDExMC44ODc4NSBMMi40MjM4NTcyNCw5Mi44MjM0MzM4IEMwLjk2MzYzMjY2LDkyLjI2MjE4ODEgMCw5MC44NTk0NTAyIDAsODkuMjk1MDgwNSBMMCwxNy4yMzIyMTM5IEMwLDE1LjUyNjc3MjkgMS4xNDE5OTU2NiwxNC4wMzI1NDggMi43ODc2MTM0NCwxMy41ODQ4MDgxIFoiIGlkPSJwYXRoLTEiIGZpbGw9IiNGQTkzN0EiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=';
$score-1: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDEyMCAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJncmF5Ij4KPHBhdGggaWQ9Ik1hc2siIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOC4yMzU5NiAyMS4yMjk0QzguMjM1OTYgMTkuNTI0IDkuMzc3OTYgMTguMDI5NyAxMS4wMjM2IDE3LjU4Mkw2MC43MjEgNC4wNjAzM0M2MS4wMzkxIDMuOTczNzYgNjEuMzc1IDMuOTc2MjUgNjEuNjkxOCA0LjA2NzUzTDEwOC41MDIgMTcuNTUyOUMxMTAuMTIxIDE4LjAxOTMgMTExLjIzNiAxOS41MDA2IDExMS4yMzYgMjEuMTg1MlY5My4yNDA2QzExMS4yMzYgOTQuODMwNSAxMTAuMjQxIDk2LjI1MDUgMTA4Ljc0NyA5Ni43OTM0TDU4LjkxOTUgMTE0Ljg5N0M1OC41MTE3IDExNS4wNDUgNTguMDY0IDExNS4wNDEgNTcuNjU5IDExNC44ODVMMTAuNjU5OCA5Ni44MjA2QzkuMTk5NTkgOTYuMjU5NCA4LjIzNTk2IDk0Ljg1NjYgOC4yMzU5NiA5My4yOTIzTDguMjM1OTYgMjEuMjI5NFoiIGZpbGw9IiNCREJEQkQiLz4KPG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI4IiB5PSIzIiB3aWR0aD0iMTA0IiBoZWlnaHQ9IjExMyI+CjxwYXRoIGlkPSJNYXNrXzIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOC4yMzU5NiAyMS4yMjk0QzguMjM1OTYgMTkuNTI0IDkuMzc3OTYgMTguMDI5NyAxMS4wMjM2IDE3LjU4Mkw2MC43MjEgNC4wNjAzM0M2MS4wMzkxIDMuOTczNzYgNjEuMzc1IDMuOTc2MjUgNjEuNjkxOCA0LjA2NzUzTDEwOC41MDIgMTcuNTUyOUMxMTAuMTIxIDE4LjAxOTMgMTExLjIzNiAxOS41MDA2IDExMS4yMzYgMjEuMTg1MlY5My4yNDA2QzExMS4yMzYgOTQuODMwNSAxMTAuMjQxIDk2LjI1MDUgMTA4Ljc0NyA5Ni43OTM0TDU4LjkxOTUgMTE0Ljg5N0M1OC41MTE3IDExNS4wNDUgNTguMDY0IDExNS4wNDEgNTcuNjU5IDExNC44ODVMMTAuNjU5OCA5Ni44MjA2QzkuMTk5NTkgOTYuMjU5NCA4LjIzNTk2IDk0Ljg1NjYgOC4yMzU5NiA5My4yOTIzTDguMjM1OTYgMjEuMjI5NFoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMCkiPgo8L2c+CjwvZz4KPC9zdmc+Cg==';

.view-assessment-score {
  display: flex;
  color: rgba(0, 0, 0, 0.8);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;

  &:empty {
    display: none;
  }

  .score {
    .expandable {
      cursor: pointer;
    }

    &.score-generic:not(.score-1) {
      .numerical {
        color: var(--ion-color-dark);
      }
    }

    &.score-5 {
      background-image: url($score5);
      color: white !important;
    }

    &.score-1:not(.score-generic) {
      background-image: url($score1);
      color: white;
    }

    &.score-2 {
      background-image: url($score2);
      .numerical {
        color: rgba(0, 0, 0, 0.8) !important;
      }
    }
    &.score-3 {
      background-image: url($score3);
      .numerical {
        color: rgba(0, 0, 0, 0.8) !important;
      }
    }
    &.score-4 {
      background-image: url($score4);
      .numerical {
        color: rgba(0, 0, 0, 0.8) !important;
      }
    }

    &.score-generic,
    &.score-generic.group_generic_long {
      background-image: none;
      background-color: var(--ion-color-light-medium);
      padding: 0 6px;
      border-radius: 4px;
      width: auto !important;
      height: 24px;
      // max-width: 55px;
      white-space: nowrap;

      .numerical {
        font-size: 15px;
        // color: var(--ion-color-dark)!important;
      }
      &.score-1 {
        color: white !important;
        background-color: var(--ion-color-danger2) !important;
      }
      &.score-4 {
        background-color: var(--ion-color-primary) !important;
      }
      &.score-5 {
        color: white !important;
        background-color: var(--ion-color-excellent) !important;
        .numerical {
          color: white !important;
        }
      }
      &.score-3 {
        background-color: var(--ion-color-warning) !important;
      }
      &.score-2 {
        background-color: var(--ion-color-danger) !important;
      }

      &.score-custom-BLUE {
        background-image: none;
        background-color: #3880ff !important;
        .numerical {
          color: white !important;
        }
      }
      &.score-custom-GREY {
        background-image: none;
        background-color: var(--ion-color-medium) !important;
        .numerical {
          color: white !important;
        }
      }
      &.score-custom-BLACK {
        background-image: none;
        background-color: var(--ion-color-dark) !important;
        .numerical {
          color: white !important;
        }
      }
      &.score-custom-REJECT {
        background-image: none;
        background-color: var(--ion-color-danger2) !important;
        .numerical {
          color: white !important;
        }
      }
      &.score-custom-AMBER {
        background-image: none;
        background-color: var(--ion-color-secondary) !important;
      }
      &.score-custom-RED {
        background-image: none;
        background-color: var(--ion-color-danger) !important;
      }
      &.score-custom-GREEN {
        background-image: none;
        background-color: var(--ion-color-primary) !important;
      }
    }

    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    width: 40px;
    height: 38px;

    // &.group_condition {
    //   order: 1;
    // }

    // &.group_appearance {
    //   order: 2;
    // }

    .label {
      font-size: 10px;
      font-weight: normal;
      margin: 3px 0 -2px 0;
      display: none;
    }
    .numerical {
      font-weight: bold;
      font-size: 16px;
    }
  }

  &.INLINE {
    .score {
      width: 26px;
      height: 30px;
      .numerical {
        font-size: 16px;
      }
      .label {
        display: none;
      }
    }
  }

  &.big {
    .score.score-generic.group_generic_long .numerical {
      font-size: 15px !important;
    }
  }

  &.CARD-ORDER {
    .score {
      width: 26px;
      height: 30px;
      // .numerical {
      //     font-size: 16px;
      // }
      .label {
        display: none;
      }
    }
  }
}
