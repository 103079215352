.dark {
  .page-conversation {
    .sidebar {
      box-shadow: 0 0 20px 0px #111;
    }
  }
}
.page-conversation {
  ion-title {
    font-size: 16px;
    cursor: pointer;
  }

  .top-banner {
    width: 100%;
    ion-card {
      border: 0;
      border-bottom: 1px solid var(--ion-color-light);
      margin: 0;
      box-shadow: none;
      width: 100%;
      max-width: 100% !important;
      border-radius: 0;
      position: fixed;
      animation: fadeInDown 0.3s both;
      animation-delay: 0.4s;
      z-index: 1;
      // top: 0px;
      // max-height: 120px;
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    padding-top: 50px;
    z-index: 1;
    background: var(--ion-color-light);
    animation: fadeInRight 0.3s both;
    box-shadow: 0 0 20px 0px #ccc;
    ion-list {
      background: var(--ion-color-light);
      ion-item {
        cursor: pointer;
        border-bottom: 1px solid var(--ion-color-light);
        --padding-start: 10px;
        font-size: 14px;

        ion-icon {
          font-size: 40px;
          color: var(--ion-color-light-medium);
          margin-right: 5px;
        }

        div {
          display: flex;
          flex-direction: column;
          b {
            font-size: 16px;
          }
          span {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  // &.two-people-conversation {
  //   .user-name {
  //     display:none;
  //   }
  // }

  // hack for the no-background page
  background: var(--ion-color-background);
  &.no-bg {
    background: none;
  }
  &.no-animation {
    .messages > *:not(:last-child) {
      animation: none !important;
      animation-delay: 0s !important;
    }
  }

  .loading-wrapper {
    padding: 15px 30px;
    ion-spinner {
      transform: scale(2);
    }
  }

  ion-textarea,
  ion-item.sc-ion-textarea-ios-h:not(.item-label),
  ion-item:not(.item-label) .sc-ion-textarea-ios-h {
    // --background: var(--ion-color-background);
    --padding-start: 10px;
    // border-radius: 8px;
    // --background: transparent;
  }

  ion-list {
    padding-bottom: 70px;
    @media (min-width: 575px) {
      padding-bottom: 90px;
    }
  }

  ion-input {
    text-align: left;
    --padding-start: 10px !important;
  }

  .emoji {
    font-size: 25px;
  }

  .add-form {
    ion-icon {
      font-size: 30px;
    }
  }

  // textarea {
  // background: green!important;
  // }

  .send-button {
    ion-icon {
      font-size: 24px;
    }
  }

  .sc-ion-textarea-md-h,
  .sc-ion-textarea-ios-h {
    flex: auto;
    padding-top: 0;
    margin-top: 0;
  }

  ion-footer {
    // animation: fadeInUp;
    // animation-fill-mode: both;
    bottom: 0;
    position: fixed;
    // animation-delay: .2s;
    // animation-duration: .3s;
    width: 100%;
    ion-toolbar {
      --background: var(--ion-color-light);
    }
  }

  .ion-card,
  ion-card {
    max-width: 300px;
    min-width: 280px;

    margin-bottom: 15px;
    margin-top: 15px;

    @media only screen and (min-width: 768px) {
      max-width: 360px;
      min-width: 360px;
    }
  }

  .card-wrapper,
  .loading-wrapper {
    width: 100%;
    display: flex;
    .card-inspection {
      margin: 15px;
    }
    &.i-did {
      justify-content: flex-end;
    }
  }

  .scroll-down-msg {
    font-size: 12px;
    margin-top: 15px;
    text-align: center;
    text-transform: uppercase;
    color: var(--ion-color-medium);

    animation: fadeInTop;
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .messages {
    padding: 10px 0;
    // background: var(--ion-color-background);
    // min-height: calc(100vh - 118px);

    &.has-order {
      margin-top: 135px;
    }
  }

  .messages > * {
    animation-timing-function: ease-out !important;
  }

  .messages > .i-did {
    animation: fadeInRight;
    animation-fill-mode: both;

    // animation-delay: .1s;
    animation-duration: 0.3s;
  }

  .messages > .they-did {
    animation: fadeInLeft;
    animation-fill-mode: both;

    // animation-delay: .1s;
    animation-duration: 0.3s;
  }

  .they-did ion-card,
  .they-did > div,
  .bubble.they-did {
    order: -1;
  }

  .go-to-top {
    order: 0;
  }

  .date-separator {
    font-size: 11px;
    color: var(--ion-color-medium);
    position: relative;
    margin: 3px;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 13px;
    z-index: 3;
    span {
      padding: 2px 10px;
      background-color: var(--ion-color-light);
      animation: fadeInUp 0.3s ease-in;
      border-radius: 5px;
    }
  }
  .timestamp {
    font-size: 10px;
    margin: 18px 0 0 0;
    // display: table;
    // float: right;
    position: relative;
    text-transform: uppercase;
    color: var(--ion-color-medium);
  }

  .status-wrapper {
    text-align: center;
    border-bottom: 1px solid var(--ion-color-light);
    // margin-right: 10px;
    // margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1s both !important;
    margin-bottom: 10px;
    margin-top: 10px;

    div {
      border-radius: 5px;
      position: absolute;
      font-size: 12px;
      background: var(--ion-color-background);
      color: var(--ion-color-medium);
      padding: 4px 15px;
    }
  }

  .msg {
    // width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
    position: relative;

    .timestamp {
      margin-top: 11px;
    }

    &.ORDER .bubble,
    &.REPORT .bubble {
      width: 90%;
      ion-card {
        margin: 4px;
      }
    }

    &.selected {
      background-color: #bbb;
    }

    &.i-did {
      justify-content: flex-end;
    }

    .i-did {
      .user-name {
        display: none;
      }
    }

    .user-name {
      margin-left: 20px;
      font-size: 12px;
      color: var(--ion-color-medium);
    }

    .bubble {
      max-width: 250px;
      height: auto;
      display: block;
      background: var(--ion-color-light);
      color: var(--ion-color-dark);
      border-radius: 5px;
      position: relative;
      margin: 10px 10px 3px 15px;

      @media only screen and (min-width: 768px) {
        max-width: 400px;
      }

      .txt {
        padding: 8px 0 8px 0;
        // width: 100%;

        .name {
          font-weight: 600;
          font-size: 14px;
          display: inline-table;
          padding: 0 0 0 15px;
          margin: 0 0 4px 0;

          span {
            font-weight: normal;
            overflow: hidden;
          }
        }

        .message {
          font-size: 14px;
          font-weight: 500;
          padding: 0 15px 0 15px;
          margin: auto;
          display: block;
          white-space: pre-line;
        }
      }

      // .bubble-arrow {
      //   position: absolute;
      //   float: left;
      //   left: -11px;
      //   top: 0px;

      //   &:after {
      //     content: "";
      //     position: absolute;
      //     border-top: 15px solid var(--ion-color-tertiary);
      //     border-left: 15px solid transparent;
      //     border-radius: 4px 0 0 0px;
      //     width: 0;
      //     height: 0;
      //   }
      // }

      // .bubble-arrow.alt {
      //   bottom: 20px;
      //   left: auto;
      //   right: 4px;
      //   float: right;

      //   &:after {
      //     border-top: 15px solid var(--ion-color-tertiary);
      //     transform: scaleX(-1);
      //   }
      // }
    }

    .bubble.i-did {
      margin: 10px 15px 0px 10px;
      background: var(--ion-color-light-medium);
      color: var(--ion-color-light-dark);
      justify-content: flex-end;
    }

    .bubble.follow {
      margin: 2px 0 0px 15px;
    }

    .bubble.altfollow {
      margin: 2px 10px 0px 0px;
      background: var(--ion-color-tertiary);
      justify-content: flex-end;
    }
  }
  // fix for iphone 5 and really small screens
  @media only screen and (max-width: 320px) {
    .card-wrapper .timestamp {
      display: none;
    }
  }
}

// .dark .bubble.i-did {
// color: white!important;
// }
