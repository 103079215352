/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

:root {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #1c1c1c;
  --ion-text-color-rgb: 28, 28, 28;

  --ion-color-step-50: #f4f4f4;
  --ion-color-step-100: #e8e8e8;
  --ion-color-step-150: #dddddd;
  --ion-color-step-200: #d2d2d2;
  --ion-color-step-250: #c6c6c6;
  --ion-color-step-300: #bbbbbb;
  --ion-color-step-350: #b0b0b0;
  --ion-color-step-400: #a4a4a4;
  --ion-color-step-450: #999999;
  --ion-color-step-500: #8e8e8e;
  --ion-color-step-550: #828282;
  --ion-color-step-600: #777777;
  --ion-color-step-650: #6b6b6b;
  --ion-color-step-700: #606060;
  --ion-color-step-750: #555555;
  --ion-color-step-800: #494949;
  --ion-color-step-850: #3e3e3e;
  --ion-color-step-900: #333333;
  --ion-color-step-950: #272727;
}

/** Ionic CSS Variables **/
:root {
  --side-max-width: 300px;

  // --ion-font-family: 'Roboto', sans-serif;
  // --ion-font-family: 'Poppins', sans-serif;
  --ion-font-family: 'HK Grotesk', sans-serif;
  // --ion-font-family: 'Open Sans', sans-serif;
  //--ion-font-family: 'Work Sans', sans-serif;

  --ion-color-gray: #b8b8b8;
  // font-weight:

  --ion-background-color: #ffffff;
  --ion-toolbar-background: #fff;

  ion-list {
    background: #ffffff;
  }

  --ion-color-primary: #97d176;
  --ion-color-primary-rgb: 151, 209, 118;
  --ion-color-primary-contrast: #0000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #85b868;
  --ion-color-primary-tint: #a1d684;

  --ion-color-primary-alt: #34a854;
  --ion-color-primary-alt-rgb: 52, 168, 84;
  --ion-color-primary-alt-contrast: #333333;
  --ion-color-primary-alt-contrast-rgb: 33, 33, 33;
  --ion-color-primary-alt-shade: #2e944a;
  --ion-color-primary-alt-tint: #48b165;

  --ion-color-secondary: #ffcc68;
  --ion-color-secondary-rgb: 255, 204, 104;
  --ion-color-secondary-contrast: #333333;
  --ion-color-secondary-contrast-rgb: 33, 33, 33;
  --ion-color-secondary-shade: #e0b45c;
  --ion-color-secondary-tint: #ffd177;

  --ion-color-tertiary: #bf79e9;
  --ion-color-tertiary-rgb: 191, 121, 233;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #a86acd;
  --ion-color-tertiary-tint: #c586eb;

  --ion-color-blue: #3d91ff;
  --ion-color-blue-rgb: 61, 145, 255;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #3680e0;
  --ion-color-blue-tint: #509cff;

  .ion-color-blue {
    --ion-color-base: var(--ion-color-blue);
    --ion-color-base-rgb: var(--ion-color-blue-rgb);
    --ion-color-contrast: var(--ion-color-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-shade);
    --ion-color-tint: var(--ion-color-blue-tint);
  }

  --ion-color-success: #34a854;
  --ion-color-success-rgb: 52, 168, 84;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2e944a;
  --ion-color-success-tint: #48b165;

  --ion-color-excellent: #34a854;
  --ion-color-excellent-rgb: 52, 168, 84;
  --ion-color-excellent-contrast: #ffffff;
  --ion-color-excellent-contrast-rgb: 255, 255, 255;
  --ion-color-excellent-shade: #2e944a;
  --ion-color-excellent-tint: #48b165;

  --ion-color-warning: #ffcc68;
  --ion-color-warning-rgb: 255, 204, 104;
  --ion-color-warning-contrast: #333333;
  --ion-color-warning-contrast-rgb: 33, 33, 33;
  --ion-color-warning-shade: #e0b45c;
  --ion-color-warning-tint: #ffd177;

  --ion-color-danger: #fa937a;
  --ion-color-danger-rgb: 250, 147, 122;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #dc816b;
  --ion-color-danger-tint: #fb9e87;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-light-medium: #dddddd;
  --ion-color-light-medium-rgb: 221, 221, 221;
  --ion-color-light-medium-contrast: #000000;
  --ion-color-light-medium-contrast-rgb: 0, 0, 0;
  --ion-color-light-medium-shade: #c2c2c2;
  --ion-color-light-medium-tint: #e0e0e0;

  .ion-color-light-medium {
    --ion-color-base: var(--ion-color-light-medium);
    --ion-color-base-rgb: var(--ion-color-light-medium-rgb);
    --ion-color-contrast: var(--ion-color-light-medium-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-medium-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-medium-shade);
    --ion-color-tint: var(--ion-color-light-medium-tint);
  }

  --ion-color-camera: #ea0206;
  --ion-color-camera-rgb: 234, 2, 6;
  --ion-color-camera-contrast: #ffffff;
  --ion-color-camera-contrast-rgb: 255, 255, 255;
  --ion-color-camera-shade: #ce0205;
  --ion-color-camera-tint: #ec1b1f;

  .ion-color-camera {
    --ion-color-base: var(--ion-color-camera);
    --ion-color-base-rgb: var(--ion-color-camera-rgb);
    --ion-color-contrast: var(--ion-color-camera-contrast);
    --ion-color-contrast-rgb: var(--ion-color-camera-contrast-rgb);
    --ion-color-shade: var(--ion-color-camera-shade);
    --ion-color-tint: var(--ion-color-camera-tint);
  }

  --ion-color-danger2: #ea0206;
  --ion-color-danger2-rgb: 234, 2, 6;
  --ion-color-danger2-contrast: #ffffff;
  --ion-color-danger2-contrast-rgb: 255, 255, 255;
  --ion-color-danger2-shade: #ce0205;
  --ion-color-danger2-tint: #ec1b1f;

  .ion-color-danger2 {
    --ion-color-base: var(--ion-color-danger2);
    --ion-color-base-rgb: var(--ion-color-danger2-rgb);
    --ion-color-contrast: var(--ion-color-danger2-contrast);
    --ion-color-contrast-rgb: var(--ion-color-danger2-contrast-rgb);
    --ion-color-shade: var(--ion-color-danger2-shade);
    --ion-color-tint: var(--ion-color-danger2-tint);
  }

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  .ion-color-white {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
  }

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #101010;
  --ion-color-black-tint: #000000;

  .ion-color-black {
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
  }

  --score-0: var(--ion-color-success);
  --score-1: var(--ion-color-warning);
  --score-2: var(--ion-color-danger);

  --google: #4285f4;
  --facebook: #3b5998;

  --light_gray: #e6e6e6;

  ion-item {
    // --padding-start: 10px;
    // --min-height: 60px;
    --border-color: transparent;
    // background-color: #fafafa;
  }
  ion-card ion-item:last-child {
    padding-bottom: 0;
  }

  ion-segment-button {
    // &::part(native) {
    --padding-start: 10px;
    --padding-end: 10px;
    min-width: auto;
    // }
  }

  ion-card-title {
    font-size: 18px;
    font-weight: bold;
  }

  ion-card-header,
  ion-card-content {
    padding-inline: 15px;
  }

  ion-card-subtitle {
    text-transform: none;
    font-weight: normal;
    font-size: 12px;
    margin-top: 3px;
    margin-left: 0.5px;

    ion-card-title {
      font-size: 18px;
      font-weight: normal;
    }
  }

  // ion-toolbar {
  //   --background: red
  // }

  // --ion-toolbar-background: var(--ion-color-light);

  ion-toolbar {
    // --background: var(--ion-color-light);
  }

  --ion-toolbar-segment-indicator-color: var(--ion-color-primary);
  --ion-toolbar-segment-color-checked: var(--ion-color-primary-contrast);
  .md {
    --ion-toolbar-segment-color-checked: var(--ion-color-primary);
  }

  ion-card,
  .ion-card {
    // --ion-background-color: blue;
    // mask-image: radial-gradient(white, black);

    // solves weird border-radius issue on IOS ? ofc not
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    box-shadow: 0px 10px 20px rgba(35, 35, 39, 0.2);
    // box-shadow: none;
    overflow: hidden;
    // border: 0!important;
    // background: #ffffff;
    border-radius: 10px;
    // border-top: 1px solid rgba(35, 35, 39, 0.01);
    background-color: var(--ion-background-color);
    // border-color: blue;;
    ion-card-content {
      // background-color: red;
      // border-radius: 10px;
      overflow: hidden;
    }
    ::part(native) {
      // background-color: VIOLET;
      // border-radius: 10px;
      overflow: hidden;
    }
  }

  ion-list {
    // background-color: #fafafa;
  }

  // ion-tab-bar {
  //   bottom: 20px;
  //   position: relative;
  //   border-radius: 16px;
  //   width: 92%;
  //   margin: 0 auto;
  // }

  ion-icon {
    font-size: 24px;
  }

  ion-list-header ion-label {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .cover-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    ion-icon {
      font-size: 36px;
    }
  }
  body {
    --ion-color-background: #ffffff;
  }

  --ion-item-background: #fafafa;

  ion-tab-button {
    --color: var(--ion-color-step-200);
    --color-selected: var(--ion-color-primary);

    // &::before {
    //   background-color: transparent;
    //   display: block;
    //   content: "";
    //   margin: 0 auto;
    //   width: 20px;
    //   height: 2px;
    // }

    // &.tab-selected::before {
    //   background-color: var(--ion-color-primary);
    // }
  }

  ion-input {
    --background: #eee;
    input {
      padding-right: 10px !important;
    }
  }

  ion-toggle::part(track) {
    // background: black;
    // --ion-color-base: black;
  }

  // --ion-card-background: var(--light_gray);
  // --ion-card-box-shadow: none;

  .searchbar-input {
    font-size: 14px;
  }
  .searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    padding-inline-start: 32px;
    border-radius: 4px;
  }
}
/*
 * Dark Colors
 * ------------------
 */

body.dark {
  --ion-background-color: #222;
  --ion-toolbar-background: #0d0d0d;
  --ion-background-color-rgb: 34, 34, 34;

  --ion-text-color: #ececec;
  --ion-text-color-rgb: 227, 227, 227;

  // --ion-color-primary: #97D176;
  // --ion-color-primary-rgb: 151,209,118;
  // --ion-color-primary-contrast: #000000;
  // --ion-color-primary-contrast-rgb: 0,0,0;
  // --ion-color-primary-shade: #85b868;
  // --ion-color-primary-tint: #a1d684;

  --ion-color-primary-alt: #34a854;
  --ion-color-primary-alt-rgb: 52, 168, 84;
  --ion-color-primary-alt-contrast: #000000;
  --ion-color-primary-alt-contrast-rgb: 0, 0, 0;
  --ion-color-primary-alt-shade: #2e944a;
  --ion-color-primary-alt-tint: #48b165;

  // --ion-color-secondary: #FFCC68;
  // --ion-color-secondary-rgb: 255,204,104;
  // --ion-color-secondary-contrast: #000000;
  // --ion-color-secondary-contrast-rgb: 0,0,0;
  // --ion-color-secondary-shade: #e0b45c;
  // --ion-color-secondary-tint: #ffd177;

  // --ion-color-tertiary: #BF79E9;
  // --ion-color-tertiary-rgb: 191,121,233;
  // --ion-color-tertiary-contrast: #000000;
  // --ion-color-tertiary-contrast-rgb: 0,0,0;
  // --ion-color-tertiary-shade: #a86acd;
  // --ion-color-tertiary-tint: #c586eb;

  --ion-color-success: #34a854;
  --ion-color-success-rgb: 52, 168, 84;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #2e944a;
  --ion-color-success-tint: #48b165;

  --ion-color-warning: #ffcc68;
  --ion-color-warning-rgb: 255, 204, 104;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b45c;
  --ion-color-warning-tint: #ffd177;

  --ion-color-danger: #fa937a;
  --ion-color-danger-rgb: 250, 147, 122;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #dc816b;
  --ion-color-danger-tint: #fb9e87;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-light-medium: #333345;
  --ion-color-light-medium-rgb: 51, 51, 69;
  --ion-color-light-medium-contrast: #ffffff;
  --ion-color-light-medium-contrast-rgb: 255, 255, 255;
  --ion-color-light-medium-shade: #2d2d3d;
  --ion-color-light-medium-tint: #474758;

  .ion-color-light-medium {
    --ion-color-base: var(--ion-color-light-medium);
    --ion-color-base-rgb: var(--ion-color-light-medium-rgb);
    --ion-color-contrast: var(--ion-color-light-medium-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-medium-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-medium-shade);
    --ion-color-tint: var(--ion-color-light-medium-tint);
  }

  --ion-color-camera: #ea0206;
  --ion-color-camera-rgb: 234, 2, 6;
  --ion-color-camera-contrast: #ffffff;
  --ion-color-camera-contrast-rgb: 255, 255, 255;
  --ion-color-camera-shade: #ce0205;
  --ion-color-camera-tint: #ec1b1f;

  ion-input {
    background-color: var(--background);
    padding-right: 15px;
  }

  ion-list,
  ion-infinite-scroll {
    background: #111;
  }

  ion-item {
    background: #1c1c1c;
  }

  --ion-color-background: #1c1c1c;

  .highcharts-background {
    // fill: #1c1c1c;
    fill: transparent;
  }
  .highcharts-grid path,
  .highcharts-axis path {
    stroke: #1c1c1c;
  }
  .indicator-card .cover,
  .rag-card .cover,
  .pulse-card .cover,
  .xyplot-card .cover {
    background-color: #111;
  }
}

/*
 * iOS Dark Theme
 * -------------------
 */

.ios body.dark {
  --ion-background-color: #111111;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #1c1c1c;
  --ion-item-background-activated: #313131;
}

/*
 * Material Design Dark Theme
 * ------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1a1b1e;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
