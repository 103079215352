.page-inspection {
  // background-color: var(--ion-background-color);
  // ion-content {max-width: 930px;}
  // // margin: auto;

  .is-locked {
    .inspection-item,
    ion-button {
      pointer-events: none;
      opacity: 0.7;
    }

    ion-list-header ion-button {
      display: none;
    }
  }

  .top-info {
    .pallets,
    .ggn,
    .gln {
      display: flex;
      gap: 4px;
      flex-direction: column;

      div {
        display: flex;
        gap: 4px;
      }
    }
  }

  .inspection-header-selector {
    border-bottom: 1px solid var(--ion-color-light-medium);
  }

  .gallery-fab {
    --border-radius: 10px;
    position: relative;

    // ion-icon {
    //   color: white;
    // }
    .img-being-uploaded {
      div {
        display: none;
      }
    }

    .c-image-sync {
      z-index: 5;

      & + img {
        // display: none;
        position: absolute;
        opacity: 0.6;
        z-index: 4;
        filter: blur(1px) brightness(90%);
      }
    }
  }

  ion-fab-button.gallery-fab {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .locked {
    pointer-events: none;
    opacity: 0.6;
  }

  .lock-message {
    background: var(--ion-color-secondary);
    color: #222;
    padding: 15px;
    position: sticky;
    top: 0;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 10;

    .p1 {
      font-size: 20px;
      font-weight: 900;
    }

    .p2 {
      font-size: 14px;
    }

    &.race-condition {
      background: var(--ion-color-danger2);
      color: white;
      animation: fadeInDown 0.3s both;

      @media (max-width: 575px) {
        font-size: 14px;
      }

      .bottom-buttons {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        // ion-button {
        //   animation-delay: .6s;
        //   animation: fadeInRight .3s both;
        // }
        .exit-button {
          &::part(native) {
            background-color: white;
            color: #111;
          }
        }
      }
    }
  }

  .loading {
    animation: fadeInUp 0.3s both;
    animation-delay: 0.3s;
    margin-top: 50px;
    text-align: center;

    ion-spinner {
      transform: scale(2);
    }
  }

  .card-inspection {
    // padding-bottom: 10px;
    .card-images {
      height: 200px;

      > * {
        width: 150px;
        min-width: 150px;
        height: auto;
      }

      @media (max-width: 575px) {
        height: 150px;

        > * {
          width: 112px;
          min-width: 112px;
        }
      }
    }

    ion-card-header > div:nth-child(2),
    .view-assessment-score {
      display: none;
    }

    .defects {
      .action-comment {
        display: none;
      }
    }
  }

  ion-card,
  .ion-card {
    border-radius: 0;
    margin: 0;
    box-shadow: none;

    .cover {
      height: 200px;

      @media (max-width: 575px) {
        height: 150px;
      }

      &:empty {
        display: none;
      }

      .card-images {
        display: flex;
      }
    }

    ion-button,
    .location {
      display: none;
    }

    ion-card-content {
      // padding-bottom: 0;
    }
  }

  .bottom-shadow {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;
    height: 120px;
    background: rgba(0, 0, 0, 0.5);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .camera-toggle-wrapper {
    left: 77px;
    bottom: 41px;
    position: fixed;
    z-index: 4;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1px;
    color: var(--ion-color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: rgba(0,0,0,.5);

    ion-toggle {
      // padding-left: 0;
      padding-bottom: 6px;
    }

    > div {
      animation: fadeInUp 0.3s both;
      background-color: var(--ion-color-light);
      border-radius: 4px;
      padding: 0 2px;
    }
  }

  .inspection-wrapper {
    padding-bottom: 450px;
    padding-top: 0;
  }

  ion-list-header + .sub-section-wrapper {
    margin-top: 3px;
  }

  .sub-section-wrapper {
    // border-left: 3px solid var(--ion-color-medium);
    position: relative;
    margin-bottom: 3px;

    &:has(.open) {
      // border-color: var(--ion-color-secondary);
      &:after // , &:before
      {
        content: ' ';
        height: 1px;
        width: 100%;
        background-color: var(--ion-color-medium);
        position: absolute;
        z-index: 1;
        animation: fadeInLeft 0.3s ease;
      }

      &:before {
        top: 0;
        left: 0;
      }

      &:after {
        bottom: 0;
        left: 0;
      }
    }
  }

  ion-list-header .section-align-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    gap: 10px;
  }

  .section-hint {
    animation: fadeIn 0.3s both;
    padding: 8px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: var(--ion-color-dark);
    color: var(--ion-color-light);

    .section-hint-title,
    .section-hint-description {
      padding: 0 8px;
    }

    .section-hint-description {
      white-space: pre-wrap;
    }

    .section-hint-title {
      font-weight: bold;
      font-size: 16px;
    }

    .section-hint-gallery {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 3px;
      flex-wrap: no-wrap;
      padding-left: 8px;
      padding-right: 8px;

      // height: 100px;
      div {
        flex: 0 0 110px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 14px;

        span {
          padding: 5px 1px;
        }
      }

      img {
        object-fit: cover;
        cursor: pointer;
        width: 110px;
        height: 150px;
      }
    }
  }

  .toggle-section {
    cursor: pointer;
    user-select: none;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid var(--ion-color-light-medium);
    background-color: var(--ion-color-light);
    margin-top: 3px;

    h2 ion-icon,
    h1 ion-icon {
      float: right;
    }

    .pic-container {
      // margin-left: auto;
      display: flex;
    }

    ion-button {
      --border-radius: 6px;
      height: 32px;

      // margin-left: auto;
      &::part(native) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .ion-label {
      display: flex;
      align-items: center;
      gap: 5px;

      h1 {
        margin: 0;
      }
    }

    .pic-indicator {
      position: relative;
      align-items: center;
      display: flex;
      align-items: center;
      gap: 5px;

      // ion-icon {
      //   color: var(--ion-color-medium);
      // }
      .pic-count {
        padding: 2px 6px;
        border-radius: 5px;
        background-color: var(--ion-color-medium);
        color: var(--ion-color-light);
        font-size: 12px;
        font-weight: bold;

        &.many {
          background-color: var(--ion-color-dark);
          color: var(--ion-color-light);
        }
      }
    }

    h1,
    h2 {
      font-weight: bold;
    }

    h1 {
      font-size: 18px;
    }

    &.sub-toggle {
      padding-left: 15px;
      margin-top: 0px;
      // margin-bottom: 0px;
      // background-color: var(--background);
      border-bottom: 1px solid var(--ion-color-light-medium);

      // text-transform: uppercase;
      ion-label h1 {
        font-size: 16px !important;
      }
    }

    ion-label {
      display: flex;
      align-items: center;
      gap: 5px;

      ion-icon {
        min-width: 24px;
      }
    }

    &.closed ~ div {
      display: none;
      content-visibility: hidden;
    }
  }
}
