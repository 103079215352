.agTable {
  width: 100%;

  & > ion-label {
    padding: 15px 0;
    font-weight: bold;
  }

  table {
    width: 100%;
    text-align: left;
    min-width: 800px;

    tr:nth-child(even) {
      background-color: var(--ion-color-light);
    }
    tr:nth-child(odd) {
      background-color: var(--ion-color-light-medium);
    }
    // tr:first-child {
    //   font-weight: bold;
    // }
    tbody {
      tr:last-child {
        font-weight: bold;
        border-top: 1px solid #aaa;
      }
    }

    thead {
      tr {
        border-bottom: 1px solid #aaa;
      }
    }

    tr {
      display: flex;
      flex-direction: row;
      gap: 20px 5px;
      padding: 3px 0;
      justify-content: space-between;

      td:nth-child(2) {
        min-width: 100px;
        overflow: hidden;
      }

      th:nth-child(2) {
        min-width: 100px;
        overflow: hidden;
      }
      td:nth-child(1) {
        min-width: 100px;
        overflow: hidden;
      }

      th:nth-child(1) {
        min-width: 100px;
        overflow: hidden;
      }

      .cell {
        width: 100%;
        padding: 0 4px;
      }
    }
  }

  #AGTableHeader {
    background-color: var(--ion-color-light);
  }
}
