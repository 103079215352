.schema-preview-wrapper {
  .schema-preview {
    height: 750px;
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    .phone-preview {
      border: 10px solid var(--ion-color-light-medium);
      border-radius: 45px;
      height: 700px;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 20px;
      width: 375px;

      .notch {
        position: sticky;
        top: 0;
        display: flex;
        justify-content: center;
        z-index: 10;
        padding-bottom: 5px;
        pointer-events: none;
        opacity: 0.95;
        background-color: var(--ion-color-light);

        .device__ios-notch {
          width: 180px;
          fill: var(--ion-color-light-medium);
        }
      }
    }
  }
}
