$padding: 7px;

.page-inspection-result {
  ion-content::part(scroll) {
    display: flex;
    @media screen and (max-width: 552px) {
      display: block;
    }
  }
  .history {
    position: relative;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    animation: fadeInLeft 0.3s both;
    position: sticky;
    top: 0;
    z-index: 5;
    background: var(--background);
    /*     max-height: calc(100vh - 200px); */
    overflow: auto;
    border-right: 1px solid var(--ion-color-light);
    padding-bottom: 15px;
    @media screen and (max-width: 552px) {
      position: relative;
      width: 100%;
      padding-bottom: 15px;
    }

    & > div {
      padding: 15px;
      border-bottom: 1px solid var(--ion-color-light);
      cursor: pointer;
      // border-right: 1px solid #eee;
      // margin-right: 15px;
      &:hover {
        background-color: var(--ion-color-light);
      }
      &.selected {
        font-weight: bold;
        background-color: var(--ion-color-light-medium);
        ion-badge {
          background-color: var(--ion-color-dark);
          color: var(--ion-color-light);
        }
      }
    }

    ion-button {
      margin-top: 15px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }

    .history-title {
      font-weight: bold;
      font-size: 18px;
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 10px 15px;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: var(--background) !important;
      ion-buttons {
        margin-left: auto;
      }
    }

    .b-bottom {
      bottom: 0;
      position: sticky;
      height: 50px;
    }

    // @media screen and (min-width: 600px){
    //   ion-button {
    //     position: absolute;
    //     z-index: 9;
    //     top: 3px;
    //     right: 22px;
    //   }
    // }
  }
  .history-loading {
    display: flex;
    justify-content: center;
    position: fixed;
    margin: 15px;
    z-index: 9;
  }

  .main-wrapper {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &.no-wrap {
      max-width: none;
      padding: 15px;
      .report-grid {
        flex-wrap: nowrap;
        flex-direction: row;
        &::before {
          display: none;
        }
        .group {
          &:nth-child(2n + 1) {
            order: 1;
          }
          &:nth-child(2n) {
            order: 1;
          }
        }
      }
    }
  }

  .not-found {
    text-align: center;
    display: block;
  }

  .report-grid {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column wrap;
    align-content: space-between;
    height: 3000px;
    gap: 5px;
    padding-right: 10px;
    @media screen and (max-width: 600px) {
      padding: 0;
      gap: 0;
    }

    &::before {
      content: '';
      flex-basis: 100%;
      // width: 0;
      order: 2;
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    strong,
    b {
      padding: 6px;
      display: block;
      font-size: 18px;
    }

    b {
      background-color: var(--ion-color-light-medium);
      border-bottom: 1px solid var(--ion-color-medium);
      margin-bottom: 2px;
      // margin-top: 15px;
    }

    .aux-prefix {
      margin-right: 10px;
      color: var(--ion-color-medium);
    }

    .aux-value {
    }

    strong {
      font-size: 22px;
      // white-space: nowrap;
      text-overflow: ellispsis;
    }

    .group {
      width: 50%;
      // &:has(.question-list-group:empty) {
      //   display: none;
      // }
      @media screen and (max-width: 600px) {
        width: 100%;
      }

      &:nth-child(2n + 1) {
        order: 1;
      }
      &:nth-child(2n) {
        order: 2;
        @media screen and (max-width: 600px) {
          order: 1;
        }
      }

      .group-name {
        display: flex;
        padding: 10px 0;
        margin-top: 20px;
        .view-assessment-score {
          font-size: 20px;
          font-weight: 700;
        }
        > div {
          flex: 1;
        }
      }

      .question-list-group {
        display: flex;
        flex-direction: column;
        &:empty:before {
          content: 'no remarks';
          padding: $padding;
          // font-style: italic;
          display: block;
          background-color: var(--ion-color-light);
          text-align: center;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }

      .question-group-wrapper {
        margin-bottom: 15px;
      }
      .question + .question-group-wrapper {
        margin-top: 15px;
      }

      .question-list {
        display: flex;
        flex-direction: column;
        // gap: 3px;

        &:empty:before {
          content: 'no remarks';
          padding: $padding;
          font-style: italic;
          display: block;
          background-color: var(--ion-color-light);
        }
      }

      .question {
        gap: 15px;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: $padding;
        font-weight: bold;
        background-color: var(--ion-color-light);

        &.clickable {
          cursor: pointer;
        }

        &:not(.multiple) {
          .answerLabel {
            display: none;
          }
        }

        .type-text {
          flex-direction: column;
          .answerLabel {
            display: none;
            & + span {
              margin-top: 10px;
              text-align: left !important;
              white-space: pre-wrap !important;
              font-weight: normal;
            }
          }
        }

        .num-of-pictures {
          ion-icon {
            font-size: 14px;
          }
          display: inline-flex;
          align-items: center;
          gap: 2px;
          font-size: 14px;
        }

        &.quantity_correct,
        &.quantity {
          .values {
            display: flex;
            flex-direction: row-reverse;
            > :last-child:after {
              content: ' of ';
              padding-right: 3px;
            }
          }

          &.score-undefined {
            display: none;
          }
        }

        .boolean {
          display: flex;
          justify-content: flex-end;
        }

        .question-name {
          display: flex;
          align-items: top center;
          gap: 10px;
        }

        &.score-1,
        .score-1 {
          background-color: var(--ion-color-danger2);
          color: white;
          // order: -3;
          .answerLabel {
            color: #ddd !important;
          }
        }
        &.score-2,
        .score-2 {
          background-color: var(--ion-color-danger);
          // order: -2;
          .answerLabel {
            color: #fff !important;
          }
        }
        &.score-3,
        .score-3 {
          background-color: var(--ion-color-warning);
          // order: -1;
        }

        .multioutput-values {
          white-space: nowrap;
          margin-left: auto;
          text-align: right;
        }

        .values {
          // white-space: nowrap;
          // min-width: 100px;
          margin-left: auto;
          text-align: right;

          // FIX THIS, SHOULDN'T BE HARDCODED
          .score-custom-BLUE {
            background-image: none;
            background-color: #3880ff !important;
            color: white !important;
          }
          .score-custom-GREY {
            background-image: none;
            background-color: var(--ion-color-medium) !important;
            // color: var(--ion-color-white)!important;
            // color: white!important;
          }
          .score-custom-BLACK {
            background-image: none;
            background-color: var(--ion-color-dark) !important;
            // color: var(--ion-color-white)!important;
            color: white !important;
          }
          .score-custom-GREEN {
            background-image: none;
            background-color: var(--ion-color-primary) !important;
            // color: white!important;
          }
          .score-custom-RED {
            background-image: none;
            background-color: var(--ion-color-danger) !important;
            // color: white!important;
          }
          .score-custom-AMBER {
            background-image: none;
            background-color: var(--ion-color-warning) !important;
            // .numerical { color: white!important; }
          }

          .score-generic {
            padding: 4px 8px;
            border-radius: 4px;
          }

          .score-4 {
            // background-color: var(--ion-color-primary);
          }
          .score-1 {
            color: white;
          }
        }

        .TEXT,
        .COMMENT {
          white-space: break-spaces !important;
          text-align: left !important;
          // display: block;
          font-weight: normal;
          width: 100%;
        }

        &.multiple:not(.quantity_correct, .quantity) {
          gap: 0;
          flex-direction: column;
          .values {
            margin-left: inherit;
            text-align: left;
            > div {
              display: flex;
              .answerLabel {
                color: var(--ion-color-medium);
                white-space: normal;
              }
              span:last-child {
                white-space: nowrap;
                text-align: right;
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }

  .report-not-available {
    text-align: center;
    width: 100%;
    .loading {
      animation: fadeInUp 0.3s both;
      animation-delay: 1s;
      margin-top: 50px;
      ion-spinner {
        transform: scale(2);
      }
    }
  }

  .top-pictures {
    position: relative;
    margin-top: 15px;
    border-radius: 8px;
    overflow: hidden;
    .card-images {
      height: 400px;
      grid-template-rows: 199px 199px;
      &.one-row {
        height: 250px;
      }
      > * {
        // width: 200px;
        width: 100%;
        min-width: 150px;
        max-width: 200px;
        height: auto;
      }
      @media (max-width: 575px) {
        height: 152px !important;
        display: flex;
        > * {
          width: 114px;
          min-width: 114px;
        }
      }
    }
    .view-all {
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 1;
      ion-chip {
        background-color: var(--ion-color-dark);
        color: var(--ion-color-light);
      }
    }
  }
  @media (min-width: 501px) {
    .assessment-info {
      border-left: 1px solid var(--ion-color-light-medium);
      padding-left: 30px;
    }
  }
  @media (max-width: 500px) {
    // .assessment-info {
    //   border-top: 1px solid var(--ion-color-light-medium);
    //   padding-top: 10px;
    // }
  }

  .top-info,
  .pallets,
  .ggn,
  .gln {
    border: 1px solid var(--ion-color-light-medium);
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 35px;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 23px;
    background-color: var(--ion-color-light);
    position: relative;
    border-radius: 8px;

    .change-layout {
      position: absolute;
      top: 10px;
      right: 10px;
      div {
        padding: 10px 15px;
        // font-size: 14px;
        background-color: var(--ion-color-medium);
        color: white;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
        &:hover {
          background-color: var(--ion-color-dark);
          color: var(--ion-color-light);
        }
      }
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .top-wrapper {
      display: flex;
      flex-direction: column;
      // gap: 35px;
      > div {
        /*         flex: 1 1 auto; */
        max-width: 180px;
      }
      .article-info {
        .article-name {
          font-size: 16px;
        }
      }
      .lot-info {
        & > :first-child {
          font-size: 20px;
          margin-bottom: 4px;
          font-weight: bold;
        }
        & > :nth-child(2) {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    @media (max-width: 600px) {
      gap: 15px;
      .top-wrapper {
        // gap: 15px;
      }
    }
  }

  .pallets,
  .ggn,
  .gln {
    padding: 15px;
    // border-top: 0;
    align-items: center;
    gap: 10px;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }
  }
}

.dark {
  .page-inspection-result {
    .score-3,
    .score-2 {
      color: #222;
    }
  }
}

@media print {
  .page-inspection-result {
    .top-pictures .card-images {
      height: auto;
      max-height: 240px;
      flex-wrap: wrap;
    }
    .group-name {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }
    .question *,
    :empty {
      font-size: 12px !important;
    }
    b {
      font-size: 14px !important;
    }
    strong {
      font-size: 18px !important;
    }
    .view-assessment-score .score {
      width: 30px;
      height: 28px;
      font-size: 14px;
    }
  }
}

.badge-clickable {
  cursor: pointer;
  display: flex;
  ion-icon {
    font-size: 12px;
  }
}
