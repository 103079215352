.swipeable-panels {
  &--wrapper {
    width: 100%;
    height: 100%;

    @media (min-width: 991px) {
      height: auto;
    }

    > div {
      height: inherit;
    }

    div > div {
      @media (min-width: 991px) {
        padding: 20px;
      }
    }
  }
}
