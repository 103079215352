.card-order {
  user-select: none;

  ion-card-content:empty {
    padding: 4px;
  }

  &.card-production {
    ion-card-content {
      margin-left: 0;
      margin-right: 0;
      padding-inline: 0;
      padding-top: 5px;
    }
  }

  &.open {
    ion-card-content {
      padding: 0;
      // @for $j from 1 to 120 {
      //   > :nth-child(#{$j}) {
      //     animation: fadeInUp .3s both;
      //     animation-delay: #{$j*30+00}ms;
      //   }

      // }
      @media screen and (min-width: 592px) {
        max-height: 380px;
        overflow-y: auto;
      }
    }
  }

  .lot-options-button {
    --width: 20px;
    --height: 20px;
    // --border-radius: 50%;
    --vertical-align: middle;
    --padding-start: 0px;
    --padding-end: 0px;
    margin-left: -8px;
  }

  .card-order-link {
    display: flex;
    padding-bottom: 12px;
    ion-card-header + ion-card-header {
      padding-right: 0;
      padding-top: 0;
      position: absolute;
      top: 0px;
      right: -5px;
    }
    ion-buttons {
      // gap: 3px;
      margin-right: 10px;
      margin-top: 2px;
    }
    .button-menu {
      margin-right: -10px;
      display: none;
    }
  }

  &.skeleton {
    .ion-card-header,
    .ion-card-title,
    .ion-card-content {
      border: 0 !important;
    }
    ion-card-header {
      ion-skeleton-text {
        // animation-delay: .5s;
      }
    }
  }

  .arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    margin: 10px;
    animation: fadeInDown 0.3s both;
  }

  .location {
    width: 100%;
    padding: 5px 15px;
    color: var(--ion-color-dark) !important;
    background: var(--ion-color-light);
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .quantity {
    ion-badge {
      margin-bottom: -8px;
      border-radius: 0;
      margin-left: -3px;
      margin-right: -3px;
      font-size: 11px;
      margin-top: 5px;
      word-wrap: wrap;
    }
    b {
      color: var(--ion-color-dark) !important;
      font-weight: 900;
      font-size: 15px;
      display: block;
    }
    div {
      color: var(--ion-color-dark) !important;
      font-size: 11px;
    }
  }

  .reason {
    // width: 100%;
    // text-align: center;
    display: flex;
    text-align: center;
    align-items: center;
    margin: 15px 0;
    justify-content: center;
    gap: 15px;
    // .waste-container {
    //   position: relative;
    //   width: fit-content;
    //   display: flex;
    // }
  }

  .waste-container {
    overflow: auto;
    z-index: 10;

    > ion-card {
      z-index: 10;
    }
  }

  .waste {
    display: flex;
    align-items: center;
    gap: 15px;

    ion-badge {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 8px;
    }

    b {
      color: var(--ion-color-dark) !important;
      font-weight: 900;
      font-size: 12px;
      display: block;
    }
    div {
      color: var(--ion-color-light) !important;
      font-size: 11px;
      font-weight: 900;
    }
  }

  .view-article-description {
    ion-badge {
      max-width: 178px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .report-type-badge {
    text-transform: uppercase;
  }

  .sublots-divider {
    font-weight: bold;
    vertical-align: middle;
    font-size: 18px;
    color: var(--ion-color-dark);
    padding: 0 15px 5px 15px;
  }

  &.completed {
    border: 3px solid var(--ion-color-primary);
    background-color: rgba(151, 209, 118, 0.05);

    &.score-3 {
      border-color: var(--ion-color-warning);
      background-color: rgba(255, 204, 104, 0.1);
    }
    &.score-2 {
      border-color: var(--ion-color-danger);
      background-color: rgba(250, 147, 122, 0.1);
    }
    &.score-1 {
      border-color: var(--ion-color-dark);
      // border: none;
      background-color: rgb(180, 2, 6, 0.8);
      *:not(ion-badge) {
        color: white;
      }
      ion-icon {
        --ion-color-base: white !important;
      }
      ion-item {
        border-color: #990106;
      }
    }

    ion-item {
      --ion-item-background: transparent;
      --ion-color-background: transparent;
      background: transparent;
      border-color: rgba(0, 0, 0, 0.1);
    }

    .order-summary {
      margin-top: 15px;
    }

    .order-done-badge {
      display: inline-flex;
      text-transform: uppercase;
      align-items: center;
      gap: 3px;
      padding: 3px 5px;
      // color: var(--ion-color-dark);
      ion-icon {
        // color: var(--ion-color-dark);
        font-size: 20px;
      }
    }
  }

  &.qc-status-REDO {
    border: 3px solid var(--ion-color-danger);
    border-style: dashed;
  }

  // border: 3px solid transparent;

  ion-card-header {
    padding-bottom: 0;
  }

  .contact {
    margin-top: 4px;
    margin-bottom: 4px;
    // margin-right: 10px;
    font-size: 16px;
    // border-bottom: 1px solid var(--ion-color-light);
    // font-weight: normal;
    img {
      height: 30px;
      width: auto;
      filter: grayscale(100%);
      margin: 0 auto;
    }
    span {
      display: block;
      font-size: 15px;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: pre;
      overflow: hidden;
    }
  }

  .status {
    margin-top: 5px;
    font-size: 13px;
  }

  .status.rejected {
    background-color: var(--ion-color-danger2);
    color: white;
  }

  .status.in-progress {
    background-color: var(--ion-color-secondary);
    color: #222;
  }

  .status.accepted-upon-reservation,
  .status.accepted-not-full-price {
    background-color: var(--ion-color-warning);
    color: #222;
  }

  ion-title {
    font-size: 20px;
  }

  .order-info {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    align-items: center;
  }

  .card-order-report-type {
    margin-top: 5px;
    margin-bottom: 0;
    display: block;
    ion-badge:hover {
      /*       text-decoration: underline; */
    }
  }

  .order-id {
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 900;

    &.linked-id {
      font-size: 16px;
      margin-top: -2px;
    }

    .highlight {
      background-color: var(--ion-color-secondary);
      color: black !important;
      padding: 0 3px 0 0;
      border-radius: 3px;
    }

    .order-icon {
      font-size: 20px;
    }
    ion-icon {
      font-size: 20px;
      width: 20px;
      margin-bottom: 1px;
    }
    .report-icon {
      font-size: 20px;
      margin-bottom: 3px;
    }
  }

  .upfront-information-badge {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    // color: black;
    padding: 3px 5px;
    ion-icon {
      font-size: 20px;
    }
  }

  ion-icon.caret {
    position: absolute;
    right: 0;
    margin-top: -10px;
    margin-right: -10px;
    padding: 10px;
    z-index: 6;
    cursor: pointer;
    // background-color: white;
  }

  ion-list {
    --background: none;
  }

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --background: none;
    border-top: 1px solid var(--ion-color-light);
    padding: 0;

    ion-grid {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.highlight {
      background: rgb(255, 204, 104, 0.5);
      padding: 5px;
      margin-left: -5px;
      margin-right: -5px;
      // background: linear-gradient(0deg, rgba(255,204,104,.4) 30%, rgba(255,255,255,0) 100%);
    }

    .lot-info {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    .contact-lot-info {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 0.9em;
    }

    .col-scoring {
      // max-width: 65px;
      text-align: right;
      padding-inline-end: unset;
      font-weight: 300;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 5px;
      ion-badge {
        // width: 100%;
        min-width: 55px;
        padding: 6px 4px;
        // opacity: .8;
      }
    }

    ion-row {
      flex-wrap: nowrap;
    }

    .col-name {
      font-weight: bold;
      line-height: 20px;
      padding-inline-end: 5px;
      flex: 2;
      // div {
      //   display: block;
      //   font-size: 12px;
      //   font-weight: normal;
      // }
      .article-name {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  .share-button,
  .create-report-button,
  .redo-button {
    display: none !important;
  }

  .upfront-alert {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    color: black;
    border-radius: 0;
  }

  .lot-actions {
    margin-left: 0px;
  }
}
