.shared-history {
  ion-item + div {
    border-top: 2px solid var(--ion-color-medium);
  }
  .shared-item {
    padding: 10px 15px;
    border-bottom: 1px solid var(--ion-color-light-medium);
    & > div {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 5px;
      &.align-top {
        align-items: flex-start;
      }
    }
  }

  ion-icon {
    min-width: 24px;
  }

  .shared-to {
    --min-height: 30px;
  }

  .message {
    font-size: 14px;
    border: 1px solid var(--ion-color-light-medium);
    // color: var(--ion-color-medium);
    padding: 10px;
    margin: 5px 0 15px !important;
    border-radius: 4px;
  }

  .link-to-report {
    background-color: var(--ion-color-primary);
    color: rgba(0, 0, 0, 0.7);
    gap: 5px;
    text-decoration: none;
    border-radius: 3px;
    padding: 2px 6px 2px 2px;
    font-weight: bold;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    // text-transform: uppercase;
    ion-icon {
      font-size: 18px;
    }
  }

  ion-item {
    // margin-top: 5px;
    --min-height: 40px;
  }
}
