.page-pwa-enforcing-content {
  .main-wrapper {
    padding: 10px;
  }

  .logo-wrapper {
    justify-content: center;
    display: flex;
    padding-top: 10px;
  }
}
