.modal-select-pictures-import {
  .message {
    padding: 15px;
    text-align: center;
    background-color: var(--ion-color-light);
    position: sticky;
    top: 0;
    z-index: 2;
    ion-badge {
      vertical-align: bottom;
    }
  }

  ion-title {
    padding-inline: inherit;
  }

  .upload-button {
    bottom: 15px;
    right: 15px;
  }

  .lot-wrapper {
    border-bottom: 1px solid var(--ion-color-light);
    .card-images.emptyPicture {
      &:after {
        content: 'no images';
      }
    }
    .card-images {
      // height: inherit;
      // flex-wrap: wrap;
    }
    .lot-id {
      font-weight: bold;
      padding: 10px 0 10px 5px;
      span {
        margin-left: 10px;
        font-weight: normal;
        color: var(--ion-color-tertiary);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
