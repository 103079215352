.c-input {
  display: inline-flex;
  &:not(.has-label):not(.c-input-verification) {
    max-width: 150px;
  }
  .actual-input {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ion-button {
      margin-right: 5px;
      height: 36px;
    }
  }
  .negate-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ion-button {
      height: 36px;
      margin-right: 5px;
    }
  }
  &:not(.c-input-verification):not(.boolean) .actual-input {
    max-width: 140px;
    min-width: 140px;
  }

  &.boolean {
    justify-content: flex-end;
    .label {
      white-space: nowrap;
    }
  }

  &.c-input-verification {
    min-width: 240px;
    max-width: 240px;
    input {
      max-width: 80px;
    }
  }

  .label {
    margin-right: 10px;
  }

  input {
    background: var(--ion-color-light);
    border-radius: 8px;
    border: 0;
    text-align: right;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px 10px 10px 5px;
    position: relative;
    outline: none;
    max-width: 100px;

    .native-input[disabled] {
      opacity: 1;
      cursor: not-allowed;
    }

    & + .unit {
      height: 41px;
      padding: 0 10px;
      background-color: var(--ion-color-light-medium);
      // color: var(--ion-color-light);
      display: flex;
      align-items: center;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      // font-family: Monaco, monospace;
      font-weight: bold;
      // font-size: 14px;
      position: relative;
      align-items: center;
      justify-content: center;
      &:not(.long) {
        width: 40px;
      }
    }

    &:not([disabled='true']) {
      &.focus,
      &:hover {
        outline: 2px solid var(--ion-color-tertiary);
        &:after,
        & + .unit:after {
          content: '';
          position: absolute;
          top: -2px; // border: 3px + offset: 1px
          right: -2px; // border: 3px + offset: 1px
          bottom: -2px; // border: 3px + offset: 1px
          left: -2px; // border: 3px + offset: 1px
          border: 2px solid var(--ion-color-tertiary);
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 10px; // border—radius: 6px + offset: 1px
          border-bottom-left-radius: 10px; // border—radius: 6px + offset: 1px
          // animation: fadeIn .3s both;
        }
        & + .unit {
          // outline: 2px solid var(--ion-color-tertiary);
          &:after {
            border-left: 0;
            border-right-width: 2px;
            border-radius: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
}
