.page-contact {
  .user-invite-button {
    display: flex;
    justify-content: center;
  }

  .container-orders {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(276px, 1fr));
    padding: 15px;
    gap: 15px;
    align-items: flex-start;
    margin-bottom: 150px;

    ion-card {
      margin: 0;
    }
  }

  ion-item-divider {
    z-index: 9;
  }

  .select-supplier {
    margin: 10px;
    z-index: 10;
    &.z-11 {
      z-index: 11;
    }
    &.z-12 {
      z-index: 12;
    }
    > div {
      min-width: 200px;
      input {
        width: auto !important;
      }
    }
  }

  // ion-item {
  //   border-bottom: 1px solid var(--ion-color-light);
  //   --border-color: var(--ion-color-light);
  // }

  .card-order .contact {
    // display: none;
    & + ion-item {
      margin-top: 8px;
    }
  }

  ion-content ion-header {
    ion-toolbar {
      padding: 10px 0;
    }
    ion-title {
      display: none;
    }

    .partner-logo {
      // padding-left: var(--padding-start);
      // text-align: center;
      margin: 0 15px;
      img {
        height: 80px;
        object-fit: contain;
        object-position: middle;
        margin-bottom: -4px;
      }
    }

    .partner-score {
      position: absolute;
      display: none;
      top: 10px;
      right: 10px;
      width: 50px;

      .number {
        position: absolute;
        top: 15px;
        left: 20px;
        font-weight: bold;
        color: black;
      }
    }
  }

  .card-insight {
    width: 100%;
    --color: black;
    min-height: 100px;
    ion-card-subtitle {
      color: black !important;
    }
  }

  .card-certification {
    width: 100%;
    --color: black;

    ion-card-subtitle {
      color: black !important;
    }

    img {
      height: 60px;
      object-fit: contain;
      object-position: center;
    }
  }

  .avatar {
    width: 45px;
    height: 45px;
    .render-user {
      color: #222;
    }
  }

  .card-product {
    width: 100%;
    height: 100px;
    position: relative;

    ion-card-subtitle {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      width: 100px;
      margin-left: -50px;
      color: white;
    }

    img {
      height: 100px;
      width: 100%;
      object-fit: cover;
    }
  }

  .maps {
    width: 100%;
    height: 150px;
  }
}

.page-contact.modal-view {
  .user-invite-button,
  .users-divider,
  .users-item,
  ion-back-button {
    display: none;
  }

  .no-contacts {
    ion-card {
      display: none;
    }
  }
}
