// --------------------------------
// print
// --------------------------------
.print-mode {
  opacity: 0;
}

.print-only {
  display: none !important;
}

body {
  @media print {
    -webkit-print-color-adjust: exact !important;
    position: relative !important;
    max-height: unset !important;
    overflow: scroll !important;

    .highcharts-reset-zoom {
      display: none;
    }

    .print-only {
      display: inherit !important;
    }

    .print-h1 {
      font-weight: 900;
      padding: 30px;
    }

    // *[display="flex"] {
    // 	display: block!important;
    // }

    .page-inspection-result {
      width: 100%;
      .card-images {
        // display: none;
        // width: 100%;
        // display:flex!important;
        // gap:0;
        // flex-wrap:wrap;
        // height: auto;
        grid-template-rows: 119px 119px !important;
        height: auto !important;
        img,
        ion-img {
          width: 120px;
          height: 120px;
          object-fit: cover;
          overflow: hidden;
        }
      }
      .assessment-info {
        padding: 0;
        border: 0;
      }
    }

    .page-insights-new {
      * {
        animation: none !important;
      }
      *,
      ::part(scroll) {
        -webkit-scrollbar {
          display: none;
        }
        overflow: visible;
      }
      overflow: visible;
      min-height: 100vh;

      h1 {
        display: none !important;
      }

      .insights-top {
        flex-wrap: wrap;
      }

      .insights-wrapper {
        // display: block!important;
        // > div {
        // 	display: flex;
        // 	flex-direction: column;
        // 	justify-content: center;
        // }
      }

      .rank-wrapper {
        break-before: always;
      }

      [data-flipper-id] {
        animation: none;
        transform: none;
        display: block;
      }

      .xyplot-card,
      .pulse-card,
      .rag-card,
      ion-card {
        -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
        page-break-inside: avoid; /* Firefox */
        break-inside: avoid; /* IE 10+ */
        // break-before: always;
        animation: none;
        transform: none;
        // margin-left: auto;
        // margin-right: auto;
        .arrows {
          display: none;
        }
        // width: 500px;
        // max-width: 100%;
        // height: 534px;

        // &:nth-child(1) {
        // break-after: always;
        // width: 100%;
        // max-width: 900px;
        // }
      }
    }

    .page-report,
    .page-order {
      *,
      ::part(scroll) {
        -webkit-scrollbar {
          display: none;
        }
        overflow: visible;
      }
      overflow: visible;
      max-width: 100%;
      min-height: 100vh;
      .card-inspection {
        display: flex;
        flex-direction: column-reverse;
        ion-card-header {
          padding: 0;
          > div:nth-child(2) {
            flex-direction: row-reverse;
            gap: 15px;
            align-items: center;
          }
          .view-assessment-score {
            margin: 0;
          }
          .quantity {
            flex-direction: row;
            gap: 5px;
          }
        }
        ion-card-content {
          padding: 5px 0;
        }
        .location {
          padding: 5px 0;
          color: black !important;
          background: none;
          opacity: 1;
        }

        .card-images {
          // display: none;
          display: flex !important;
          gap: 0;
          flex-wrap: wrap;
          height: auto;
          img,
          ion-img {
            width: 120px;
            height: 120px;
            object-fit: cover;
            overflow: hidden;
          }
        }
        .cover {
          background: none;
          order: -1;
        }
      }
      .view-assessment-status {
        display: none !important;
      }
      .container-reports {
        display: block;
        min-height: 100%;
        ion-card,
        .ion-card {
          box-shadow: none;
          margin: 0;
          margin-bottom: 10px;
          padding: 0;
          padding-bottom: 10px;
          border-radius: 0;
          border-bottom: 1px solid var(--ion-color-light-medium);
          .user {
            max-width: none;
          }
        }
      }

      ion-card,
      .ion-card,
      .agTable,
      ion-list-header,
      img {
        break-inside: avoid;
      }
    }
    .no-print,
    .no-print * {
      display: none !important;
    }

    .print-mode {
      opacity: 1;
      z-index: 2147483647;
      background: white;
      // overflow: auto!important;
      // height: 100%!important;
    }

    ion-loading,
    .loading-container {
      display: none;
    }

    ion-page {
      display: block; /* Not really needed in all cases */
      position: relative !important;
      width: auto !important;
      height: auto !important;
      overflow: visible !important;
    }
  }
  // ---------------------------
}
