.view-qc-tabs-selector {
  padding-top: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .view-qc-tabs-selector_checkbox-group {
    margin-right: 5vw;
    margin-bottom: 10px;
    .view-qc-tabs-selector_checkbox-label {
      //overflow-wrap: anywhere;
    }

    @media only screen and (max-width: 600px) {
      .view-qc-tabs-selector_checkbox-label {
        inline-size: 80vw;
        //overflow-wrap: anywhere;
      }
    }
    .view-qc-tabs-selector_checkbox-item {
      min-width: 300px;
    }
  }

  .view-qc-tabs-selector__default-tab {
    min-width: 200px;
    margin-bottom: 150px;
  }
}
