.onboarding {
  &--content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #f8f8f8;
  }
}
