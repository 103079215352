.view-subscription-selector {
  .subscription-status {
    padding: 0;
    // padding-top: 10px;
    .subscription-status-label {
      //z-index: 1 !important;
      min-width: 150px !important;
    }
    .subscription-status-checkbox {
      //z-index: 1 !important;
    }
  }
}
