$color_1: white;

// ion-modal .modal-wrapper {
// 	background: black;
// 	--background: black;
// }

.page-picture {
  background: black;
  .img-being-uploaded {
    color: white;
    width: 100%;
  }
  ion-toolbar {
    --background: black;
  }
  ion-content {
    --background: black;
  }
  .toggle {
    padding-right: 5px;
  }
  .wrapper {
    // padding-bottom: 300px!important;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .ocr-view {
    position: absolute;
    animation: fadeInUp 0.3s both;
    top: 0px;
    width: 100%;
    ion-list {
      margin: 20px;
      border-radius: 5px;
    }
  }

  .image-area {
    position: relative;
    // padding-bottom: 445px;
    height: 100%;
    width: 100%;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .defect-list {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin: 5px;
      gap: 3px;
    }

    .no-editable {
      & > img {
        margin: auto;
      }
    }

    & > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.multi {
      margin-bottom: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 1px;
      column-gap: 1px;
      grid-auto-rows: 0.25fr;
      // grid-auto-rows: 115px;
      // @media (min-width: 374px) {
      // 	grid-auto-rows: 120px;
      // }
      // @media (min-width: 575px) {
      // 	grid-auto-rows: 1800px;
      // }

      &.p2 {
        grid-template-columns: 1fr 1fr;
      }
      &.p1 {
        grid-template-columns: 1fr;
      }
    }
  }

  .image-meta {
    padding: 6px 0 3px 0;
    margin-bottom: 0px;
    // margin-top: 250px;
    position: absolute;
    bottom: 15px;
    right: 0;
    transition: opacity 0.3s;

    &.requestingOCR {
      pointer-events: none;
      // opacity: 1;
    }

    @media (max-width: 340px) {
      margin-left: 0;
      margin-right: 0;
      border-radius: 0;
      top: 50px;
      bottom: auto;
    }
  }

  .editable {
    .image-area {
      align-items: flex-start;
      position: relative;
    }
    .image-meta {
    }
  }

  .multitag {
    .image-meta {
      margin-top: 200px;
    }
  }

  .back-button {
    color: white;
    position: fixed;
    opacity: 0.7;
    left: 0;
    top: 0;
    padding: 130px 130px 130px 10px;
    // border: 1px solid white;
    ion-icon {
      font-size: 40px;
    }
  }
  .next-button {
    color: white;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0.7;
    padding: 130px 10px 130px 130px;
    // border: 1px solid black;
    ion-icon {
      font-size: 40px;
    }
  }

  .no-editable {
    .next-button {
      padding: 0;
      padding-right: 10px;
      padding-left: 120px;
      top: 0;
      bottom: 0;
      display: flex;
      align-content: center;
      align-items: center;
    }
    .back-button {
      padding: 0;
      padding-left: 10px;
      padding-right: 120px;
      top: 0;
      bottom: 0;
      display: flex;
      align-content: center;
      align-items: center;
    }
  }

  .close-button {
    position: fixed;
    z-index: 1;
    opacity: 0.7;
    --border-radius: 50px;
    --padding-start: 10px;
    --padding-end: 10px;
    right: 5px;
  }

  .minimize-button {
  }

  .bottom-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0 4px;
    margin-top: 5px;
    gap: 4px;
    ion-button {
      // flex: 1;
      text-transform: uppercase;
      font-weight: bold;
      display: flex;
      gap: 4px;
      div {
        padding-top: 3px;
      }
      &:first-child {
        order: 2;
      }
      &:nth-child(2) {
        // &::part(native) {border: 0;}
      }
    }
  }

  .image-tags {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;

    &.edit-mode-report {
      display: none;
    }

    .chip-list {
      margin: 0px 5px 5px 5px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px;
      flex: 1;
      .ion-chip {
        padding: 10px 10px;
        cursor: pointer;
        min-width: 120px;
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 14px;
        line-height: 1.4em;
        border-radius: 10px;
        text-transform: uppercase;
        line-height: 16px;
        font-weight: bold;
        border: 2px solid var(--ion-color-light);
        // text-align: right;
        ion-label {
          width: 100%;
        }
        &.selected {
          border: 2px solid var(--ion-color-tertiary);
          background-color: var(--ion-color-light);
        }
        &.ocr-button {
          background-color: #abdd7e;
          color: white;
          padding: 5px 10px 5px 5px;
          b {
            font-weight: 900;
            letter-spacing: 1px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .view-defect-node {
    display: grid;
    max-height: 520px;
    overflow-y: scroll;

    .level--1 {
      order: -1;
      &:first-child {
        border-top: 1px solid var(--ion-color-light);
      }
    }

    ion-toggle {
      padding-right: 5px;
    }

    ion-item {
      --padding-start: 10px;

      ion-label {
        font-size: 16px;
        white-space: normal;
        margin-top: 8px;
        margin-right: 8px;
      }
      ion-toggle {
        padding-left: 5px;
        padding-right: 0px;
        margin-right: 0;
      }
    }

    ion-item {
      --padding-top: 0px;
      --padding-bottom: 0px;
      --min-height: 20px;
    }

    ion-list-header {
      border-bottom: 1px solid var(--ion-color-light);
      cursor: pointer;
      &:first-child {
        // border-top: 1px solid var(--ion-color-light);
      }
      ion-label {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    h1 {
      font-size: 17px;
      font-weight: bold;
      margin: 0;
      padding-right: 20px;
    }

    h2 {
      font-weight: bold;
    }
  }
}
