// how to style firebaseui component for LOGIN flow
// https://github.com/firebase/firebaseui-web-react/blob/master/example/src/firebaseui-styling.global.css

.page-login {
  // animation: fadeIn .5s both;
  // background-color: #eee;
  // color: #222!important;
  ion-content {
    // --ion-color-background: white;

    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    transform: scale(2);

    ion-spinner {
      animation: fadeInUp 0.7s both;
      animation-delay: 0.3s;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  .cover-area-wrapper {
    background-color: lighten(#abdd7e, 10%);
    opacity: 1;
    // transition: background-color .5s ease-in;
    transition: opacity 0.5s ease-in;
    // animation: fadeIn .5s both;
  }

  &.loading .cover-area-wrapper,
  &.loading-success .cover-area-wrapper {
    // background-color: var(--background);
    opacity: 0;
  }

  .cover-area {
    height: calc(100vh - 500px);
    // height: 300px;
    max-height: 600px;
    position: relative;
    overflow: visible;
    // background: url('./assets/img/bg-green-leaves.webp') repeat;
    background-size: contain;
    filter: brightness(90%);
    transition: height 0.3s;

    @media screen and (max-width: 768px) {
      height: 300px;
    }

    &.keyboard-open {
      height: 150px !important;
    }
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    margin-top: -60px;

    &.loading {
    }
  }

  .main-area {
    margin-top: 70px;
    // height: 360px;
    width: 100%;

    #firebaseui_container .firebaseui-card-actions {
      animation: fadeInUp 0.5s both;
    }

    > h1 {
      font-size: 30px;
      animation: fadeInUp 0.5s both;
      animation-delay: 0.1s;
      // animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      margin: 0;
      font-weight: 900;
      letter-spacing: 2px;
      text-transform: uppercase;
      display: none;
    }
  }

  &.zoom-out-logos {
    .logo-wrapper .brand-logo {
      animation: zoomOut 0.7s both;
    }

    .loading-container ion-spinner {
      animation: zoomOut 0.7s both !important;
      animation-delay: 0.1s !important;
    }

    h1 {
      animation: zoomOut 0.4s both !important;
      animation-delay: 0.1s !important;
    }
  }

  ion-button {
    margin-top: 10px;
    width: 100%;
  }

  // &.loading {
  //     h1 {
  //         letter-spacing: 20px;
  //     }
  // }

  ion-icon {
    margin-right: 10px;
  }

  .google {
    --background: var(--google);
  }

  .facebook {
    --background: var(--facebook);
  }

  .input-label {
    margin-bottom: 10px;
  }

  .firebaseui-card-header {
    border: 0;

    h1 {
      text-align: center;
    }
  }

  .firebaseui-id-page-sign-in .mdl-button--primary {
    color: var(--ion-color-primary) !important;
  }

  .firebaseui-id-page-sign-in .mdl-button--raised {
    background: var(--ion-color-primary) !important;
  }

  .firegbaseui-textifeld .is-focused {
    border-bottom: 4px var(--ion-color-primary) !important;
  }

  .firebaseui-idp-button[data-provider-id='password'] {
    background: var(--ion-color-primary) !important;
  }

  .firebaseui-container {
    box-shadow: none !important;
  }

  .mdl-textfield__label:after {
    background: var(--ion-color-primary) !important;
  }

  .firebaseui-error {
    // color: var(--ion-color-primary) !important;
    font-size: 14px;
  }

  .firebaseui-input-invalid {
    // border-color: var(--ion-color-primary) !important;
  }

  .mdl-button--raised.mdl-button--colored {
    background: var(--ion-color-primary) !important;
  }

  .mdl-button--primary {
    // color: var(--ion-color-primary) !important;
  }

  .mdl-progress > .bufferbar {
    background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0.7),
        hsla(0, 0%, 100%, 0.7)
      ),
      linear-gradient(90deg, var(--ion-color-primary), var(--ion-color-primary)) !important;
  }

  .mdl-progress > .progressbar {
    background-color: var(--ion-color-primary) !important;
  }

  .firebaseui-link {
    color: var(--ion-color-primary) !important;
  }
}

.firebaseui-container {
  background-color: transparent !important;
  margin-bottom: 15px;
  min-height: 150px;
  padding-top: 10px;
  border-radius: 20px;
  box-shadow: none;

  input,
  input::placeholder {
    color: var(--ion-color-dark);
    border-bottom: 1px solid var(--ion-color-light-medium) !important;
  }

  label {
    color: var(--ion-color-medium) !important;
  }
}

.firebaseui-container.firebaseui-page-provider-sign-in {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}

.firebaseui-busy-indicator.mdl-progress {
  top: 56px;
  // width: 90%;
}

.firebaseui-container.firebaseui-id-page-callback {
  background: transparent;
  box-shadow: none;
  margin-top: 40px;
  height: 84px;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}

.firebaseui-card-header {
  display: none;
}

.firebaseui-subtitle,
.firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: rgba(0, 0, 0, 0.1);
}

.firebaseui-id-dismiss-info-bar {
  display: block;
}

.firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
}

.firebaseui-label {
  padding-left: 5px;
  // margin-bottom: 5px!important;
}

input.firebaseui-input {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  padding-left: 5px;
}
