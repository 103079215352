.page-inbox {
  // ion-content {
  ion-header {
    ion-title {
      animation: fadeInUp 0.3s both;
    }
  }
  // }

  .ios {
    .search-button {
      display: none;
    }
  }

  .archived-top-bar {
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    // background-color: var(--ion-color-light);
    color: var(--ion-color-dark);
    user-select: none;
    &:hover {
      background-color: var(--ion-color-light);
    }
  }

  .unread-icon {
    font-size: 16px;
  }

  .conversation-info {
    display: flex;
    gap: 10px;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      gap: 2px;
      background-color: var(--ion-color-light);
      padding: 2px;
      border-radius: 4px;
      ion-icon {
        font-size: 18px;
      }
    }
    p {
      color: var(--ion-color-medium);
    }
  }

  .count {
    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
    font-size: 18px;
    margin-left: 110px;
    border-radius: 15px;
    padding: 3px 10px;
    animation: fadeInRight;
    animation-duration: 0.3s;
    // animation-delay: .3s;
    animation-fill-mode: both;
    display: inline-block;
  }

  ion-content {
    ion-toolbar {
      ion-searchbar {
        padding-bottom: 0;
      }
    }
  }

  ion-list {
    margin-bottom: 100px;
    ion-item {
      // animation: fadeIn .3s both;
      // --padding-start: 10px;
      // animation-delay: .5s;
      --border-color: var(--ion-color-light);
    }
    // --ion-background-color: white;
    ion-label {
      // min-height: 60px;
    }
  }

  ion-avatar {
    // font-size: 64px;
    // color: #ccc;
    color: var(--ion-color-light-medium);
    width: 48px;
    height: 54px;
    ion-icon {
      font-size: 54px;
    }
    margin-right: 15px;
  }

  p.last-message {
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 10px;
    font-size: 16px;

    &.unread {
      font-weight: bold;
    }

    &:empty {
      &:before {
        content: 'shared a file';
      }
    }
  }

  ion-item::part(native) {
    padding-left: 10px;
  }

  h3 {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    ion-icon,
    div {
      color: var(--ion-color-tertiary);
    }
  }

  ion-fab-button {
    animation: fadeInRight 0.3s both;

    // none of this works to change the svg color

    // color: white;
    // ion-icon::part(native) {
    //   path {
    //     fill: var(ion-color-primary-contrast)
    //  }
    // }
  }
}
