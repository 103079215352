.location-properties-wrapper {
  padding: 15px;
  width: 100%;
  background-color: var(--ion-color-light);
  color: var(--ion-color-dark);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.41);

  .location-properties-secondary-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .location-properties {
      max-width: 100%;
      flex-shrink: 0;
      .property-name {
        font-size: 16px;
        font-weight: bold;
        color: var(--ion-color-dark);
        margin-bottom: 5px;
        display: flex;
        gap: 4px;
      }
      .properties-badges {
        display: flex;
        gap: 4px;
        line-height: 0;
        flex-wrap: wrap;
        ion-badge {
          max-width: 150px;
          // min-width: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .shared-info {
      ion-card {
        flex-grow: 1;
      }
      ion-button {
        position: absolute;
        top: 4px;
        right: 4px;
      }
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}
