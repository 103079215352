.render-user {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: var(--ion-color-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  .initials {
    text-align: center;
    font-size: 12px;
  }
}
