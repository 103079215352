.page-create-claim {
  .react-select__placeholder,
  .react-select__single-value {
    font-size: 14px;
    text-align: right;
  }
  .react-select__control {
    min-width: 250px;
    margin: auto;
    z-index: 1000;
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
    z-index: 1000;
  }
  .react-select__option {
    color: var(--ion-color-dark);
    z-index: 1000;
  }
  .react-select__option--is-active {
    background-color: var(--ion-color-tertiary);
    color: white;
    z-index: 1000;
  }
  .react-select__option--is-selected {
    background-color: transparent !important;
    color: var(--ion-color-dark);
    font-weight: bold;
    z-index: 1000;
  }
  .react-select__option--is-focused {
    background-color: var(--ion-color-tertiary) !important;
    color: white;
    z-index: 1000;
  }
}
