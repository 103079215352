.modal-camera {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  animation: fadeIn 0.3s both;
}
.modal-camera-gallery {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.page-camera {
  background: black;
  @media screen and (orientation: landscape) and (max-width: 915px) {
    .recor2d-button {
      position: fixed;
      right: 0;
      top: 0;
      margin-top: 34vh;
      margin-right: 7px;
      opacity: 0.9;
    }
  }
  .camera-dark-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000000;
  }

  .header-md::after {
    display: none;
  }
  ion-toolbar {
    --background: none;
    --border-width: 0 !important;
    border-bottom: 0;
    .close-button {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 20px;
    }
  }
  ion-content {
    --background: black;
  }
  .close-button-top {
    position: fixed;
    z-index: 1;
    opacity: 0.7;
    --border-radius: 50px;
    --padding-start: 10px;
    --padding-end: 10px;
    right: 5px;
  }

  .ocr-toggle {
    top: 15px;
    display: none;
  }

  .ocr-view {
    position: absolute;
    animation: fadeInUp 0.3s both;
    top: 70px;
    width: 100%;
    ion-list {
      margin: 20px;
      border-radius: 5px;
    }
  }

  .auto-tag-chip {
    text-transform: uppercase;
    text-align: center;
    background-color: var(--ion-color-white);
    color: var(--ion-color-black);
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 4px;
  }
  ion-fab.fab-vertical-bottom {
    position: fixed;
  }
  .camera-wrapper {
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // @media (max-width: 468px) {
    // 	width: 100%;
    // 	height: 80vh;
    // }
  }
  .camera-video {
    position: relative;
    flex: 1;
    overflow: hidden;
    background-color: black;
    display: flex;
    align-items: center;

    :not(.scandit) video {
      // transform: scaleX(-1);
    }

    // &.mode-camera {
    // 	> div {
    // 		position: relative!important;
    // 		> div {
    // 			position: relative!important;
    // 			video {
    // 				object-fit: contain!important;
    // 			}
    // 		}
    // 	}
    // }

    > div {
      // top: 0;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // position: absolute;

      .scandit.scandit-barcode-picker {
        max-height: none !important;
        max-width: none !important;
        // transform: scaleX(-1);
        animation: fadeIn 0.3s both;
      }

      // .scandit-logo {
      // 	display: none;
      // }

      .scandit video {
        object-fit: cover !important;
        width: 100%;
      }
    }
  }
  video {
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    object-fit: cover;
    background-color: black;
    animation: fadeIn 0.3s both;
  }
  .shutter-overlay {
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
  }

  offscreen-image-render {
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  ion-fab-button.record-button {
    --background: white;
    border-radius: 100px;
    display: inline-block;
  }

  ion-fab.vision {
    --padding-bottom: 10px;
    right: 54px;
    // --background: white;
    // border-radius: 24px;
  }

  ion-fab-button.close-button {
    border-radius: 24px;
    --background: rgba(0, 0, 0, 0.3);
    --background-activated: none;
    --background-focused: none;
    --background-hover: none;
  }

  ion-fab-button.inline-edit-enabled {
    display: inline-block;
  }

  .barcode-container {
    // animation: fadeInUp;
    // animation-duration: .5s;

    z-index: 9999;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    flex-direction: column;
    gap: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &.clickable {
      pointer-events: all;
    }

    .message {
      animation: fadeInRight;
      animation-duration: 0.3s;
      span {
        background-color: var(--ion-color-tertiary);
        padding: 5px 15px;
        border-radius: 20px;
      }
      &.code-13,
      &.code-8 {
        animation: shakeX;
        animation-duration: 1s;
        span {
          background-color: var(--ion-color-primary);
        }
      }
      // not found
      &.code-9,
      &.code-12 {
        animation: flash;
        animation-duration: 1s;
        span {
          background-color: var(--ion-color-danger2);
          color: white;
        }
      }
    }

    .rectangle {
      width: 85%;
      height: 125px;
      // border: 1px solid black;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      animation: fadeInLeft;
      animation-duration: 0.3s;
    }

    .buttons {
      position: absolute;
      top: 80px;
      gap: 5px;
      display: flex;
      ion-button {
        animation: fadeInDown;
        animation-duration: 0.3s;
        animation-fill-mode: both;

        &:nth-child(2) {
          animation-delay: 0.1s;
        }
        &:nth-child(3) {
          animation-delay: 0.2s;
        }
      }
    }
  }
}
