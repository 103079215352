.modal-reset-password {
  --width: 400px;
  --height: 230px;
  .bottom-buttons {
    text-align: right;
    margin-right: 16px;
  }
}

.page-profile {
  ion-content {
    .page-profile-wrapper {
      max-width: 968px;
    }
  }
  ion-list {
    padding-bottom: 200px;
  }
  ion-list-header {
    font-size: 16px;
  }

  ion-item-divider {
    display: flex;
    gap: 15px;
    border-bottom: 1px solid var(--ion-color-light-medium);
    color: var(--ion-color-dark);
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;

    ion-icon {
      margin-right: 0;
      color: var(--ion-color-medium);
      &.show-more {
        margin-left: 10px;
      }
    }
    .divide-wrapper-buttons {
      margin-left: auto;
      margin-right: 16px;
    }
  }

  .user-permissions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: 666px) {
      grid-template-columns: 1fr;
    }
  }

  .experimental-clear-cache {
    ion-item-divider {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    ion-item-divider,
    ion-icon {
      color: #fff;
    }
    ion-button {
      color: #222;
      // margin-left: 60px;
      margin-right: 10px;
      ion-icon {
        font-size: 22px;
      }
    }
  }

  ion-item[title] {
    cursor: help;
    user-select: none;
    --padding-top: 0;
    --min-height: 42px;
  }

  .user-permissions-item {
    display: flex;
    align-items: center;
    ion-icon {
      margin-right: 10px;
      // margin-left: 10px;
      min-width: 24px;
    }
  }

  ion-checkbox {
    margin-right: 20px;
  }

  ion-item.subscription-selector {
    overflow: visible;
    // margin-bottom: 15rem;
    --padding-start: 8px;
  }
}
